

//Modules
import { useContext, useState } from "react";
import { Box , Grid , Typography , Rating , TextField , MenuItem  } from "@mui/material"

//Icons
import ArrowUpwardIcon from '@mui/icons-material/ArrowUpward';
import { makes, models, years } from "../../../utils/constant";
import { MainContext } from "../../../context/context";
const CustomerTop = ( props : any ) => {
    const { config } = useContext(MainContext)
    const [ model , setModel ] = useState<string>('need')
    const [ make, setMake ] = useState<string>('Toyota')
    const [ year, setYear ] = useState<string>('2023')
    const box_sizing = {
        border : '1px solid #D3D3D3',
        padding : '20px',
        minHeight : ' 15vh',
        margin : '5%',
        borderRadius : '10px',
        width : '420px'
    }
    const input_refined = {
        
    }
    const box_button_search = { backgroundColor :  config.primary_color !== '' ? config.primary_color : '#B71F26' , height : '38px' , width : '38px' , cursor : 'pointer' , borderRadius : '10px' , display : 'flex' , justifyContent : 'center' }
    const box_img = { height : '25px' , paddingTop : '0px' , marginTop : '6px' }
    return (
        <Box>
            <Grid container sx={{ paddingTop : '3vh' , display : 'flex'}}>
                <Grid item sx={{ display : 'flex' }}>
                    <Box sx={box_sizing}>
                        <Grid container>
                            <Grid item xs={12} sx={{ height : '100%'}}>
                                <Typography sx={{ fontWeight : 800 ,fontSize : 16}}>{ "Total "+ props.title} </Typography>
                            </Grid>
                            <Grid item xs={6}>
                                <Box sx={{ display : 'flex' ,marginTop : '2.5vh' }}>
                                    <Typography sx={{ fontWeight : 800 , fontSize : 24 , marginRight : '10px'}}> {props.total } </Typography>
                                    {props.total_month > 0 && <Typography sx={{ color : '#009900' , alignSelf : 'center'}}> + { props.total_month * 1}</Typography>}
                                </Box>
                            </Grid>
                            <Grid item xs={6}>
                                <Box sx={{ border : '1px solid red' , height : '11.5vh'}}>
                                    <Typography sx={{ marginTop: '4.5vh' , textAlign : 'center'}}>Chart here</Typography>
                                </Box>
                            </Grid>
                        </Grid>
                    </Box>
                </Grid>
                {props.title !== 'users' &&
                <>
                <Grid item  sx={{ display : 'flex'}}>
                    <Box sx={box_sizing}>
                    <Grid container>
                            <Grid item xs={12} sx={{ height : '100%'}}>
                                <Typography sx={{ fontWeight : 800 ,fontSize : 18}}>Satisfaction score </Typography>

                            </Grid>
                            <Grid item xs={12}>
                                <Typography sx={{ color : 'gray' , fontSize : 14 , marginTop : '0.5vh'}}>Total reviews</Typography>
                            </Grid>
                            <Grid item xs={6}>
                                <Box sx={{ display : 'flex' ,marginTop : '0.5vh' }}>
                                    <Typography sx={{ fontWeight : 800 , fontSize : 18}}>{props.total_ratings}</Typography>
                                </Box>
                            </Grid>
                            <Grid item xs={6}>
                                <Box sx={{ display : 'flex' ,marginTop : '0.5vh' }}>
                                    <Typography sx={{ fontWeight : 800 , fontSize : 18}}>{props.global_rating}</Typography>
                                    <Rating value={props.global_rating} precision={0.1} max={1} readOnly></Rating>
                                </Box>
                            </Grid>
                            <Grid item xs={6}>
                                <Box sx={{ display : 'flex',marginTop : '0vh' }}>
                                    <ArrowUpwardIcon sx={{color : '#009900' , fontSize : 16}}/>
                                    <Typography sx={{color : '#009900' , marginRight : '8px' , alignSelf : 'center' , fontSize : 14}}>{props.percent_total}%</Typography>
                                    <Typography sx={{ fontSize : 12 , alignSelf : 'center' }}>vs last month</Typography>
                                </Box>
                            </Grid>
                            <Grid item xs={6}>
                                <Box sx={{ display : 'flex',marginTop : '0vh' }}>
                                    <ArrowUpwardIcon sx={{color : '#009900' , fontSize : 16}}/>
                                    <Typography sx={{color : '#009900' , marginRight : '8px' , alignSelf : 'center' , fontSize : 14}}>{props.percent_month}%</Typography>
                                    <Typography sx={{ fontSize : 12 , alignSelf : 'center' }}>vs last month</Typography>
                                </Box>
                            </Grid>
                            <Grid item xs={12}>
                            <hr style={{ marginBottom : '0vh' , borderColor : 'lightgray' , maxWidth : '100%'}}></hr>
                            </Grid>
                            <Grid item xs={4}>
                                <Box sx={{ display : 'flex' , marginTop : '0.5vh'}}>
                                    <Rating value={props.high_rating} precision={0.1} max={1} readOnly></Rating>
                                    <Box component={'img'} src={'./images/happy.svg'} sx={{ alignSelf : 'center' , marginRight : '4px'}}/>
                                    <Typography sx={{fontSize : 18 , alignSelf : 'center'}}>4-5</Typography>
                                </Box>
                            </Grid>
                            <Grid item xs={4}>
                            <Box sx={{ display : 'flex' , marginTop : '0.5vh'}}>
                                    <Rating value={props.mid_rating} precision={0.1} max={1} readOnly></Rating>
                                    <Box component={'img'} src={'./images/mid.svg'} sx={{color : 'yellow', fontSize : 20 , alignSelf : 'center' , marginRight : '4px'}}/>
                                    <Typography sx={{fontSize : 18 , alignSelf : 'center'}}>2-3</Typography>
                                </Box>
                            </Grid>
                            <Grid item xs={4}>
                            <Box sx={{ display : 'flex' , marginTop : '0.5vh'}}>
                                    <Rating value={props.low_rating} precision={0.1} max={1} readOnly></Rating>
                                    <Box component={'img'} src={'./images/sad.svg'} sx={{alignSelf : 'center' , marginRight : '4px'}}/>
                                    <Typography sx={{fontSize : 18 , alignSelf : 'center'}}>1-2</Typography>
                                </Box>
                            </Grid>
                            <Grid item xs={4}>
                                <Typography>{props.high_rating} user</Typography>
                            </Grid>
                            <Grid item xs={4}>
                                <Typography>{props.mid_rating} user</Typography>
                            </Grid>
                            <Grid item xs={4}>
                                <Typography>{props.low_rating} user</Typography>
                            </Grid>
                        </Grid>
                    </Box>
                </Grid>
                <Grid item  sx={{ display : 'flex' }}>
                    <Box sx={box_sizing}>
                        <Grid container>
                            <Grid item xs={12} sx={{ height : '100%'}}>
                                <Typography sx={{ fontWeight : 800 ,fontSize : 14}}>Search by Model , Make , Year or VIN</Typography>
                            </Grid>
                            <Grid item xs={10} sx={{ marginTop : '1vh'}}>
                                <TextField size={'small'}sx={input_refined} fullWidth placeholder="License plate" ></TextField>
                            </Grid>
                            <Grid item xs={2} sx={{ marginTop : '1vh'}}>
                                <Box sx={{ display : 'flex' , justifyContent : 'flex-end'}}>
                                    <Box sx={box_button_search}>
                                        <Box sx={box_img} component={'img'} alt={'search by license plate'} src={'./images/search.svg'}></Box>
                                    </Box>
                                </Box>
                            </Grid>
                            <Grid item xs={10} sx={{ marginTop : '1vh'}}>
                                <TextField size={'small'} sx={input_refined} fullWidth placeholder="VIN" ></TextField>
                            </Grid>
                            <Grid item xs={2} sx={{ marginTop : '1vh'}}>
                                <Box sx={{ display : 'flex' , justifyContent : 'flex-end'}}>
                                    <Box sx={box_button_search}>
                                        <Box sx={box_img} component={'img'} alt={'search by vin'} src={'./images/search.svg'}></Box>
                                    </Box>
                                </Box>
                            </Grid>      
                            <Grid item xs={3}  sx={{ marginTop : '1vh'}}>
                                <TextField size={'small'} value={year} onChange={(e) => setYear(e.target.value)} sx={input_refined} placeholder="Year" select>
                                    { years.map((option , index ) => (
                                        <MenuItem key={index} value={option.value}>
                                            {option.value}
                                        </MenuItem>
                                    ))}
                                </TextField>
                            </Grid>                            
                            <Grid item xs={3.5}  sx={{ marginTop : '1vh'}}>
                                <TextField size={'small'} value={make} onChange={(e) => setMake(e.target.value)} sx={input_refined} placeholder="Make" select >
                                    { makes.map((option , index ) => (
                                        <MenuItem key={index} value={option}>
                                            {option}
                                        </MenuItem>
                                    ))}
                                </TextField>
                            </Grid>
                            <Grid item xs={3.5}  sx={{ marginTop : '1vh'}}>
                                <TextField size={'small'} value={model} onChange={(e) => setModel(e.target.value)} sx={input_refined} placeholder="Model" select>
                                { models.map((option , index ) => (
                                        <MenuItem key={index} value={option.value}>
                                            {option.value}
                                        </MenuItem>
                                    ))}
                                </TextField>
                            </Grid>
                            <Grid item xs={2}  sx={{ marginTop : '1vh'}}>
                                <Box sx={{ display : 'flex' , justifyContent : 'flex-end'}}>
                                    <Box sx={box_button_search}>
                                        <Box sx={box_img} component={'img'} alt={'search by cars specs'} src={'./images/search.svg'}></Box>
                                    </Box>
                                </Box>
                            </Grid>
                        </Grid>
                
                    </Box>
                </Grid>
                </>
                }
            </Grid>
        </Box>
    )


}

export default CustomerTop