/*
 * Date: 2023-07-17
 * Description: Loading token component!
 * Author: Philippe Leroux @ skitsc
 */

import { Box , CircularProgress , Typography } from '@mui/material'
const LoadingAuth = () =>{
    return (
        <Box
        display={"flex"}
        justifyContent={"center"}
        alignItems={"center"}
        height={"100vh"}
      >
          <Box>
            <CircularProgress sx={{ }} />
          </Box>
          <Box>
            <Typography ml={3}>Validating authentification ... </Typography>
          </Box>
      </Box>
    )
}
export default LoadingAuth