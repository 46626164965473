/*
 * Date: 2024
 * Description: Dedicated body for customer view
 * Author: Philippe Leroux @ Skitsc
 */


//Modules
import { Box , Grid} from "@mui/material";
import { LoadingButton } from "@mui/lab";

//Components
import CustomerContacts from "../../customers/form/contacts.inputs";
import CustomerProfile from "../../customers/form/customer.profile";
import CustomerAddress from "../../customers/form/customer.address";
import ImportantDetails from "../../customers/form/important.details";
//Styles
import { button_center } from "../../../styles/form.styles";

//Interfaces && types
import { i_fragmented_body } from "../../../interfaces/utility.interface";

const CustomerFragmentedBody = ( props : i_fragmented_body ) => {
    console.log(props.form)
    const formated_props : any = {
        ...props,
        form : props.form.prev_form,
        setForm : props.form.setPrevForm,
    };
    return (
        <Box>
            <Grid container sx={{ marginTop : '2vh'}}>
                <Grid item xs={12}>
                    {props.modal_type === 'add_contact' && <CustomerContacts {...formated_props}/> }
                    {props.modal_type === 'edit_profile' && <CustomerProfile {...props.form }/> }
                    {props.modal_type === 'edit_address' && <CustomerAddress {...props.form}/> }
                    {props.modal_type === 'edit_other_details' && <ImportantDetails {...props.form}/> }
                </Grid>
                <Grid item xs={12} sx={{ marginTop : '2vh'}}>
                    <LoadingButton onClick={() => props.form.callback(props.modal_type === 'add_contact' && "contact")} sx={button_center} variant="contained" loading={props.form.loading}> Save changes </LoadingButton>
                </Grid>
            </Grid>
        </Box>
    )
}

export default CustomerFragmentedBody