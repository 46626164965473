/*
 * Date: 2024
 * Description: App config
 * Author: Philippe Leroux @ skitsc
 */

//Modules
import { Grid , Box , Typography } from "@mui/material"
import { i_file_uploads_props, i_initial_props, i_snack_alert , i_alert_props } from "../../interfaces/utility.interface";
import { ReactElement , useState , useEffect , useContext } from "react";
import { useNavigate } from "react-router-dom";

//Context
import { ThemeContext } from "../../context/context";
import { MainContext } from "../../context/context";

//Components
import Footer from "../../components/utility/footer";
import AlertDialog from "../../components/utility/alert";
import AppConfigInputs from "../../components/settings/app.config/app.config.inputs";
import UploadsInputs from "../../components/utility/upload.box";
import CircularUnderLoad from "../../components/utility/center.loader";

//Utilitys
import { CheckEnv, delay, f_empty_promise } from "../../utils/utility";
import { f_fetch, f_fetch_multiform } from "../../api/fetch";

//Constants
import { default_org, org_errors } from "../../utils/constant";

//Interfaces && types
import { i_app_config_errors, i_app_config_form , i_app_config } from "../../interfaces/setting.interface";

//Middlewares
import { m_validate_app_config } from "../../validation/main.middleware";
import { m_validate_email_opt, m_validate_hex_colors, m_validate_opt_phone, m_validate_str , m_force_str , m_validate_opt_zip_code} from "../../validation/utility.middleware";



const AppConfig = ( props : i_initial_props ) : ReactElement => {
    //Context
    const { mode , setCurrentColor } = useContext(ThemeContext);
    const { HandleLogout , setCurrentConfig } = useContext(MainContext);
    const nav = useNavigate()
    //Main states
    const [ loading , setLoading ] = useState<boolean>(false)
    const [ dev ] = useState<boolean>(CheckEnv);
    const [ target , setTarget ] = useState(default_org)
    const [ api_errors, setApiErrors ] = useState<i_snack_alert>({ open : false , promise : f_empty_promise()})

    //Business fields
    const [ app_config , setAppConfig ] = useState<i_app_config>(default_org)
    const [ upload , setUpload ] = useState<any[]>([])
    const [ asset, setAsset ] = useState<string[]>([]);
    //Errors 
    const [ app_config_errors , setAppConfigErrors ] = useState<i_app_config_errors>(org_errors)
    const [ Eupload, setEupload ] = useState<string>("")

    const handleSubmit = async() => {
        //validation here

        const [ valid , error , field ] = m_validate_app_config(app_config)
        if(valid){
            setApiErrors({ open : false, promise : f_empty_promise()})
            setLoading(true)
            const formdata = new FormData()
            formdata.append('_id', app_config._id)
            formdata.append('id', app_config.id)
            formdata.append('name',app_config.name)
            formdata.append('url', app_config.url)
            formdata.append('email', app_config.email)
            formdata.append('country', app_config.country)
            formdata.append('activity', app_config.activity)
            formdata.append('door_number', app_config.door_number)
            formdata.append('unit_number', app_config.unit_number)
            formdata.append('street', app_config.street)
            formdata.append('city', app_config.city)
            formdata.append('state', app_config.state)
            formdata.append('zip', app_config.zip)
            formdata.append('phone', app_config.phone)
            formdata.append('phone_two', app_config.phone_two)
            formdata.append('primary_color', app_config.primary_color)
            formdata.append('secondary_color', app_config.secondary_color)
            formdata.append('hover_color', app_config.hover_color)
            formdata.append('selected_color', app_config.selected_color)
            if(upload.length > 0) formdata.append('files', upload[0])
            if(asset.length > 0)formdata.append('path[]', asset[0])
            const res = await f_fetch_multiform('/config', 'PUT', formdata)
            if(res.type === 'Success'){
                setCurrentColor(res.data)
                setCurrentConfig(res.data)
                setTarget(res.data)
                setUpload([])
            }
            if(res.type === 'Unauthorized') HandleLogout(nav)
            else{
                setApiErrors({ open : true, promise : res })
            }
            setLoading(false)
        }else{
            setAppConfigErrors({...app_config_errors, [field] : error})
        }
    }

    const app_config_form_props : i_app_config_form = {
        form : app_config,
        setForm : setAppConfig,
        errors : app_config_errors,
        mode : mode,
        loading : loading,
        callback : handleSubmit,
        dev : dev,
    }
    const uploads_props : i_file_uploads_props = {
        uploads : upload,
        setUploads : setUpload,
        assets : asset,
        setAssets : setAsset,
        max : 1,
        Euploads : Eupload,
        setEuploads : setEupload,
        type : 'form',
        title : "Organization Logo",
        path : '/webapi/public/config/',
        setApiError : setApiErrors
    }
    const alert_props : i_alert_props = {
        event : api_errors,
        handleClose : () => setApiErrors({ open : false, promise : f_empty_promise()}),
        type : 'simple'
    }
    useEffect(() => {
        setLoading(true);
        const findOrg = async () => {
            const res = await f_fetch('/config', 'GET', true, null)
            if(res.type === 'Success'){
                setUpload([])
                setTarget(res.data)
                await delay(1000)
            }else{
                if(res.type === 'Unauthorized') HandleLogout(nav)
                else{
                    setApiErrors({ open : true , promise : res })
                }
            }
            setLoading(false)
        }
        findOrg()
    },[HandleLogout , nav])

    useEffect(() => {
        console.log(target)
        setAppConfig({...target})
        setAsset(target.path === '' || target.path === null ? [] : [target.path])
    },[target])
    useEffect(() => {
        const F = app_config
        const E = app_config_errors
        if(E.name !== '' && m_force_str(F.name) === '') setAppConfigErrors({...E, name : ''})
        if(E.email!== '' && m_validate_email_opt(F.email)) setAppConfigErrors({...E, email : ''})
        if(E.phone !== '' && m_validate_opt_phone(F.phone)) setAppConfigErrors({...E, phone : ''})
        if(E.phone_two !== '' && m_validate_opt_phone(F.phone_two)) setAppConfigErrors({...E, phone_two : ''})
        if(E.unit_number!== '' && m_validate_str(F.unit_number)) setAppConfigErrors({...E, unit_number : ''})
        if(E.door_number!== '' && m_validate_str(F.door_number)) setAppConfigErrors({...E, door_number : ''})
        if(E.street!== '' && m_validate_str(F.street)) setAppConfigErrors({...E, street : ''})
        if(E.city!== '' && m_validate_str(F.city)) setAppConfigErrors({...E, city : ''})
        if(E.state!== '' && m_validate_str(F.state)) setAppConfigErrors({...E, state : ''})
        if(E.zip!== '' && m_validate_opt_zip_code(F.zip)) setAppConfigErrors({...E, zip : ''})
        if(E.primary_color && m_validate_hex_colors(F.primary_color)) setAppConfigErrors({...E, primary_color : ''})
        if(E.secondary_color && m_validate_hex_colors(F.secondary_color)) setAppConfigErrors({...E, secondary_color : ''})
        if(E.hover_color && m_validate_hex_colors(F.hover_color)) setAppConfigErrors({...E, hover_color : ''})
        if(E.selected_color && m_validate_hex_colors(F.selected_color)) setAppConfigErrors({...E, selected_color : ''})
      },[app_config , app_config_errors])
    return (
        <Box>
            <Box sx={{ minHeight : '91vh'}}>
                {loading ? <CircularUnderLoad type={'full'} /> : 
                    <Grid container>
                        <Grid item xs={12}  sx={{ borderBottom : '1px solid gray' , borderCollapse : 'initial'}}>
                            <Box sx={{  pb: 1 , pl : 6 , pt:1 , display : 'flex'}}>
                                <Typography variant="h5" sx={{alignSelf : 'center' }}>Organization Profil</Typography>
                            </Box>
                        </Grid>
                        <Grid item xs={12} ml={6} >
                            <Box p={2} m={2}>
                                <UploadsInputs {...uploads_props}></UploadsInputs>
                            </Box>
                        </Grid>
                        <Grid item xs={12} ml={10} mr={10} sx={{ marginBottom : '1vh'}} > <hr style={{ display : 'block' , width : '100%' , maxWidth : '1000px' , marginLeft : 'unset'}}/></Grid>
                        <Grid item xs={12} ml={6} >
                            <AppConfigInputs { ...app_config_form_props } />
                        </Grid>
                    </Grid>
                }
            </Box>
            <AlertDialog {...alert_props} />
            <Footer type={'center'}/>
        </Box>
    )
}

export default AppConfig;