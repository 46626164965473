
/*
 * Date: 2024
 * Description: Customized paginators for tables
 * Author: Philippe Leroux  @ Skitsc
 */

//Modules
import { Box , Paper , TableContainer , Grid, TextField, MenuItem, Typography , Pagination } from "@mui/material";
import { ReactElement } from "react";

//Interfaces && types
import { i_pagination_new } from '../../interfaces/utility.interface';


const TablePagination = ( props : i_pagination_new ) : ReactElement => {
    const { count, filter, onPageChange , handleRowsPerPage  } = props;
    const cnt = Math.ceil(count / filter.rows_per_page);
    const jobs_styling = { borderLeft : '1px solid #D3D3D3' , borderBottom : '1px solid #D3D3D3' , borderRight : '1px solid #D3D3D3' , borderLeftRadius : '8px'}
    return (
        <TableContainer component={ props.title === 'Jobs' ? 'div' : Paper} sx={[ props.title  === 'Jobs' && jobs_styling,{ display : 'flex' , flexDirection : 'column' , alignItems : 'center' , justifyContent : 'center' , padding : '8px' ,}]}>
            <Grid container sx={{}}>
                <Grid item xs={3} sx={{ display : 'flex' , justifyContent : 'flex-start' , paddingRight : '8px'}}>
                    <Typography  sx={{ alignSelf : 'center' , marginRight : '8px'}}>Total of : {count}</Typography>
                </Grid>
                <Grid item xs={6}>
                    <Box sx={{ display : 'flex' , justifyContent : 'center' , alignItems : 'center'}}>
                        <Pagination count={cnt} page={filter.page} onChange={onPageChange} shape="rounded" variant="outlined" boundaryCount={1} />
                    </Box>
                </Grid>
                    
                <Grid item xs={3} sx={{ display : 'flex' , justifyContent : 'flex-end' , paddingRight : '8px'}}>
                    <Typography sx={{ alignSelf : 'center' , marginRight : '8px'}}>{props.title + " per page" } </Typography>
                    <TextField size={'small'} select value={filter.rows_per_page} onChange={(e) => handleRowsPerPage( Number(e.target.value))}>
                        <MenuItem value={5}>5</MenuItem>
                        <MenuItem value={10}>10</MenuItem>
                        <MenuItem value={25}>25</MenuItem>
                        <MenuItem value={50}>50</MenuItem>
                    </TextField>
                </Grid>
            </Grid>
      </TableContainer>
    )
}

export default TablePagination;