/*
 * Date: 2023
 * Description: recovery page ! validate the token from link , if invalid, redirect to login page
 * Author: Philippe Leroux @ skitsc
 */

//Modules
import { useState, useEffect, useContext , ReactElement , ChangeEvent } from "react";
import { Box ,TextField, IconButton, Typography } from "@mui/material";

//Contexts
import { ThemeContext } from "../context/context";

//Middlewares
import { m_validate_recovery_reset } from "../validation/main.middleware";
import { m_validate_password } from "../validation/utility.middleware";
//Api
import { f_fetch } from "../api/fetch";
//Icons And button
import { LoadingButton } from '@mui/lab';
import VisibilityOffIcon from "@mui/icons-material/VisibilityOff";
import VisibilityIcon from "@mui/icons-material/Visibility";
//Styles
import { FormStyles } from "../styles/main.styles"

//Component
import Alert from "../components/utility/alert";

//Interfaces && Types
import { i_user_reset } from "../interfaces/user.interface";
import { i_alert_props, i_initial_props } from "../interfaces/utility.interface";
import { i_snack_alert } from "../interfaces/utility.interface";

//Icons
import { empty_promise } from "../utils/constant";
import { useNavigate } from "react-router-dom";

//Constant
import { lexique } from "../utils/constant";


const Recovery = ( props : i_initial_props ) : ReactElement  => {

    //Contexts
    const nav = useNavigate()
    const { mode } = useContext(ThemeContext);
    //Main states
    const [ display , setDisplay ] = useState<'recovery' | 'final'>('recovery')
    const [ valid , setValid ] = useState<boolean>(false);
    const [ loading , setLoading ] = useState<boolean>(false);
    const [ api_errors , setApiErrors ] = useState<i_snack_alert>({ open: false, promise: empty_promise });
    //Form
    const [ password, setPassword] = useState<string>("");
    const [ confirm_password, setConfirmPassword] = useState<string>("");

    const [ key , setKey ] = useState<string>("");
    const [ visible, setVisible ] = useState<boolean>(false);

    const [ is_length , setIsLength ] = useState<boolean>(false);
    const [ is_cap_num , setIsCapNum ] = useState<boolean>(false);
    const [ is_match, setIsMatch ] = useState<boolean>(false);
    //Errors  
    const [ Epasswordconfirm , setEpasswordconfirm ] = useState<string>("");
    const [ Epassword , setEpassword ] = useState<string>("");
        
    const handleOnSubmit = async() => {
        setLoading(true);
        const form : i_user_reset = {
            password : password,
            confirm_password : confirm_password,
            code : key
        }
        const [ valid , error , field ] = m_validate_recovery_reset(form)
        if(valid){
        var endpoint = '/recovery/reset'
        const res = await f_fetch(endpoint, 'POST' , true , form);
        if(res.type === "Success"){
            setDisplay('final')            
        }else{
            setApiErrors({ open: true, promise : res });
        }
        }else{
            if(field === "password")  setEpassword(error);
            if(field === "confirm_password") setEpasswordconfirm(error);
        }
        setLoading(false);
    };

    useEffect(() => {
        setLoading(true);
        const fetch_key = async() => {
            const params = new URLSearchParams(window.location.search)
            const keyParam = params.get('key');
            const res = await f_fetch('/recovery/key?'+params.toString(), 'GET', true, null);
            setKey(keyParam !== null? keyParam : '');
            if(res.type === 'Success') setValid(true);

            console.log(res);
            setApiErrors({ open: true, promise : res });
        }
        fetch_key()
        setLoading(false)
    },[nav])
    useEffect(() => {
        if(Epassword!== '' && m_validate_password(password)) setEpassword('')
        if(Epasswordconfirm !== '' && password === confirm_password) setEpasswordconfirm('')
    }, [Epassword , Epasswordconfirm , password , confirm_password]);

    useEffect(() => {
        if(password.length >= 8 && password.length <= 20){
            setIsLength(true)
        }else{
            setIsLength(false)
        }
        if(lexique.cap_number_regex.test(password)){
            setIsCapNum(true)
        }else{ setIsCapNum(false)}
        if(lexique.one_special_regex.test(password)){
            setIsMatch(true)
        }else{
            setIsMatch(false)
        }
    },[password])
    const alert_props : i_alert_props = {
        event : api_errors,
        handleClose : () => setApiErrors({ open : false, promise : empty_promise }),
        type : 'full'
    }
    
    return (
        <Box sx={{}}>
        <Box sx={{ display: "flex", justifyContent: "center", alignItems: "center", height: "100vh" , backgroundImage : mode === "Dark" ? "url('./images/dark-bg-login.jpg')" :"url('./images/bg-login.jpg')" ,backgroundSize: "cover",  backgroundPosition: "center"}}>
        
        <Box  sx={{ width : '455px',borderRadius: 1 , padding: '2em'}}>
            <Box>
                <Box sx={{ display: "flex", justifyContent: "center", alignItems: "center", flexDirection: "column", pb: 2}}>
                <Box component={'img'} alt={'Garage logo'} sx={{ width: '150px' , marginBottom : '1vh'}} src={'./images/dark-logo.png'}></Box>
                {valid ? <>
                    {display === 'recovery' ?
                    <>
                    <Box>
                        <Typography sx={{ fontWeight : '600' , marginBottom : '1vh' , textAlign : 'center'}} component="h1" variant="h5">Set new password</Typography>
                        <Typography sx={{ fontSize : 14 , color : 'gray'}} component="h2" variant="h6">Your new password must be different to</Typography>
                    </Box>
                    <Box>
                        <Typography sx={{ fontSize : 14, color : 'gray'}} component="h2" variant="h6">previously used password</Typography>
                    </Box>
                    </>
                    : 
                        <>
                            <Typography sx={{ fontWeight : '600' , marginBottom : '1vh' , textAlign : 'center'}} component="h1" variant="h5">Password reset</Typography>
                            <Typography sx={{ fontSize : 14 , color : 'gray'}} component="h2" variant="h6">Your password has been successfully reset.</Typography>
                            <Typography sx={{ fontSize : 14 , color : 'gray'}}>Click below to log in magically.</Typography>
                        </>
                    }
                
                    <Box component={'form'}>
                    {display === 'recovery' && <>
                        <Typography sx={{ fontWeight : 600}}>Password</Typography>
                        <TextField fullWidth  size="small" type={visible ? "text" : "password"} placeholder="Enter your password" sx={FormStyles.textfield} 
                            value={password} helperText={Epassword} error={Epassword!== "" ? true : false}  autoComplete={"new-password" }
                            onChange={(e: ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => setPassword(e.target.value)}
                            InputLabelProps={{ shrink: true }}
                            InputProps={{ endAdornment: <IconButton edge="end" aria-label="toggle password visibility" 
                            onClick={() => setVisible(!visible)}>{visible ? <VisibilityOffIcon /> : <VisibilityIcon />}</IconButton>}}/>
                        <Typography sx={{ fontWeight : 600}}>Confirm Password</Typography>
                        <TextField fullWidth  size="small" type={visible ? "text" : "password"} placeholder="Enter your password" sx={FormStyles.textfield} autoComplete={"new-password" }
                            value={confirm_password} helperText={Epasswordconfirm} error={Epasswordconfirm !== "" ? true : false}
                            onChange={(e: ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => setConfirmPassword(e.target.value)}
                            InputLabelProps={{ shrink: true }}
                        />
                        <Box sx={{ display : 'flex' }}>
                            <Box sx={{ justifyContent : 'flex-start'}} component={'img'} alt={'checkmark button'} src={is_length ? './images/green-check.svg': './images/grey-check.svg'}/>
                            <Typography sx={{ paddingLeft : '8px' , fontSize : 16}}>Must be between 8-20 characters</Typography>
                        </Box>
                        <Box sx={{ display : 'flex' , marginTop : '0.5vh'}}>
                            <Box sx={{ justifyContent : 'flex-start'}} component={'img'} alt={'checkmark button'} src={is_cap_num ? './images/green-check.svg': './images/grey-check.svg'}/>
                            <Typography sx={{ paddingLeft : '8px' , fontSize : 16  }}>Must contain one capitalize letter and one number</Typography>
                        </Box>
                        <Box sx={{ display : 'flex' , marginTop : '0.5vh' , marginBottom : '1.5vh'}}>
                            <Box sx={{ justifyContent : 'flex-start'}} component={'img'} alt={'checkmark button'} src={is_match ? './images/green-check.svg': './images/grey-check.svg'}/>
                            <Typography sx={{ paddingLeft : '8px' , fontSize : 16}}>Must contain one special character</Typography>
                        </Box>
                        </>
                    }
                    <LoadingButton loading={loading} disabled={loading} fullWidth variant="contained" color="primary"
                    onClick={() => display === 'recovery' ? handleOnSubmit() : nav('/')}>{ display === 'recovery' ? "Reset password" : "Continue" }</LoadingButton>
                    </Box>
                </> :
            <Box>
                 <Box>
                    <Typography sx={{ fontWeight : '600' , marginBottom : '1vh' , textAlign : 'center'}} component="h1" variant="h5">Password recovery process</Typography>
                    <Typography sx={{ fontSize : 14 , color : 'gray'}} component="h2" variant="h6">Please make to use the latest url for password recovery.</Typography>
                    <Typography sx={{ fontSize : 14 , color : 'gray'  , textAlign : 'center'}} component="h2" variant="h6">Allow 5-10 minutes for email reception.</Typography>
                    <Typography sx={{ fontSize : 14 , color : 'gray' , textAlign : 'center'}} component="h2" variant="h6">Make sure to verify spams.</Typography>
                </Box>
                <LoadingButton sx={{ marginTop : '2vh'}} loading={loading} disabled={loading} fullWidth variant="contained" color="primary"
                    onClick={() => nav('/')}>Go to login</LoadingButton>
            </Box>
            }
            </Box>
            </Box>
            </Box>
            {valid &&
                <Alert {...alert_props} />
            }
        </Box>
        </Box>
    );
}

export default Recovery;
