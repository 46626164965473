

//Modules
import { Box , Grid , Typography} from "@mui/material"
import { ReactElement } from "react"


//Interfaces && types
import { t_display } from "../../../types/types"
import { i_snack_alert , i_tbl_header } from "../../../interfaces/utility.interface";

export interface i_table_v2_row_props {
    row : any;
    callback : ( value : any , type : t_display ) => void;
    setApiError : ( error : i_snack_alert) => void;
    headers : i_tbl_header[];
}

const PayRollRow = ( props : i_table_v2_row_props) : ReactElement => {
    const renderPeriod = () => {
        return " the date"
    }
    return (
        <Box sx={{ borderLeft : '1px solid #D3D3D3' , borderRight : '1px solid #D3D3D3' , borderBottom : '1px solid #D3D3D3'}}>
        <Grid container sx={{ padding : '6px'}}>
            <Grid item xs={props.headers[0].portion}>
                <Box sx={{ display : 'flex' , marginTop : '0.4vh'}}>
                    {/* <Typography sx={{ fontSize : 14 }}>{row.user.first_name + " " + row.user.last_name}</Typography> */}
                </Box>
            </Grid>
            <Grid item xs={props.headers[1].portion} sx={{ alignSelf : 'center'}}>
                <Typography sx={{ alignSelf : 'center' , fontSize : 14}}>{props.row.status}</Typography>
            </Grid>
            <Grid item xs={props.headers[2].portion} sx={{ alignSelf : 'center' , display : 'flex'}}>
                <Typography sx={{ alignSelf : 'center' , fontSize : 14}}>{renderPeriod()}</Typography>
            </Grid>
            <Grid item xs={props.headers[3].portion} sx={{ alignSelf : 'center'}}>
                <Typography>{props.row.hours}</Typography>
            </Grid>
            <Grid item xs={props.headers[4].portion} sx={{ alignSelf : 'center'}}>
                <Typography sx={{ fontSize : 14 }}>{props.row.net_pay}</Typography>
            </Grid>
            <Grid item xs={props.headers[5].portion} sx={{ alignSelf : 'center'}}>
                <Typography sx={{ fontSize : 14 }}>{props.row.taxes}</Typography>
            </Grid>
            <Grid item xs={props.headers[5].portion} sx={{ display : 'flex' , alignSelf : 'center'}}>
                <Box sx={{ height : '25px', marginRight : '8%', cursor : 'pointer'}} onClick={() => props.callback( props.row ,'task')} component={'img'} alt="Sort order img" src={'./images/eye.svg'}/>
                <Box sx={{ height : '25px' , marginRight : '8%', cursor : 'pointer'}} onClick={() => props.callback( props.row ,'download')} component={'img'} alt="Sort order img" src={'./images/download-icon.svg'}/>
            </Grid>
        </Grid>
    </Box>
    )
}

export default PayRollRow