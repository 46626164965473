/*
 * Date: 2023
 * Description: Car items main display for the car objects includes pleatora of options
 * Author: Philippe Leroux @ skitsc
 */

//Modules
import { ReactElement } from "react"
import { Box , Grid , Typography , Button } from "@mui/material"
import { i_customer_car, t_customer_modal_body } from "../../../interfaces/customer.interface"
import { t_display } from "../../../types/types"

interface i_customer_car_item {
    car : i_customer_car
    callback : ( car : i_customer_car , display : t_display , event : t_customer_modal_body ) => void
}
const CarItem = ( props : i_customer_car_item ) : ReactElement => {
    const C = props.car
    const f_handle_registration = ( car : i_customer_car ) => {
        //Maybe some kind of function that return the latest registration if there's many
        return (
            <Box>
                {  C.registration[0] !== undefined ?
                    <Typography sx={{ fontSize : 14}}>{C.registration[0].registration_number}</Typography>
                    : <Typography sx={{ fontSize : 14}}>No registration yet</Typography>
                }
            </Box>
        )
    }

    return (
        <Box key={C._id} sx={{ border: '1px solid #EAECF0' , marginBottom : '1vh' , padding : '5px'}}>
            <Grid container>
                <Grid item xs={3}>
                    <Box sx={{display : 'flex'  , marginBottom : '0.4vh'}}> 
                        <Typography sx={{ fontWeight : 800}}>Car photos</Typography>
                        <Box sx={{ cursor : 'pointer' ,marginLeft : 'auto'}} component={'img'} alt="Car photo handle" src={'./images/eye.svg'}/>
                    </Box>
                    <Box component={'img'} sx={{ maxHeight : '150px' , display : 'block', marginLeft : 'auto' , marginRight : 'auto'}} alt={'car logo or latest service picture'} src={'./images/makes/' + C.make.toLowerCase()+'-logo.png'}/>
                </Grid>
                <Grid item xs={3} sx={{ paddingLeft : '10px'}}>
                    <Box sx={{display : 'flex' , marginBottom : '0.4vh'}}> 
                        <Typography sx={{ fontWeight : 800}}>Car info</Typography>
                        <Box sx={{ cursor : 'pointer' ,marginLeft : 'auto'}} onClick={() => props.callback( C , 'form' , 'none')} component={'img'} alt="Edit car asset" src={'./images/edit.svg'}/>
                    </Box>
                    <Box sx={{ display : 'flex' , width : '100%' , marginBottom : '0.4vh' }}>
                        <Box><Typography sx={{ fontSize : 14}}>Make</Typography></Box>
                        <Box sx={{ marginLeft : 'auto'}}><Typography sx={{ fontSize : 14}}>{C.make}</Typography></Box>
                    </Box>
                    <Box sx={{ display : 'flex' , width : '100%' , marginBottom : '0.4vh'}}>
                        <Box><Typography sx={{ fontSize : 14}}>Model</Typography></Box>
                        <Box sx={{ marginLeft : 'auto'}}><Typography sx={{ fontSize : 14}}>{C.model}</Typography></Box>
                    </Box>
                    <Box sx={{ display : 'flex' , width : '100%'  , marginBottom : '0.4vh'}}>
                        <Box><Typography sx={{ fontSize : 14}}>Year</Typography></Box>
                        <Box sx={{ marginLeft : 'auto'}}><Typography sx={{ fontSize : 14}}>{C.year}</Typography></Box>
                    </Box>
                    <Box sx={{ display : 'flex' , width : '100%'  , marginBottom : '0.4vh'}}>
                        <Box><Typography sx={{ fontSize : 14}}>Color</Typography></Box>
                        <Box sx={{ marginLeft : 'auto'}}><Typography sx={{ fontSize : 14}}>{C.color}</Typography></Box>
                    </Box>
                    <Box sx={{ display : 'flex' , width : '100%'  , marginBottom : '0.4vh'}}>
                        <Box><Typography sx={{ fontSize : 14}}>Vin</Typography></Box>
                        <Box sx={{ marginLeft : 'auto'}}><Typography sx={{ fontSize : 14}}>{C.vin}</Typography></Box>
                    </Box>
                    <Box sx={{ display : 'flex' , width : '100%'  , marginBottom : '0.4vh'}}>
                        <Box><Typography sx={{ fontSize : 14}}>Plate</Typography></Box>
                        <Box sx={{ marginLeft : 'auto'}}>{f_handle_registration(C)}</Box>
                    </Box>
                </Grid>
                <Grid item xs={3.5} sx={{ paddingLeft: '10px'}}>
                    <Box sx={{display : 'flex'  , marginBottom : '0.4vh'}}> 
                        <Typography sx={{ fontWeight : 800}}>Car status</Typography>
                        {/* <Box sx={{ cursor : 'pointer' ,marginLeft : 'auto'}} component={'img'} alt="Edit car asset" src={'./images/edit.svg'}/> */}
                    </Box>
                    <Box sx={{ display : 'flex', marginTop : '1.5vh' , marginBottom : '1.5vh' , paddingLeft : '0.5vh'}}>
                        <Box pl={1} pr={1} pt={0.5} pb={0.5} sx={{ border : '1px solid gray' , borderRadius : '2px' , display : 'flex'}}>
                            <Box component={'img'} alt={'Status icon'} src={'./images/tool-01.svg'}/>
                            <Typography sx={{ fontWeight : 500 , fontSize : 10 , paddingLeft : '6px' , alignSelf : 'center'}}>Repair in progress</Typography>
                        </Box>
                    </Box>
                    <Box sx={{display : 'flex'  , marginBottom : '0.4vh'}}> 
                        <Typography sx={{ fontWeight : 800}}>Comments</Typography>
                    </Box>
                    <Box>
                        <Typography sx={{ fontSize : 14}}>Some important comments here from the job i guess..</Typography>
                    </Box>
                </Grid>
                <Grid item xs={2.5} sx={{paddingLeft : '10px', marginTop : '0.4vh'}}>
                    <Box sx={{ display : 'flex' , width : '100%' , marginBottom : '1vh' , paddingLeft : '10px' }}>
                        <Button variant="contained" sx={{fontSize : 12}} onClick={() => props.callback( C , 'more' , 'none')} startIcon={<Box component={'img'} alt={'info icon'} src={'./images/info_circle.svg'}/>}>More info</Button>
                    </Box>
                    <Box sx={{ display : 'flex' , width : '100%' , marginBottom : '1vh' , paddingLeft : '10px'}}>
                        <Button variant="contained"  sx={{fontSize : 12}} onClick={() => props.callback( C , 'service' , 'none')} startIcon={<Box component={'img'} alt={'add icon'} src={'./images/plus-square.svg'}/>}>Plan service</Button>
                    </Box>
                    <Box sx={{ display : 'flex' , width : '100%' , marginBottom : '0.5vh' , paddingLeft : '10px'}}>
                        <Button variant="contained"  sx={{fontSize : 12}} onClick={() => props.callback( C , 'contact' , 'none')} startIcon={<Box component={'img'} alt={'add icon'} src={'./images/message-chat-circle.svg'}/>}>Contact us</Button>
                    </Box>
                </Grid>
            </Grid>
        </Box>
    )
}

export default CarItem