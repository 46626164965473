
/*
 * Date: 2024
 * Description: Tasks rows
 * Author: Philippe Leroux @ Skitsc
 */

//Modules
import { Grid , Box , Typography } from "@mui/material"
import { useEffect, useState } from "react";
import LinearProgress, { LinearProgressProps } from '@mui/material/LinearProgress';

//Interfaces
import { i_table_v2_row_props } from "../../../interfaces/utility.interface";

//Utils
import { f_timestamp_to_date } from "../../../utils/utility";


//Icons


const TaskRows = ( props : i_table_v2_row_props ) => {
    const row = props.row
    const [ status , setStatus ] = useState<string>(row.status)
    
    useEffect(() => {
        if(row.status !== status) setStatus(row.status)
    },[row , status])
    const formatElaspe = () => {
        var value = 'Elaspe time: '
        if(row.status === 'Done'){
            value += row.time_estimated + 'm'
        } else{
            value += '00m'
        }
        return value
    }
    const LinearProgressWithLabel = (props: LinearProgressProps) => {
        var value : number = 0;
        if(row.status === 'Done') value = 100
        return (
          <Box sx={{ display: 'flex', alignItems: 'center' }}>
            <Box sx={{ width: '80%', mr: 1 }}>
              <LinearProgress color={row.status === 'Done' ? 'success' : row.status === 'Overtime' ? 'error' : 'inherit'} variant="determinate" value={value}  />
            </Box>
            <Box sx={{ minWidth: 35 }}>
              <Typography variant="body2" >{row.time_estimated + 'M'} </Typography>
            </Box>
          </Box>
        );
      }
    return (
            <Box sx={{ borderLeft : '1px solid #D3D3D3' , borderRight : '1px solid #D3D3D3' , borderBottom : '1px solid #D3D3D3'}}>
                <Grid container sx={{ padding : '6px'}} >
                    <Grid item xs={props.headers[0].portion}>
                        <Box sx={{display : 'flex'}}>
                            <Typography sx={{ paddingLeft : '6px' , paddingRight : '10px' , fontWeight : 600}}>{row.title + ' (' + row.index + ")" } </Typography>
                        </Box>
                        <Box sx={{ paddingLeft : '6px' , paddingRight : '10px' }}>
                            {row.type}
                        </Box>
                        
                    </Grid>
                    <Grid item xs={props.headers[1].portion} sx={{ alignSelf : 'center'}}>
                        <Typography sx={{ alignSelf : 'center' , fontSize : 14}}>{f_timestamp_to_date(row.start, 'long')}</Typography>
                    </Grid>
                    <Grid item xs={props.headers[2].portion} sx={{ alignSelf : 'center' , display : 'flex'}}>
                        <Typography sx={{ alignSelf : 'center' , fontSize : 14}}>{f_timestamp_to_date(row.end, 'long')}</Typography>
                    </Grid>
                    <Grid item xs={props.headers[3].portion} pr={6}>
                        <Box sx={{ fontSize : 10 , fontWeight : 700}}>{row.status}</Box>
                        <LinearProgressWithLabel/>
                        <Box sx={{ fontSize : 10 , fontWeight : 700}}>{formatElaspe()}</Box>
                    </Grid>
                    <Grid item xs={props.headers[4].portion}sx={{ alignSelf : 'center'}}>
                        <Typography sx={{ fontSize : 14 }}>{row.user.first_name + " " + row.user.last_name}</Typography>
                    </Grid>
                    <Grid item xs={props.headers[5].portion} sx={{ display : 'flex' , alignSelf : 'center'}}>
                        <Box sx={{ height : '25px', marginRight : '8%', cursor : 'pointer'}} onClick={() => props.callback( row ,'view')} component={'img'} alt="Sort order img" src={'./images/eye.svg'}/>
                        <Box sx={{ height : '30px' , marginRight : '8%', cursor : 'pointer'}} onClick={() => props.callback( row ,'form')} component={'img'} alt="Sort order img" src={'./images/edit.svg'}/>
                        <Box sx={{ height : '25px' , marginRight : '8%', cursor : 'pointer'}} onClick={() => props.callback( row ,'download')} component={'img'} alt="Sort order img" src={'./images/camera.svg'}/>
                    </Grid>
                </Grid>
            </Box>
    )
}

export default TaskRows