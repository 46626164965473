

//Modules
import { Box , Grid , Typography , IconButton , TextField , MenuItem, Button , InputAdornment} from "@mui/material"
import { LoadingButton } from "@mui/lab";
import dayjs , { Dayjs } from "dayjs";
import { ReactElement , ChangeEvent, useEffect } from "react"
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider , DatePicker } from '@mui/x-date-pickers';


//Components
import UploadsInputs from "../../utility/upload.box";

//Interfaces && types
import { i_user } from "../../../interfaces/user.interface";

//Constants 
import { empty_user, impot_2024 } from "../../../utils/constant";

//Icons
import { ArrowBack } from "@mui/icons-material";

//Styles
import { sub_title , input_base , bottom_line } from "../../../styles/form.styles";
import { i_payroll_input_props } from "../../../interfaces/payroll.interface";
import NumericTextField from "../../utility/inputs/numeric.inputs";

const PayrollInputs = ( props : i_payroll_input_props ) : ReactElement=> {
    const F = props.form;
    const E = props.errors;
    const I = impot_2024

    const setUser = ( val : string ) => {
        const row = props.users.find( (x : any) => x._id === val)
        const hr_rate = row?.hour_rate ? row?.hour_rate.toString() : '15.75'
        props.setForm({...props.form, user : row ? row : empty_user , user_id : row? row._id : empty_user._id , rate : hr_rate})
    }
    const handleStartDate = ( newDate: Dayjs| null ) => { 
        if(newDate!== null) {
            const endDate = newDate.add(2, 'week');
            props.setForm({...props.form, start : newDate.valueOf(), end : endDate.valueOf()})
        }
    }
    const calcAeTaxes = ( amount : number ) => {
        if(amount * I.ae.tax_rate >= I.ae.max_cote) {
            return I.ae.max_cote;
        }else{
            return amount * I.ae.tax_rate;
        }
    }
    const calcFedTaxes = ( amount : number ) => {
        var total = amount;
        var sm_tax = 0;
        var md_tax = 0;
        var lg_tax = 0;
        var xl_tax = 0;
        var xll_tax = 0;
        if(amount <= I.fed_taxes.base_amount) return 0
        if(total <= I.fed_taxes.first_bracket){
            sm_tax = ( amount - I.fed_taxes.base_amount ) * I.fed_taxes.first_tax;
        }else{
            sm_tax = ( I.fed_taxes.first_bracket - I.fed_taxes.base_amount ) * I.fed_taxes.first_tax;
            if(total <= I.fed_taxes.second_bracket){
                md_tax = (total - I.fed_taxes.first_bracket) * I.fed_taxes.second_tax;
            }else{
                md_tax = (I.fed_taxes.second_bracket - I.fed_taxes.first_bracket) * I.fed_taxes.second_tax;
                if(total <= I.fed_taxes.third_bracket){
                    lg_tax = (total - I.fed_taxes.second_bracket) * I.fed_taxes.third_tax;
                }else{
                    lg_tax = (I.fed_taxes.third_bracket - I.fed_taxes.second_bracket) * I.fed_taxes.third_tax;
                    if(total <= I.fed_taxes.fourth_bracket){
                        xl_tax = (total - I.fed_taxes.third_bracket) * I.fed_taxes.fourth_tax;
                    }else{
                        xl_tax = (I.fed_taxes.fourth_bracket - I.fed_taxes.third_bracket) * I.fed_taxes.fourth_tax;
                        xll_tax = (total - I.fed_taxes.fourth_bracket) * I.fed_taxes.fifth_tax;
                    }
                }
            }
        }
        var tax_total = sm_tax + md_tax + lg_tax + xl_tax + xll_tax;
        // tax_total = tax_total - ( tax_total * I.fed_taxes.qc_peraquation)
        return tax_total;
    }
    const calcQcTaxes = ( amount : number ) => {
        var total = amount
        var base_mtn = I.qc_taxes.base_amount * 0.14
        // var sm_tax = 51870 * 0.14;
        // var md_tax = 10530 * 0.19
        var sm_tax = 0;
        var md_tax = 0;
        var lg_tax = 0;
        var xl_tax = 0;
        if(amount <= I.qc_taxes.base_amount) return 0
        if(total <= I.qc_taxes.first_bracket){
            sm_tax = ( amount - I.qc_taxes.base_amount ) * I.qc_taxes.first_tax;
        }else{
            sm_tax = ( I.qc_taxes.first_bracket - I.qc_taxes.base_amount ) * I.qc_taxes.first_tax;
            if(total <= I.qc_taxes.second_bracket){
                md_tax = (total - I.qc_taxes.first_bracket) * I.qc_taxes.second_tax;
            }else{
                md_tax = (I.qc_taxes.second_bracket - I.qc_taxes.first_bracket) * I.qc_taxes.second_tax;
                if(total <= I.qc_taxes.third_bracket){
                    lg_tax = (total - I.qc_taxes.second_bracket) * I.qc_taxes.third_tax;
                }else{
                    lg_tax = (I.qc_taxes.third_bracket - I.qc_taxes.second_bracket) * I.qc_taxes.third_tax;
                    xl_tax = (total - I.qc_taxes.third_bracket) * I.qc_taxes.fourth_tax;
                }
            }
        }

        var tax_total = sm_tax + md_tax + lg_tax + xl_tax;
       // var tax_total = ( sm_tax + md_tax + lg_tax + xl_tax ) - base_mtn
        return tax_total;
    }
    const calcRRQtaxes = ( amount : number ) => {
        if(I.rrq.max_amount <= amount){
            return I.rrq.max_cote
        }else{
            if(amount < I.rrq.base_amount){
                return 0
            }else{
                return ( amount - I.rrq.base_amount ) * I.rrq.tax_rate
            }
        }
    }
    const calcRQAPtaxes = ( amount : number ) => {
        if(amount >= I.rqap.max_amount){
            return I.rqap.max_amount * I.rqap.tax_rate
        }else{
            return amount * I.rqap.tax_rate
        }
    }
    const handleCalc = () => {
        const total_gross = F.hours * Number(F.rate)
        const estimated_total  = total_gross * 26
        superCalcFed(total_gross)
        const y_rrq = Number(calcRRQtaxes(estimated_total))
        const y_rqap = Number(calcRQAPtaxes(estimated_total))
        const y_ae = Number(calcAeTaxes(estimated_total))
        const y_qc_tax = Number(calcQcTaxes(superCalc(estimated_total)))
        const y_fed_tax = Number(calcFedTaxes(superCalcFed(estimated_total)))
        const y_deductions = y_ae + y_qc_tax + y_fed_tax + y_rrq + y_rqap 

        const b_rrq = Number(calcRRQtaxes(estimated_total)) / 26
        const b_rqap = Number(calcRQAPtaxes(estimated_total)) / 26
        const b_ae = Number(calcAeTaxes(estimated_total)) / 26
        const b_qc_tax = Number(calcQcTaxes(superCalc(estimated_total))) / 26
        const b_fed_tax = Number(calcFedTaxes(superCalcFed(estimated_total))) / 26
        const b_deductions = b_ae + b_qc_tax + b_fed_tax + b_rrq + b_rqap

        props.setForm({ ...F , year_calc : { gross : estimated_total , net : estimated_total - y_deductions , ae : y_ae , rrq : y_rrq , rqap :  y_rqap, qc_tax : y_qc_tax, fed_tax : y_fed_tax, rate : estimated_total > 0 ? taxRate(y_deductions , estimated_total) : 0 , type : 'year'},
            biweekly_calc : { gross : total_gross , net : total_gross - b_deductions , ae : b_ae , rrq : b_rrq , rqap : b_rqap , qc_tax : b_qc_tax, fed_tax : b_fed_tax, rate : total_gross > 0 ? taxRate(b_deductions , total_gross) : 0 , type : 'biweekly'}})
    }
    const superCalc = ( amount : number ) => {
        var p = 26;
        var g = amount
        var f = 0;
        var h = 1380 / 26
        var j = 0;
        var J = 0;
        var c = calcRRQtaxes(g) / 26;
        var C = 0;
        var B = 0;
        var s = 2400
        var k = 2589
        var K = 0;
        var q = 0;
        var Q = 0;
        var csa = c * (0.01 / 0.0640) + C
        var i = p * ( s - f - h - csa ) - j - J
        return i
        // var qc_taxes = calcQcTaxes(i)
        // var bi_weekly_tax = calcQcTaxes(i) / 26
        // console.log("Revenue imposable : " +  i.toFixed(2) , "Impot annuel : " + calcQcTaxes(i).toFixed(2) , "Impot au 2 semaines : " + bi_weekly_tax.toFixed(2) , "Revenue au 2 semaines imposable : " + (i / 26).toFixed(2))
        // var impot_total = ( 0.19 * i ) - k - K - (0.14 * I.qc_taxes.base_amount ) - ( 0.15 * 26 * q) - ( 0.15 * p * Q )
        // console.log("Impot total : " + impot_total.toFixed(2) , qc_taxes)
    }
    const superCalcFed = ( amount : number ) => {
        //Nombre de paye
        const p = 26
        //Revenue brute
        const i = amount / 26
        //Reer autres
        const f = 0
        //Calcul rrq
        const c = calcRRQtaxes(amount) / 26;
        const ae = calcAeTaxes(amount) / 26;
        const rqap = calcRQAPtaxes(amount) / 26;
        //Calcul rrq suplementaire
        const c2 = 0;
        const pm = 12
        //Paiement de Pension alimentaire
        const f2 = 0
        //Deductions pour regime complementaire de paye
        const f5q = c * (0.01 / 0.0640) + c2
        //Deductions syndicats 
        const u1 = 0
        // Deductions frais de gardes.
        const f1 = 0
        // Deductions annuelles pour resident visée dune region specifique
        const hd = 0
        const k3 = 0
        const k4 = 0.15 * 1433
        var revenue_imposable = p * ( i - f - f2 - f5q - u1 ) - hd - f1 // CA CEST OK
        const k5 = 0.15 * revenue_imposable
        const bi_weekly_tax = calcFedTaxes(revenue_imposable) / 26
        const bi_weekly_rev = revenue_imposable / 26
        //console.log("Revenue imposable : " +  revenue_imposable.toFixed(2) , "Impot annuel : " + calcFedTaxes(revenue_imposable).toFixed(2) , "Revenue au 2 semaines imposable : " +  bi_weekly_tax , "Revenue au 2 semaines imposable : " + bi_weekly_rev.toFixed(2))
        const k = 3073
        const r = I.fed_taxes_old.second_tax
        const k1 = ( 0.15 * 15705 )
        const m1 = ( 0.15 * ( p * c * ( 0.0540 / 0.0640 ) + c2 ) * (pm / 12)) > 3510 ? 3510 : ( 0.15 * ( p * c * ( 0.0540 / 0.0640 ) + c2 ) * (pm / 12))  //maximum 3510.00
        const m2 = ( 0.15 * ( p * ae)) > 834.24 ?  834.24 : ( 0.15 * ( p * ae)) // max 834.24
        const m3 = ( 0.15 * ( p * rqap * 0.00494)) > 464.36 ? 464.36 :  ( 0.15 * ( p * rqap * 0.00494))  //maximum 464.36 $
        const k2q = m1 + m2 + m3
      //  console.log(m1 , m2 , m3)
        const t3 = ( r * revenue_imposable ) - k - k1 - k2q - k3 - k4
        const t1 = (t3 - (p * 0)) - (0.165 * t3)
       // console.log('le t3..' + t3.toFixed(2) , 'le k1..' + k1.toFixed(2) , 'le k2q ..' + k2q.toFixed(2) , 'le k3..' + k3.toFixed(2) , 'le k4..' + k4.toFixed(2 ) , t1 /26)
        return revenue_imposable
    }
    useEffect(() => {
        handleCalc()
    },[F.hours, F.rate])
    const handleRates = (amount: string) => {
        const regex = /^\d+(\.\d{0,2})?$/;
        if (amount === '') {
            props.setForm({...F, rate: ''});
        } else if (regex.test(amount)) {
            props.setForm({...F, rate: amount});
        }
    }
    const taxRate = ( deductions : number , amount : number ) => {
        const rate = (deductions / amount) * 100;
        const roundedRate = rate.toFixed(1);
        return parseFloat(roundedRate);
    }
    const customCeil = (value : number) => {
        const integerPart = Math.floor(value);
        const decimalPart = value - integerPart;
        if (decimalPart >= 0.5) {
            return integerPart + 1;
        } else {
            return integerPart;
        }
    };
    // const roundToTwoDecimalPlaces = ( num: number ) : number  => {
    //     return Math.round(num * 100) / 100;
    // }
    const handleHours = ( e : number) => {
        props.setForm({...F, hours : e})
    }
  return (
    <Box p={2} sx={{ marginBottom : '1vh' }} id={'task_inputs'} >
        <Grid container>
            <Grid item xs={12}>
                <Box sx={{ display : 'flex' }}>
                    <IconButton sx={{ marginRight : '4px'}} onClick={() => props.onClose('table')}><ArrowBack color={'primary'} sx={{ marginRight : 'auto'}}/></IconButton>
                    <Typography sx={{ fontWeight : 800 , fontSize : 20 ,  alignSelf : 'center'}}>{F._id !== '' ? "Edit pay "+ props.title : "Add a pay"}</Typography>
                </Box>
                <Typography sx={{ fontWeight : 800 , fontSize : 16 , marginBottom : '2vh' , color : 'grey'}}>Enter your pay informations</Typography>
            </Grid>
            <Grid item xs={3}>
                <Typography sx={sub_title}>Select a date period</Typography>
            </Grid>
            <Grid item xs={9}>
                <LocalizationProvider dateAdapter={AdapterDayjs}>
                <DatePicker
                        value={dayjs(F.start)}
                        onChange={(date) => handleStartDate(date)}
                        shouldDisableDate={(date) => date.day() !== 0} // Disable all days that are not Sundays
                    />
                    <DatePicker
                        value={dayjs(F.end)}
                        disabled
                    
                    />
                </LocalizationProvider>         
            </Grid>
            <Grid item xs={12} sx={{ marginBottom : '1vh'}}> <hr style={bottom_line}/></Grid>
            <Grid item xs={3}>
                <Typography sx={sub_title}>Select employee *</Typography>
            </Grid>
            <Grid item xs={9}>
                <TextField select sx={[input_base , { width : '300px'} ]} size="small" value={F.user._id !== ''  ? F.user._id : "default"} error={E.user_id !== ''} helperText={E.user_id}
                    onChange={( event: ChangeEvent<any> ) => setUser(event.target.value)} autoComplete={props.dev ? "off" : "new-password" }>
                        <MenuItem value={"default"} disabled>Select a user</MenuItem>
                        {props.users.map((user : i_user) => (
                            <MenuItem key={user._id} value={user._id}>{user.first_name + " " + user.last_name + " --> " + user.type }</MenuItem>
                        ))}
                </TextField>
            </Grid>
            <Grid item xs={12} sx={{ marginBottom : '1vh'}}> <hr style={bottom_line}/></Grid>
            {F.user_id !== '' && 
            <>
                <Grid item xs={3}>
                    <Typography sx={sub_title}>Hours rates / Hours</Typography>
                </Grid>
                <Grid item xs={9}>
                <TextField sx={[input_base , { width : '300px'} ]}  size="small" placeholder={"Hour rate"} value={F.rate} error={false} helperText={""}
                      onChange={(e : any) => handleRates(e.target.value)} autoComplete={props.dev ? "off" : "new-password" } name={"rates"} InputProps={{startAdornment: <InputAdornment position="start">$</InputAdornment>}} />  
                    <NumericTextField sx={[input_base , { width : '300px'} ]} size="small" placeholder={"index"} value={F.hours} error={E.hours !== ""} helperText={E.hours}
                      handleChange={handleHours} autoComplete={props.dev ? "off" : "new-password" } name={"hours"}/>    
                </Grid>
                <Grid item xs={12} sx={{ marginBottom : '1vh'}}> <hr style={bottom_line}/></Grid>
                <Grid item xs={12}>
                    <Typography sx={{ fontWeight : 800 , fontSize : 20 , marginBottom : '2vh'}}>Bi-Weekly taxes calculation</Typography>
                </Grid>
                <Grid item xs={3}>
                    <Typography sx={sub_title}>Gross pay amount</Typography>
                </Grid>
                <Grid item xs={9}>
                    <TextField sx={[input_base , { width : '150px'} ]}  size="small" placeholder={"bi-weekly gross amount"} disabled value={F.biweekly_calc.gross} 
                     InputProps={{startAdornment: <InputAdornment position="start">$</InputAdornment>}}  autoComplete={props.dev ? "off" : "new-password" } name="gross_amount"/>       
                </Grid>
                <Grid item xs={3}>
                    <Typography sx={sub_title}>Qc taxes / Fed taxes / RRQ / RQAP / Ae</Typography>
                </Grid>
                <Grid item xs={9}>
                    <TextField value={F.biweekly_calc.qc_tax.toFixed(2)} disabled name={'qc_tax'} sx={[input_base , { width : '150px'} ]} size={'small'} 
                    autoComplete={props.dev ? "off" : "new-password" } placeholder="0"  InputProps={{startAdornment: <InputAdornment position="start">$</InputAdornment>}}  />
                   <TextField value={F.biweekly_calc.fed_tax.toFixed(2)} disabled name={'fed_tax'} sx={[input_base , { width : '150px'} ]} size={'small'} 
                   autoComplete={props.dev ? "off" : "new-password" } placeholder="0"  InputProps={{startAdornment: <InputAdornment position="start">$</InputAdornment>}}  />
                      <TextField value={F.biweekly_calc.rrq.toFixed(2)} disabled name={'rrq_tax'} sx={[input_base , { width : '150px'} ]} size={'small'} 
                   autoComplete={props.dev ? "off" : "new-password" } placeholder="0"  InputProps={{startAdornment: <InputAdornment position="start">$</InputAdornment>}}  />
                      <TextField value={F.biweekly_calc.rqap.toFixed(2)} disabled name={'rqap_tax'} sx={[input_base , { width : '150px'} ]} size={'small'} 
                   autoComplete={props.dev ? "off" : "new-password" } placeholder="0"  InputProps={{startAdornment: <InputAdornment position="start">$</InputAdornment>}}  />
                       <TextField value={F.biweekly_calc.ae.toFixed(2)} disabled name={'ae_tax'} sx={[input_base , { width : '150px'} ]} size={'small'} 
                   autoComplete={props.dev ? "off" : "new-password" } placeholder="0"  InputProps={{startAdornment: <InputAdornment position="start">$</InputAdornment>}}  />
                </Grid>
                <Grid item xs={3}>
                    <Typography sx={sub_title}>Net pay amount </Typography>
                </Grid>
                <Grid item xs={9}>
                    <TextField sx={[input_base , { width : '150px'} ]} disabled  size="small" placeholder={"Net amount"} value={F.biweekly_calc.net.toFixed(2)}
                     InputProps={{startAdornment: <InputAdornment position="start">$</InputAdornment>}}   autoComplete={props.dev ? "off" : "new-password" } name="net_amount"/>
                    <TextField sx={[input_base , { width : '150px'} ]} disabled  size="small" placeholder={"tax rate"} value={F.biweekly_calc.rate.toFixed(2)}
                     InputProps={{endAdornment: <InputAdornment position="start">%</InputAdornment>}}   autoComplete={props.dev ? "off" : "new-password" } name="taxation"/>
                </Grid>
                <Grid item xs={12} sx={{ marginBottom : '1vh'}}> <hr style={bottom_line}/></Grid>
                <Grid item xs={12}>
                    <Typography sx={{ fontWeight : 800 , fontSize : 20 , marginBottom : '2vh'}}>Yearly taxes calculation</Typography>

                </Grid>
                <Grid item xs={3}>
                    <Typography sx={sub_title}>Gross pay amount</Typography>
                </Grid>
                <Grid item xs={9}>
                    <TextField sx={[input_base , { width : '150px'} ]}  size="small" placeholder={"index"} disabled value={F.year_calc.gross} 
                     InputProps={{startAdornment: <InputAdornment position="start">$</InputAdornment>}}  autoComplete={props.dev ? "off" : "new-password" } name="gross_amount"/>       
                </Grid>
                <Grid item xs={3}>
                    <Typography sx={sub_title}>Qc taxes / Fed taxes / RRQ / RQAP / Ae</Typography>
                </Grid>
                <Grid item xs={9}>
                    <TextField value={customCeil(F.year_calc.qc_tax)} disabled name={'qc_tax'} sx={[input_base , { width : '150px'} ]} size={'small'} 
                    autoComplete={props.dev ? "off" : "new-password" } placeholder="0"  InputProps={{startAdornment: <InputAdornment position="start">$</InputAdornment>}}  />
                   <TextField value={customCeil(F.year_calc.fed_tax)} disabled name={'fed_tax'} sx={[input_base , { width : '150px'} ]} size={'small'} 
                   autoComplete={props.dev ? "off" : "new-password" } placeholder="0"  InputProps={{startAdornment: <InputAdornment position="start">$</InputAdornment>}}  />
                      <TextField value={F.year_calc.rrq.toFixed(2)} disabled name={'rrq_tax'} sx={[input_base , { width : '150px'} ]} size={'small'} 
                   autoComplete={props.dev ? "off" : "new-password" } placeholder="0"  InputProps={{startAdornment: <InputAdornment position="start">$</InputAdornment>}}  />
                      <TextField value={F.year_calc.rqap.toFixed(2)} disabled  name={'rqap_tax'}  sx={[input_base , { width : '150px'} ]} size={'small'} 
                   autoComplete={props.dev ? "off" : "new-password" } placeholder="0"  InputProps={{startAdornment: <InputAdornment position="start">$</InputAdornment>}}  />
                       <TextField value={F.year_calc.ae.toFixed(2)} disabled name={'ae_tax'}  sx={[input_base , { width : '150px'} ]} size={'small'} 
                   autoComplete={props.dev ? "off" : "new-password" } placeholder="0"  InputProps={{startAdornment: <InputAdornment position="start">$</InputAdornment>}}  />
                </Grid>
                <Grid item xs={3}>
                    <Typography sx={sub_title}>Net pay amount / Taxation *</Typography>
                </Grid>
                <Grid item xs={9}>
                    <TextField sx={[input_base , { width : '150px'} ]} disabled  size="small" placeholder={"Net amount"} value={F.year_calc.net.toFixed(2)} 
                     InputProps={{startAdornment: <InputAdornment position="start">$</InputAdornment>}}   autoComplete={props.dev ? "off" : "new-password" } name="net_amount"/>
                    <TextField sx={[input_base , { width : '150px'} ]} disabled  size="small" placeholder={"tax rate"} value={F.year_calc.rate.toFixed(2)}
                     InputProps={{endAdornment: <InputAdornment position="start">%</InputAdornment>}}   autoComplete={props.dev ? "off" : "new-password" } name="taxation"/>
                </Grid>
            </>}
        </Grid>

        <hr style={bottom_line}/>
        <Grid container sx={{ marginTop : '1vh' , marginBottom : '1vh'}}>
            <Grid item xs={3}></Grid>
            <Grid item xs={6} sx={{ marginTop : '1vh' ,display : 'flex' , justifyContent : 'center'}}>
                <Button onClick={() => props.onClose('table')} sx={{ marginRight : '1vh' , backgroundColor : 'white' , color : 'black' , border : '1px solid gray'}}>Cancel</Button>
                <LoadingButton onClick={() => props.callback()} loading={props.loading} variant="contained" color="primary" sx={{fontWeight : 600}}>Submit</LoadingButton>
            </Grid>
            <Grid item xs={3}></Grid>
        </Grid>
    </Box>
  );
}

export default PayrollInputs