/*
 * Date: 2024
 * Description: Car layout Like a table with all car boxes
 * Author: Philippe Leroux @ skitsc
 */

import { Box } from "@mui/material"
import { ReactElement } from "react"
import { i_customer_car, i_customer_car_layout_props } from "../../interfaces/customer.interface";
import CarItem from "./cards/car.item";

const CarLayout = ( props : i_customer_car_layout_props ) : ReactElement => {
    return (
        <Box>
            { props.cars.map(( car : i_customer_car , index : number ) => (
                <Box key={index}>
                    <CarItem car={car} callback={props.callback}></CarItem>
                </Box>
            ))}
        </Box>
    )
}

export default CarLayout;