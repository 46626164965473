/*
 * Date: 2023
 * Description: Reusable Header of a page ( Contains title and subtitle )
 * Author: Philippe Leroux @ skitsc
 */

//Modules
import { Typography, Box , IconButton } from "@mui/material";

//Interfaces && types
import { i_header_props } from "../../interfaces/utility.interface";

//Icons
import ArrowBackIcon from "@mui/icons-material/ArrowBack";

const Header = (props : i_header_props) => {
  return (
    <>
    <Box sx={{ display : 'flex' , marginBottom : '1vh'}}>
      <Box sx={props.display === 'table' ? { visibility : 'hidden'} : {}}>
      <IconButton  onClick={() => props.callback('table')}>
          <ArrowBackIcon sx={{ fontSize : 40}}/>
      </IconButton>
      </Box>
      <Box sx={{ display : 'flex' , width : '100%'}}>
        <Typography sx={{ marginLeft : '2vh' , marginTop: '1vh' , fontWeight : 700 , fontSize : 20 , alignSelf : 'auto' }}>
          {props.title}
        </Typography>
      </Box>
    </Box>
  </>
  );
};
export default Header;
