/*
 * Date: 2023
 * Description: Cirular loading that display center of the screen.
 * Author: Philippe Leroux @ skitsc
 */

//Modules
import { Box , CircularProgress } from '@mui/material';

interface i_circ_underload {
  type : 'full' | 'table'
}
const CircularUnderLoad = ({ type } : i_circ_underload) => {
  return (
      <Box sx={ type === 'full' ? { paddingTop : '35vh' , display : 'flex' , justifyContent : 'center'} : { paddingTop : '15vh' , display : 'flex' , justifyContent : 'center'}}>
        <CircularProgress size={ type === 'full' ? 100 : 50} />
      </Box>
  )
 
}

export default CircularUnderLoad;