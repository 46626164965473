/*
 * Date: 2024
 * Description: side menu item component , handle collapsed
 * Author: Philippe Leroux @ skitsc
 */

//Modules
import { FC } from 'react';
import { Box , IconButton , Typography } from '@mui/material';
//Interfaces && types
import { i_menu_item } from '../../interfaces/utility.interface';
import { f_lighten_hex_color } from '../../utils/utility';

const SideMenuItem : FC<i_menu_item> = ({ title, Collapsed, icon , path , nav , mode , config}) => {
    const f_cap_first_letter = (input: string): string => {
        if(input.length === 0) return input; 
        const firstChar = input[0].toUpperCase();
        const restOfString = input.slice(1);
        return `${firstChar}${restOfString}`;
    }
    const formatColor = () => {
        if(mode === 'Light'){
            return f_lighten_hex_color(config.primary_color , 80)
        }else{
            return f_lighten_hex_color(config.primary_color , 50)
        }
    }

    return (
        <Box sx={ {  backgroundColor : path === '/'+title ? formatColor() : 'initial'}}>
      <IconButton onClick={() => nav('/'+title)} sx={[{ width: "100%", borderRadius: 1, alignItems: "center", justifyContent: "center" ,  paddingLeft : '20px'}  ]}> 
      
        <Box sx={[Collapsed ? { width : 'initial' } :{ width : '100%' , display : 'flex' , }]}>
          <Box sx={ Collapsed ? { paddingTop : '5px' } :{ alignSelf : 'self-end'}}>{icon}</Box>
          {!Collapsed && (
              <Typography component={'div'} sx={{ marginLeft : '12px' , fontSize:16 , alignSelf : 'center' , marginBottom : '2px' , fontWeight : path === '/'+title ? 600 : 500 }}>{f_cap_first_letter(title)}</Typography>
          )}
          {!Collapsed && <Box sx={{ width: "20%" }} />}
        </Box>
      </IconButton>
      </Box>
    );
  };

  export default SideMenuItem;