/*
 * Date: 2024
 * Description: Component for updating password of the current user
 * Author: Philippe Leroux @ skitsc
 */

//Modules
import { FC , ChangeEvent , useState , Dispatch , SetStateAction } from 'react';
import { TextField , Tooltip , IconButton , Grid , Typography } from '@mui/material';

//Interfaces && types
import { i_profile_password_item } from '../../interfaces/profile.interface';
import { LoadingButton } from '@mui/lab';

//icons
import VisibilityIcon from '@mui/icons-material/Visibility';
import VisibilityOffIcon from '@mui/icons-material/VisibilityOff';

//Styles
import { input_base, sub_title , bottom_line } from '../../styles/form.styles';

const ProfilePasswordItem : FC<i_profile_password_item> = ({ form , setForm , callback , errors , loading , selected}) => {
    const S = setForm;
    const V = form;
    const E = errors;
    const [ vis_one , setVisOne ] = useState<boolean>(true);
    const [ vis_two, setVisTwo ] = useState<boolean>(true);
    const [ vis_three, setVisThree ] = useState<boolean>(true);
    const Visibility = ({setOn , visibility } : { setOn: Dispatch<SetStateAction<boolean>>; visibility: boolean }) => {
        return (
            <Tooltip title={visibility ? 'Show' : 'Hide'}>
                <IconButton onClick={() => setOn((prevVisibility) => !prevVisibility)}>
                    {visibility? <VisibilityIcon /> : <VisibilityOffIcon />}
                </IconButton>
            </Tooltip>
        )
    }
    const handleValue = (e : ChangeEvent<HTMLInputElement>) => { 
        S({
           ...V,
            [e.target.name] : e.target.value
        })
    }
    return (
        <Grid  container mt={6}>
                <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                    <Typography sx={{ fontWeight : 800 , fontSize : 20 ,  alignSelf : 'center'}}>Auth management</Typography>
                    <Typography sx={{fontWeight : 800 , fontSize : 16 , marginBottom : '2vh' , color : 'grey'}}> Manage your password </Typography>
                </Grid>
                <Grid item xs={3}>
                    <Typography sx={sub_title} >Current password : </Typography>
                </Grid>
                <Grid item xs={9}>
                    <TextField size={'small'} name="old_password"  autoComplete="new-password" type={vis_one ? 'password' : 'text'}  sx={[input_base , { width : '350px'} ]}  error={E.old_password !== '' && true} helperText={E.old_password} value={V.old_password}
                     onChange={handleValue} 
                    InputProps={{ endAdornment: <Visibility setOn={setVisOne} visibility={vis_one} />}} />
                </Grid>
                <Grid item xs={3}>
                    <Typography sx={sub_title} >New password : </Typography>
                </Grid>
                <Grid item xs={9}>
                    <TextField size={'small'} name="password" autoComplete="new-password" type={vis_two ? 'password' : 'text'} sx={[input_base , { width : '350px'} ]} error={E.password !== '' && true} helperText={E.password} value={V.password} 
                    onChange={handleValue}
                    InputProps={{ endAdornment: <Visibility setOn={setVisTwo} visibility={vis_two} />}}/>   
                </Grid>
                <Grid item xs={3}>
                    <Typography sx={sub_title} >Confirm password : </Typography>
                </Grid>
                <Grid item xs={9}>
                    <TextField size={'small'} name="confirm_password" autoComplete="new-password" type={vis_three ? 'password' : 'text'}  sx={[input_base , { width : '350px'} ]}  error={E.confirm_password !== '' && true} helperText={E.confirm_password} value={V.confirm_password}
                     onChange={handleValue}
                    InputProps={{ endAdornment: <Visibility setOn={setVisThree} visibility={vis_three} />}}/>   
                </Grid>
                <Grid item xs={12} sx={{ marginBottom : '1vh'}}> <hr style={bottom_line}/></Grid>
                <Grid item xs={12}> 
                    <LoadingButton loading={loading && selected === 'password' ? true : false} onClick={() => callback('password', V)} variant="contained" color="primary" sx={{ display : 'block' , marginLeft : 'auto' , marginRight : 'auto' , width:'300px' }}>Change your password</LoadingButton>
                </Grid>
           
        </Grid>
    );
  };

  export default ProfilePasswordItem;