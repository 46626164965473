
/*
 * Date: 2024
 * Description: Payroll top views ( data )
 * Author: Philippe Leroux @ Skitsc
 */

//Modules
import { ReactElement } from "react";
import { Box , Grid , Typography , Accordion , AccordionDetails , AccordionSummary } from "@mui/material"

//Icons
import ArrowUpwardIcon from '@mui/icons-material/ArrowUpward';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';

//Styles
import { box_sizing , star_style } from "../../../styles/view.styles";

const PayrollTopView = ( props : any ) : ReactElement => {

        return (
            <Accordion expanded={props.acc_open} onChange={() => props.handleAcc('stats')}>
                <AccordionSummary sx={{ borderColor : '#D3D3D3'}} expandIcon={<ExpandMoreIcon />} aria-controls="panel-job-search" id="panel-job-search">
                    <Typography sx={{ fontWeight : 600}}>Stats</Typography>
                </AccordionSummary>
                <AccordionDetails>
                <Grid container sx={{ display : 'flex' }}>
                    <Grid item sx={{ display : 'flex' }}>
                        <Box sx={[box_sizing , { width : '550px' }]}>
                            <Grid container spacing={2}>
                                <Grid item xs={12} sx={{ height : '100%' , display : 'flex' , marginBottom : '0.2vh' }}>
                                    <Typography sx={{ fontWeight : 800 , fontSize : 18 , paddingRight : '8px'}}>{props.period}</Typography>
                                </Grid>
                                <Grid item xs={3} mt={0.5}>
                                    <Box sx={{borderRight : '1px solid #ccc'}}>
                                        <Box sx={{ display : 'flex' }}>
                                            <Typography sx={{ fontWeight : 800 , fontSize : 24 , marginRight : '2px' ,}}> {props.pay_total} $ </Typography>
                                        </Box>
                                        <Box sx={{ display : 'flex' , marginTop : '1vh'}}>
                                            <Typography sx={{ paddingRight : '6px' , fontSize : 13}}>Pay total spend</Typography>
                                        </Box>
                                    </Box>
                                </Grid>
                                <Grid item xs={3} mt={0.5}>
                                    <Box sx={{borderRight : '1px solid #ccc'}}>
                                        <Box sx={{ display : 'flex' , width : '100%'}}>
                                            <Typography sx={{ fontWeight : 800 , fontSize : 24 , marginRight : '2px' ,}}> {props.net_pay} $ </Typography>
                                        </Box>
                                        <Box sx={{ display : 'flex' , marginTop : '1vh' , width : '100%'}}>
                                            <Typography sx={{ paddingRight : '6px' , fontSize : 13}}>Net pays</Typography>
                                        </Box>
                                    </Box>
                                </Grid>
                                <Grid item xs={3} mt={0.5}>
                                    <Box sx={{borderRight : '1px solid #ccc'}}>
                                        <Box sx={{ display : 'flex' }}>
                                            <Typography sx={{ fontWeight : 800 , fontSize : 24 , marginRight : '2px' ,}}> {props.total_taxes} $ </Typography>
                                        </Box>
                                        <Box sx={{ display : 'flex' , marginTop : '1vh'}}>
                                            <Typography sx={{ paddingRight : '6px' , fontSize : 13}}>Total taxes</Typography>
                                        </Box>
                                    </Box>
                                </Grid>
                                <Grid item xs={3} mt={0.5}>
                                    <Box sx={{}}>
                                        <Box sx={{ display : 'flex'  }}>
                                            <Typography sx={{ fontWeight : 800 , fontSize : 24 , marginRight : '2px' ,}}> {props.pay_count} </Typography>
                                        </Box>
                                        <Box sx={{ display : 'flex' , marginTop : '1vh'}}>
                                            <Typography sx={{ paddingRight : '6px' , fontSize : 13}}>Paid staff</Typography>
                                        </Box>
                                    </Box>
                                </Grid>
                            </Grid>
                        </Box>
                    </Grid>

                    <Grid item  sx={{ display : 'flex'}}>
                        <Box sx={[box_sizing , { marginTop : '0%'} ]}>
                        <Grid container>
                                <Grid item xs={12} sx={{ height : '100%'}}>
                                    <Typography sx={{ fontWeight : 800 ,fontSize : 15}}>Satisfaction score </Typography>
    
                                </Grid>
                                <Grid item xs={12}>
                                    <Typography sx={{ color : 'gray' , fontSize : 14}}>Total reviews</Typography>
                                </Grid>
                                <Grid item xs={6}>
                                    <Box sx={{ display : 'flex' ,marginTop : '0.5vh' }}>
                                        <Typography sx={{ fontWeight : 800 , fontSize : 15}}>{props.total_ratings}</Typography>
                                    </Box>
                                </Grid>
                                <Grid item xs={6}>
                                    <Box sx={{ display : 'flex' ,marginTop : '0.5vh' }}>
                                        <Typography sx={{ fontWeight : 800 , fontSize : 15 , paddingRight : '4px'}}>{props.global_rating}</Typography>
                                        <Box component={'img'} alt={'star img'} src={'./images/star.png'} sx={star_style}></Box>
                                    </Box>
                                </Grid>
                                <Grid item xs={6}>
                                    <Box sx={{ display : 'flex',marginTop : '0vh' }}>
                                        <ArrowUpwardIcon sx={{color : '#009900' , fontSize : 16}}/>
                                        <Typography sx={{color : '#009900' , marginRight : '8px' , alignSelf : 'center' , fontSize : 14}}>{props.percent_total}%</Typography>
                                        <Typography sx={{ fontSize : 12 , alignSelf : 'center' }}>vs last month</Typography>
                                    </Box>
                                </Grid>
                                <Grid item xs={6}>
                                    <Box sx={{ display : 'flex',marginTop : '0vh' }}>
                                        <ArrowUpwardIcon sx={{color : '#009900' , fontSize : 16}}/>
                                        <Typography sx={{color : '#009900' , marginRight : '8px' , alignSelf : 'center' , fontSize : 14}}>{props.percent_month}%</Typography>
                                        <Typography sx={{ fontSize : 12 , alignSelf : 'center' }}>vs last month</Typography>
                                    </Box>
                                </Grid>
                                <Grid item xs={4}>
                                    <Box sx={{ display : 'flex' , marginTop : '0.2vh' ,  marginBottom : '0.2vh', borderTop : '1px solid #D3D3D3',}}>
                                        <Box component={'img'} alt={'star img'} src={'./images/star.png'} sx={star_style}></Box>
                                        <Box component={'img'} src={'./images/happy.svg'} sx={{ alignSelf : 'center' , marginRight : '4px'}}/>
                                        <Typography sx={{fontSize : 16 , alignSelf : 'center'}}>4-5</Typography>
                                    </Box>
                                </Grid>
                                <Grid item xs={4}>
                                <Box sx={{ display : 'flex' , marginTop : '0.2vh' , marginBottom : '0.2vh', borderTop : '1px solid #D3D3D3',}}>
                                        <Box component={'img'} alt={'star img'} src={'./images/star.png'} sx={star_style}></Box>
                                        <Box component={'img'} src={'./images/mid.svg'} sx={{color : 'yellow', fontSize : 20 , alignSelf : 'center' , marginRight : '4px'}}/>
                                        <Typography sx={{fontSize : 16 , alignSelf : 'center'}}>2-3</Typography>
                                    </Box>
                                </Grid>
                                <Grid item xs={4}>
                                <Box sx={{ display : 'flex' , marginTop : '0.2vh' ,  marginBottom : '0.2vh', borderTop : '1px solid #D3D3D3',}}>
                                        <Box component={'img'} alt={'star img'} src={'./images/star.png'}sx={star_style} />
                                        <Box component={'img'} src={'./images/sad.svg'} sx={{alignSelf : 'center' , marginRight : '4px'}}/>
                                        <Typography sx={{fontSize : 16 , alignSelf : 'center'}}>1-2</Typography>
                                    </Box>
                                </Grid>
                                <Grid item xs={4}>
                                    <Typography sx={{fontSize : 15}}>{props.high_rating} user</Typography>
                                </Grid>
                                <Grid item xs={4}>
                                    <Typography sx={{fontSize : 15}}>{props.mid_rating} user</Typography>
                                </Grid>
                                <Grid item xs={4}>
                                    <Typography sx={{fontSize : 15}}>{props.low_rating} user</Typography>
                                </Grid>
                            </Grid>
                        </Box>
                    </Grid>
       
                </Grid>
                </AccordionDetails>
            </Accordion>
        )
}


export default PayrollTopView;