/*
 * Date: 2023
 * Description: Tooltip custom for color management.
 * Author: Philippe Leroux @ Skitsc
 */


//Modules
import Tooltip , { TooltipProps, tooltipClasses } from '@mui/material/Tooltip';

//Styles
import { styled } from '@mui/material/styles';


const BlueToolTip = styled(({ className, ...props }: TooltipProps) => (
    <Tooltip {...props} classes={{ popper: className }} />
  ))(({ theme }) => ({
    [`& .${tooltipClasses.tooltip}`]: {
      backgroundColor: '#101828',
      color: 'white',
      maxWidth: 220,
      fontSize: theme.typography.pxToRem(12),
      border: '1px solid #dadde9',
    },
}));

  export { BlueToolTip }