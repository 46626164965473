/*
 * Date: 2024
 * Description: Jobs inputs..
 * Author: Philippe Leroux @ Skitsc
 */

//Modules
import { ReactElement, ChangeEvent } from "react";
import { Box, Typography, MenuItem, TextField, Grid , Button, IconButton } from "@mui/material";
import { LoadingButton } from "@mui/lab";

//Components
import UploadsInputs from "../../utility/upload.box";

//Interfaces && types
import { i_jobs_inputs_props } from "../../../interfaces/jobs.interface";
import { i_user } from "../../../interfaces/user.interface";
//Styles
import { bottom_line, input_base, sub_title } from "../../../styles/form.styles";

//Constants
import { empty_customer, empty_customer_car, empty_user } from "../../../utils/constant";


//Icons
import { ArrowBack } from "@mui/icons-material";

const JobsInputs = ( props : i_jobs_inputs_props) : ReactElement => {
    const F = props.form;
    const E = props.errors;
    const setCustomer = ( val : any ) => {
        const row = props.customers.find( (x : any) => x._id === val)
        if(row){
            props.setForm({ ...F , customer : row , car : empty_customer_car })
        }else{
            props.setForm({ ...F, customer : empty_customer})
        }
    }
    const setCar = ( val : string ) => {
        const row = props.cars.find( (x : any) => x._id === val)
        props.setForm({ ...F , car : row ? row : empty_customer_car})
    }
    const setUser = ( val : string ) => {
        const row = props.users.find( (x : any) => x._id === val)
        props.setForm({ ...F , user : row ? row : empty_user})
    }
    const handleChange = (event : ChangeEvent<HTMLInputElement>) => {
        const { name, value } = event.target;
        props.setForm({ ...F, [name] : value})
    }
  return (
    <Box p={2}  sx={{ marginBottom : '2vh' }} id={'job_inputs'} >
        <Grid container>
            <Grid item xs={12}>
                <Box sx={{ display : 'flex' }}>
                        <IconButton sx={{ marginRight : '4px'}} onClick={() => props.setDisplay('table')}><ArrowBack color={'primary'} sx={{ marginRight : 'auto'}}/></IconButton>
                        <Typography sx={{ fontWeight : 800 , fontSize : 20 ,  alignSelf : 'center'}}>Add a job</Typography>
                </Box>
                <Typography sx={{ fontWeight : 800 , fontSize : 16 , marginBottom : '2vh' , color : 'grey'}}>Enter your job informations</Typography>
            </Grid>
            <Grid item xs={3}>
                <Typography sx={sub_title}>Order title* / Order Number *</Typography>
            </Grid>
            <Grid item xs={9}>
                <TextField sx={[input_base , { width : '300px'} ]} name={'title'} size="small" placeholder={"Order title"} value={F.title} error={E.title !== ""} helperText={E.title}
                onChange={handleChange} autoComplete={props.dev ? "off" : "new-password" }/>  
                <TextField sx={[input_base , { width : '300px'} ]} name={'order_number'} size="small" placeholder={"Order number"} value={F.order_number} error={E.order_number !== ""} helperText={E.order_number}
                onChange={handleChange} autoComplete={props.dev ? "off" : "new-password" }/>                 
            </Grid>
            <Grid item xs={12} sx={{ marginBottom : '1vh'}}> <hr style={bottom_line}/></Grid>
            <Grid item xs={3}>
                <Typography sx={sub_title}>Claim number / Liaison Officer*</Typography>
            </Grid>
            <Grid item xs={9}>
                <TextField sx={[input_base , { width : '300px'} ]} size="small" name={'claim_number'} placeholder={"Claim number"} value={F.claim_number} error={E.claim_number !== ""} helperText={E.claim_number}
                onChange={handleChange} autoComplete={props.dev ? "off" : "new-password" }/>  
                   <TextField select sx={[input_base , { width : '300px'} ]}size="small" value={F.user._id !== ''  ? F.user._id : "default"} error={E.user_id !== ''} helperText={E.user_id}
                    onChange={( event: ChangeEvent<any> ) => setUser(event.target.value)} autoComplete={props.dev ? "off" : "new-password" }>
                        <MenuItem value={"default"} disabled>Select an officer</MenuItem>
                        {props.users.map((user : i_user) => (
                            <MenuItem key={user._id} value={user._id}>{user.first_name + " " + user.last_name}</MenuItem>
                        ))}
                </TextField>
            </Grid>

            <Grid item xs={12} sx={{ marginBottom : '1vh'}}> <hr style={bottom_line}/></Grid>
            <Grid item xs={3}>
                <Typography sx={sub_title}>Description </Typography>
            </Grid>
            <Grid item xs={9}>
                <TextField sx={[input_base , { width : '600px'} ]} name={'description'} multiline rows={3} size="small" value={F.description} error={E.description !== ''} helperText={E.description}
                onChange={handleChange} autoComplete={props.dev ? "off" : "new-password" }/>
            </Grid>
            <Grid item xs={12} sx={{ marginBottom : '1vh'}}> <hr style={bottom_line}/></Grid>
            <Grid item xs={12} sx={{ marginBottom : '1vh'}}>
                <Typography sx={sub_title}>Customer informations </Typography>
                <Typography sx={{ fontSize : 14 , color : 'grey'}}>Start by selecting a customer to select a car</Typography>
            </Grid>  
            <Grid item xs={3}>
                <Typography sx={sub_title}>Select a customer *</Typography>
            </Grid>
            <Grid item xs={9}>
                <TextField select sx={[input_base , { width : '300px'} ]}size="small" value={F.customer._id !== ''  ? F.customer._id : "default"} error={E.customer_id !== ''} helperText={E.customer_id}
                onChange={( event: ChangeEvent<any> ) => setCustomer(event.target.value)} autoComplete={props.dev ? "off" : "new-password" }>
                    <MenuItem value={"default"} disabled>Select a customer</MenuItem>
                    {props.customers.map((customer : any) => (
                        
                        <MenuItem key={customer._id} value={customer._id}>{customer.first_name + " " + customer.middle_name + ' ' + customer.last_name}</MenuItem>
                    ))}
                </TextField>
            </Grid>
            <Grid item xs={12} sx={{ marginBottom : '1vh'}}> <hr style={bottom_line}/></Grid>
            {F.customer._id !== '' &&
            <>
                <Grid item xs={3}>
                    <Typography sx={sub_title}>Select a car *</Typography>
                </Grid>
                <Grid item xs={9}>
                    {props.cars.length > 0 ?
                    <TextField sx={[input_base , { width : '300px'} ]} size="small" value={F.car._id !== ''  ? F.car._id : "default"} onChange={( event: ChangeEvent<any>) => setCar(event.target.value)}
                    error={E.car_id !== ''} select helperText={E.car_id}autoComplete={props.dev ? "off" : "new-password" }>
                    <MenuItem value="default" disabled>Select a car</MenuItem>
                        {props.cars.map((car : any) => (
                            <MenuItem key={car._id} value={car._id}>{car.make + " " + car.model + ' ' + car.year}</MenuItem>
                        ))}
                    </TextField>
                    :
                    <Typography sx={[input_base , { color : 'red' }]}>Please add a car first ..</Typography>
                    }
                </Grid>
                <Grid item xs={12} sx={{ marginBottom : '1vh'}}> <hr style={bottom_line}/></Grid>
            </>
            }
        </Grid>
        <Grid container>
            <Grid item xs={12} sx={{ marginTop : '1vh'}}>
                <Typography sx={{ fontWeight : 800 , fontSize : 20}}> Add details </Typography>
                <Typography sx={{ marginBottom : '1vh' , color : 'gray'}}> Uploads revelant photos</Typography>
            </Grid>
            <Grid item xs={12}>
                <UploadsInputs {...props.uploads}/>
            </Grid>
        </Grid>
        <hr style={bottom_line}/>
        <Grid container sx={{ marginTop : '1vh' , marginBottom : '1vh'}}>
            <Grid item xs={3}></Grid>
            <Grid item xs={6} sx={{ marginTop : '1vh' ,display : 'flex' , justifyContent : 'center'}}>
                <Button onClick={() => props.setDisplay('table')} sx={{ marginRight : '1vh' , backgroundColor : 'white' , color : 'black' , border : '1px solid gray'}}>Cancel</Button>
                <LoadingButton onClick={() => props.callback()} loading={props.loading} variant="contained" color="primary" sx={{fontWeight : 600}}>Submit</LoadingButton>
            </Grid>
            <Grid item xs={3}></Grid>
        </Grid>
    </Box>
  );
}


export default JobsInputs;