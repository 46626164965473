/*
 * Date: 2024
 * Description: Extension to car page where more settings is shown
 * Author: Philippe Leroux @ skitsc
 */


//Modules
import { Box , Grid , Button, Typography, IconButton } from "@mui/material"

//Icons
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import RemoveRedEyeIcon from '@mui/icons-material/RemoveRedEye';
import AddIcon from '@mui/icons-material/Add';
import UploadIcon from '@mui/icons-material/Upload';

const CarMore = ( props : any ) => {
    const T = props.target
    const f_return_img = () => {
        var img = '/images/makes/'+ T.make.toLowerCase() + '-logo.png'
        return img
    }
    const sm_title = {
        alignSelf : 'center',
        paddingLeft : '6px',
        fontSize : 20
    }

    return (
        <Box>
            <Grid container>
                <Grid item xs={12}>
                    <Box sx={{ display : 'flex' }}>
                        <IconButton onClick={() => props.return()}><ArrowBackIcon/></IconButton>
                        <Typography sx={sm_title}>{T.make}</Typography>
                        <Typography sx={sm_title}>{T.model}</Typography>
                        <Typography sx={sm_title}>{T.trim}</Typography>
                        <Typography sx={sm_title}>{T.year}</Typography>
                    </Box>
                </Grid>
                <Grid item xs={5} sx={{ padding : '20px'}}>
                    <Box sx={{ border : '1px solid gray' , width : '100%' , height: '25vh' , borderRadius : '12px'}}>
                        <Box sx={{display : 'flex', justifyContent : 'flex-start' , padding : '0.8vh' , borderBottom : '1px solid gray'}}>
                            <Typography sx={{}}>Car photos</Typography>
                            <IconButton sx={{ marginLeft :'auto' , padding : '0px'}} onClick={() => {}}><RemoveRedEyeIcon/></IconButton>
                        </Box>
                        <Box sx={{ display : 'flex' , justifyContent : 'center'}}>
                            <Box sx={{ maxWidth : '200px'  , marginTop : '1vh'}} component={'img'} src={f_return_img()}></Box>
                        </Box>
                    </Box>
                </Grid>
                <Grid item xs={5}  sx={{ padding : '20px'}}>
                    <Box sx={{ border : '1px solid gray' , width : '100%' , height: '25vh' , borderRadius : '12px'}}>
                        <Box sx={{display : 'flex', justifyContent : 'flex-start' , padding : '0.8vh' , borderBottom : '1px solid gray'}}>
                            <Typography sx={{}}>Additonnal details</Typography>
                        </Box>
                        <Box sx={{ padding : '8px'}}>
        
                        <Grid container sx={{marginBottom : '0.4vh'}} p={1}>
                            <Grid item xs={6} sx={{ }}>
                                <Typography sx={{ fontSize : 16 , marginTop : '6px'}}>No Registration :</Typography>
                            </Grid>
                            <Grid item xs={6}>
                                <Box sx={{ marginLeft : 'auto'}}>
                                { T.registration[0] !== undefined ? 
                                    <Box sx={{ display : 'flex'}}>
                                        <Typography sx={{ fontSize : 16 , alignSelf : 'center'}}>{T.registration[0].registration_number}</Typography>
                                        <IconButton sx={{ marginLeft : 'auto'}} onClick={() => props.callback('view_registration')} ><RemoveRedEyeIcon/></IconButton>
                                    </Box>
                                : <Button onClick={() =>  props.callback('registration')} variant="contained">Add registration</Button> }
                                </Box>
                            </Grid>
                        </Grid>

                            <Grid container sx={{marginBottom : '0.4vh'}} p={1}>
                                <Grid item xs={6} sx={{ }}>
                                    <Typography sx={{ fontSize : 16 , marginTop : '6px'}}>No Insurance :</Typography>
                                </Grid>
                                <Grid item xs={6}>
                                <Box sx={{ marginLeft : 'auto'}}>
                                { T.insurance[0] !== undefined ? 
                                    <Box sx={{ display : 'flex'}}>
                                        <Typography sx={{ fontSize : 16 , alignSelf : 'center'}}>{ T.insurance[0].no_police }</Typography>
                                        <IconButton sx={{ marginLeft : 'auto'}} onClick={() => props.callback('view_insurance')}><RemoveRedEyeIcon/></IconButton>
                                    </Box>
                                    : <Button onClick={ () => props.callback('insurance')} variant="contained">Add insurance</Button> }
                                </Box>
                                </Grid>

                            </Grid>
                          
                            
                        </Box>
                    </Box>
                </Grid>
                <Grid item xs={2}  sx={{  padding : '20px'}}>
                    <Box sx={{height: '25vh' ,  border : '1px solid gray' , borderRadius : '12px' }}>
                        <Box sx={{display : 'flex', justifyContent : 'flex-start' , padding : '0.8vh' , borderBottom : '1px solid gray'}}>
                            <Typography sx={{}}>Actions</Typography>
                        </Box>
                        <Box sx={{ marginTop : '1vh' , padding : '6px'}}>
                            <Button fullWidth variant={'contained'} sx={{ padding : '4px' , fontSize : '12px'}} onClick={() => props.callback('manage_registration')}>Manage registration</Button>
                            <Button fullWidth variant={'contained'} onClick={() => props.callback('manage_insurance')} sx={{ marginTop : '0.8vh' , padding : '4px' , fontSize : '12px'}}>Manage insurances</Button>
                            <Button fullWidth variant={'contained'} onClick={() => props.callback('service')} sx={{ marginTop : '0.8vh' , padding : '4px' , fontSize : '12px'}}>Program a service</Button>
                        </Box>
                    </Box>
                </Grid>
                <Grid item xs={12} sx={{ padding : '20px'}}>
                    <Box sx={{border : '1px solid gray' , width : '100%' , height: '30vh' , borderRadius : '12px'}}>
                        <Box sx={{display : 'flex', justifyContent : 'flex-start' , padding : '0.8vh' , borderBottom : '1px solid gray'}}>
                            <Typography sx={{}}>History</Typography>
                        </Box>
                    </Box>
                </Grid>
                <Grid item xs={6} sx={{ padding : '20px'}}>
                    <Box sx={{border : '1px solid gray' , width : '100%' , height: '20vh' , borderRadius : '12px'}}>
                        <Box sx={{display : 'flex', justifyContent : 'flex-start' , padding : '0.8vh' , borderBottom : '1px solid gray'}}>
                            <Typography sx={{}}>Logs</Typography>
                            <IconButton onClick={() => props.callback('logs')} sx={{ marginLeft : 'auto' , height : '24px'}}><UploadIcon color={'primary'}/></IconButton>
                        </Box>
                    </Box>
                </Grid>
                <Grid item xs={6} sx={{ padding : '20px'}}>
                    <Box sx={{border : '1px solid gray' , width : '100%' , height: '20vh' , borderRadius : '12px'}}>
                        <Box sx={{display : 'flex', justifyContent : 'flex-start' , padding : '0.8vh' , borderBottom : '1px solid gray'}}>
                            <Typography sx={{}}>Notes</Typography>
                            <IconButton onClick={() => props.callback('notes')} sx={{ marginLeft : 'auto' , height : '24px'}}><AddIcon color={'primary'}/></IconButton>
                        </Box>
                        
                    </Box>
                </Grid>
            </Grid>
        </Box>
    )
}

export default CarMore