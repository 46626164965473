/*
 * Date: 2023
 * Description: New tool bar
 * Author: Philippe Leroux @ skitsc
 */

//Modules
import { Box , Button , TextField } from "@mui/material"

//Icons
import AddIcon from '@mui/icons-material/Add';
import SearchIcon from '@mui/icons-material/Search';
//Interfaces
import { i_tool_bar_props } from "../../../interfaces/utility.interface";

const ToolBarV2 = ( props :  i_tool_bar_props) => {
    const P = props.split === undefined ? false : true
    return (
        <Box sx={{ paddingBottom : '0.5vh' , display : 'flex'}}>
            {props.searchable &&
            <Box sx={{ marginLeft : !P ? 'initial' : 'auto'}}>
                <TextField size={'small'} placeholder={props.placeholder} value={props.search} onChange={(e) => props.setSearch(e.target.value)} ></TextField>
                <Button sx={{ marginLeft : '10px' , fontWeight : 600 , color: 'white' , height : '40px' , padding : 1}} variant="contained" color="primary" onClick={() => props.callbackSearch()} startIcon={<SearchIcon />}>Search</Button>
            </Box>
            } 
            <Box sx={{ marginLeft : P ? '10px' : 'auto'}}>
                <Button onClick={() => props.callbackAdd('form')} sx={{ fontWeight : 600 , color: 'white' , height : '40px', padding : 1}} variant="contained" startIcon={<AddIcon />}>Add</Button>
            </Box>
        </Box>
    )
}

export default ToolBarV2