/*
 * Date: 2024
 * Description: Entry point of the application.
 * Author: Philippe Leroux @ skitsc
 */


//Modules
import { useState, useEffect , useMemo , useContext } from "react";
import { Routes, Route, Navigate } from "react-router-dom";
import { CssBaseline } from "@mui/material";

//Menu
import UI from "./components/ui/ui";

//pages
import Login from "./page/login";
import Dashboard from "./page/dashboard";
import Logout from "./page/logout";
import Customers from "./page/customers";
import Settings from "./page/settings";
import Profile from "./page/profile";
import Banking from "./page/banking";
import Notifications from "./page/notifications";
import Expense from "./page/expense";
import Calendar from "./page/calendar";
import Users from "./page/settings/users";
import Invoicing from "./page/invoicing";
import Reports from "./page/reports";
import Payroll from "./page/payroll";
import Help from "./page/help";
import Recovery from "./page/recovery";
import Jobs from "./page/jobs";
import AppConfig from "./page/settings/app.config";
import "./index.css"

//Theming
import { ThemeProvider } from '@mui/material/styles';
import { generateTheme } from "./theme"

//Context
import { MainContext , ThemeContext } from "./context/context";
import { MainHook, ThemeHook } from "./context/hook";
import { SocketProvider , SocketContext, socket_id} from "./context/socket.context";

//Api
import { f_fetch } from "./api/fetch";

//Types and interfaces
import { i_promise } from "./interfaces/utility.interface";

//Components
import LoadingAuth from "./components/auth/loading.auth";

//Constant
import { default_user_config } from "./utils/constant";

//Utilities
import { f_set_local_key , f_get_local_key } from "./utils/utility";
import Links from "./page/links";



const App = () => {
    const ContextState = MainHook();
    const ThemeState = ThemeHook();
    const [ isLoading, setIsLoading ] = useState(true);
    const [ mobile , setMobile ] = useState(false);
    const socket = useContext(SocketContext);   

    useEffect(() => {
        const f_fetch_handler = async () => {
            setIsLoading(true);
            const get_config : i_promise = await f_fetch('/config', 'GET', false, null);
            if(get_config.type === 'Success'){
                ContextState.setCurrentConfig(get_config.data);
                ThemeState.setCurrentColor(get_config.data)
            }
            const api_rep: i_promise = await f_fetch('/auth' , 'POST' , true , null);
            if (api_rep.type === "Success") {
                ContextState.HandleLogin(api_rep.data);
                socket.emit('join', { roomId : api_rep.data.type , user_id : api_rep.data._id , socket_id : socket_id});
            }
                setIsLoading(false);
            };
            function handleResize() {
                const mobileHeightBreakpoint = 900;
                const isMobile = window.innerHeight <= mobileHeightBreakpoint;
                setMobile(isMobile);
            }
            const set = f_get_local_key('config');
            if(set === null) f_set_local_key('config', default_user_config , 1000 * 60 * 60 * 24)
            f_fetch_handler();
            handleResize();
            window.addEventListener('resize', handleResize);
            return () => {
            window.removeEventListener('resize', handleResize);
        };
        //eslint-disable-next-line react-hooks/exhaustive-deps 
    }, []);
    const useAuth = () => {
        if (ContextState.authenticated) {
            return true
        } else return false
    } 
    const PrivateRoute = ({ children }: any) => {
        const auth = useAuth();
        return auth ? children : <Navigate to="/" />;
    }
    const PublicRoutes = ({ children }: any) => {
        const auth = useAuth();
        return !auth ? children : <Navigate to="/overview" />;
    }
const themeWithLocale = useMemo(
    () => generateTheme(
        ThemeState.mode, // Pass your mode
        ThemeState.primary_color,// Pass your primaryColor
        ThemeState.secondary_color, // Pass your secondaryColor
        ThemeState.mode === 'Dark' ? "#222" : '#FFF', // Pass your backgroundColor
        ThemeState.mode === 'Dark' ? '#555' : '#111', // Pass your borderColor
        ThemeState.mode === 'Dark' ? '#FFF' : '#000', // Pass your textColor
        ThemeState.selected_color, // Pass your selectedActionColor
        ThemeState.hover_color // Pass your hoverActionColor
    ),
    [ThemeState.mode , ThemeState.primary_color , ThemeState.secondary_color , ThemeState.hover_color , ThemeState.selected_color ],
);
    const props = {
        mobile : mobile,
    }
    return (
        <MainContext.Provider value={ContextState}>
        <SocketProvider>
            <ThemeContext.Provider value={ThemeState}>
            <ThemeProvider theme={themeWithLocale}>
                <CssBaseline />
                {isLoading ? (
                    <LoadingAuth/>
                ) : (
                    <Routes>
                    <Route path="/" element={<PublicRoutes><Login {...props}/></PublicRoutes>}/>
                    <Route path="/recovery" element={<PublicRoutes><Recovery {...props}/></PublicRoutes>}/>
                        <Route path="/overview" element={<PrivateRoute><UI MyComponent={<Dashboard {...props}/>} mobile={mobile}/></PrivateRoute>}/>
                        <Route path="/jobs" element={<PrivateRoute><UI MyComponent={<Jobs {...props}/>} mobile={mobile}/></PrivateRoute>}/>
                        <Route path="/invoicing" element={<PrivateRoute><UI MyComponent={<Invoicing {...props}/>} mobile={mobile}/></PrivateRoute>}/>
                        <Route path="/settings/users" element={<PrivateRoute><UI MyComponent={<Users {...props}/>} mobile={mobile}/></PrivateRoute>}/>
                        <Route path="/settings/app.config" element={<PrivateRoute><UI MyComponent={<AppConfig {...props} />} mobile={mobile}/></PrivateRoute>}/>
                        <Route path="/customers" element={<PrivateRoute><UI MyComponent={<Customers {...props} />}mobile={mobile}/></PrivateRoute>}/>
                        <Route path="/reports" element={<PrivateRoute><UI MyComponent={<Reports {...props}/>} mobile={mobile}/></PrivateRoute>} />
                        <Route path="/payroll" element={<PrivateRoute><UI MyComponent={<Payroll {...props}/>} mobile={mobile}/></PrivateRoute>} />
                        <Route path="/billing" element={<PrivateRoute><UI MyComponent={<Payroll {...props}/>} mobile={mobile}/></PrivateRoute>} />
                        <Route path="/links" element={<PrivateRoute><UI MyComponent={<Links {...props}/>} mobile={mobile}/></PrivateRoute>} />
                        <Route path="/settings" element={<PrivateRoute><UI MyComponent={<Settings {...props}/>} mobile={mobile}/></PrivateRoute>}/>
                        <Route path="/profile" element={<PrivateRoute><UI MyComponent={<Profile {...props}/>} mobile={mobile}/></PrivateRoute>}/>
                        <Route path="/notifications" element={<PrivateRoute><UI MyComponent={<Notifications {...props} />}mobile={mobile}/></PrivateRoute>}/>
                        <Route path="/banking" element={<PrivateRoute><UI MyComponent={<Banking {...props}/>} mobile={mobile}/></PrivateRoute>}/>
                        <Route path="/expense" element={<PrivateRoute><UI MyComponent={<Expense {...props}/>} mobile={mobile}/></PrivateRoute>}/>
                        <Route path="/calendar" element={<PrivateRoute><UI MyComponent={<Calendar {...props} />}mobile={mobile}/></PrivateRoute>}/>
                        <Route path="/help" element={<PrivateRoute><UI MyComponent={<Help {...props} />}mobile={mobile}/></PrivateRoute>}/>
                    <Route path="/logout" element={<Logout {...props}/>}/>
                    </Routes>
                )}
                </ThemeProvider>
                </ThemeContext.Provider>
            </SocketProvider>
        </MainContext.Provider>
    );
}

export default App;
