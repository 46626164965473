
/*
* Date: 2024
* Description: Constant reusable threw the app.
* Author: Philippe Leroux @ skitsc
*/

//Interfaces && types
import { i_customer,  i_customer_licence , i_customer_car,  i_customer_search_filter, i_customer_errors, i_contact, i_customer_car_errors, i_insurance_errors, i_registration_errors, i_registration, i_insurance } from "../interfaces/customer.interface";
import { i_error , i_promise , i_search_filter,} from "../interfaces/utility.interface";
import { i_user, i_user_errors } from "../interfaces/user.interface";
import { i_car , i_car_selection_options } from "../interfaces/car.interface";
import { i_order } from "../interfaces/order.interface";
import { i_task, i_task_complete, i_task_errors} from "../interfaces/task.interface";
import { i_tbl_header } from "../interfaces/utility.interface";
import { header_row } from "../styles/tbl.styles";
import { i_user_type } from "../interfaces/utility.interface";
import { i_complete_job, i_job_errors, i_job_search_filter, i_jobs_search } from "../interfaces/jobs.interface";
import { i_app_config, i_app_config_errors } from "../interfaces/setting.interface";
import { t_task_state, t_task_type , t_cal_view } from "../types/types";
import { i_cal_event, i_cal_event_errors } from "../interfaces/planner.interface";
import { i_complete_pay, i_tax_values , i_pay_errors } from "../interfaces/payroll.interface";

const default_error : i_error = {
	err: false,
	message: ""
}
const empty_customer_license : i_customer_licence = {
	licence_number : '',
	licence_province : 'QC',
	reference_number : '',
	delivery_date : Date.now() / 1000,
	expiration_date : Date.now() / 1000,
	birth_date : Date.now() / 1000,
	path : []
}

const empty_tax : i_tax_values = {
	gross : 0,
    net : 0,
    ae : 0,
    rrq : 0,
    rqap : 0,
    qc_tax : 0,
    fed_tax : 0,
    rate : 0,
    type : 'default'
}
const empty_customer: i_customer = {
	_id: "",
	customer_type: "Individual",
	title: 'Mr',
	first_name: "",
	middle_name : "",
	last_name: "",
	company_name: "",
	email: "",
	phone_one: "",
	phone_two: "",
	tax_preference: 'Taxable',
	tax_rate: '14.975%',
	currency: 'CAD',
	payment_terms: 'Paid on receiption',
	display_name : '',
	portal_language: 'EN',
	attendance : '',
	unit_number: '',
	door_number : '',
	licence: empty_customer_license,
	street : '',
	city : '',
	state : 'QC',
	zip : '',
	notes: "",
	complete : true,
	welcome_email : true,
	contact_array: [],
	disabled : false,
	timestamp: 0
};
const empty_contact : i_contact = {
	first_name: "",
	last_name: "",
	email: "",
	phone: "",
	mobile_phone: "",
	designation: "",
	unit_number : "",
	door_number : "",
	street : "",
	city : "",
	state : "",
	zip : ""
}
const empty_customer_errors : i_customer_errors = {
	_id: "",
	customer_type: "",
	title: '',
	first_name: "",
	middle_name : "",
	last_name: "",
	company_name: "",
	email: "",
	phone_one: "",
	phone_two: "",
	tax_preference: '',
	tax_rate: '',
	currency: '',
	payment_terms: '',
	portal_language: '',
	attendance : '',
	unit_number: '',
	door_number : '',
	licence: { path : '', licence_number : '', licence_province : '', reference_number : '', delivery_date : '' , expiration_date : '', birth_date : '' },
	street : '',
	city : '',
	state : '',
	zip : '',
	notes: "",
	contact_array : ""
}
const empty_user: i_user = {
	_id: "",
	email: "",
	password: "",
	confirm_password: "",
	first_name: "",
	last_name: "",
	unit_number: '',
	door_number : '',
	street : '',
	city : '',
	state : 'QC',
	zip : '',
	phone_one: "",
	phone_two: "",
	type: "Admin",
	disabled : true,
	hour_rate: 0,
	skill_code: "Default",
	start : Date.now(),
    end : 0,
    nas : "",
	timestamp : 0
};
const empty_pay : i_complete_pay ={
	hours : 80,
    taxes: '',
    amount: '',
    extra: '',
	biweekly_calc : empty_tax,
	year_calc : empty_tax,
    user: empty_user,
    user_id: '',
    status : '',
	start : Date.now(),
	end : Date.now(),
	rate : '15.75',
    path: [],
    timestamp: Date.now()
}
const pay_errors : i_pay_errors = {
	_id: "",
	hours : '',
    taxes: '',
    amount: '',
    extra: '',
    user_id: '',
    status : '',
}
const empty_user_errors : i_user_errors = {
	_id: "",
	email: "",
	password: "",
	confirm_password: "",
	first_name: "",
	last_name: "",
	unit_number: '',
	door_number : '',
	street : '',
	city : '',
	state : '',
	zip : '',
	phone_one: "",
	phone_two: "",
	type: "",
	disabled : "",
	hour_rate: "",
	skill_code: "",
	start : "",
    end : "",
    nas : "",
	timestamp : ""
};
const empty_search_job : i_jobs_search = {
	vin : '',
	license_plate : '',
	year : '',
	make : '',
	model : '',
	job_id : '',
	payment_order : '',
	date_from : 0,
	date_to : 0,
	order : 'DESC'
}

const empty_task : i_task ={
	_id : "",
	description : "",
	title : "",
	path : [""],
	initial_path : [""],
	user_id : "",
	job_id : "",
	time_estimated : 0,
	start : Date.now(),
	end : Date.now(),
	note : '',
	timesheet : [{ in : 0, out : 0 }],
	type : 'Other',
	status : 'Not started',
	index : 0
}

const empty_promise : i_promise = {
	data : [],
	message : "",
	type : "Failed"
}
const default_org : i_app_config = {
	name : '',
	phone : '',
	email : '',
	url : '',
	phone_two : '',
	street : '',
	city : '',
	state : '',
	zip : '',
	country : '',
	door_number : '',
	unit_number : '',
	path : '',
	id : '',
	_id : '',
	activity : '',
	primary_color : '#B71F26',
    secondary_color : '#5555',
    selected_color : '#B71F26',
    hover_color : '#444',
	timestamp : 0,

}
const org_errors : i_app_config_errors = {
	name : '',
	phone : '',
	email : '',
	url : '',
	phone_two : '',
	street : '',
	city : '',
	state : '',
	zip : '',
	country : '',
	door_number : '',
	unit_number : '',
	path : '',
	id : '',
	_id : '',
	activity : '',
	primary_color : '',
    secondary_color : '',
    selected_color : '',
    hover_color : '',
	timestamp : '',
}
const empty_customer_car : i_customer_car = {
	_id : '',
	make : "Tesla",
	model : "",
	year : 2017,
	trim : '',
	color : '',
	color_code : '',
	color_code_two : '',
	kilo : '',
	valid :false,
	disabled : false,
	insured : false,
	registration : [],
	vin: '',
	insurance : [],
	customer_id : '',
	timestamp : Date.now()
}
const customer_car_errors : i_customer_car_errors = {
	make : '',
    model : '',
    year : '',
    trim : '',
    color : '',
    color_code : '',
    color_code_two : '',
    kilo : '',
    vin : '',
}
const empty_job : i_complete_job = {
	title : '',
	claim_number : '',
	order_number : '',
	description : '',
	status : '',
	sub_status : '',
	path : [],
	_id : '',
	customer : empty_customer,
	car : empty_customer_car,
	user : empty_user,
	task_count : 0,
	task_done : 0,
	timestamp : 0
}
const empty_job_errors : i_job_errors = {
	title : '',
	claim_number : '',
	order_number : '',
	description : '',
	status : '',
	sub_status : '',
	_id : '',
	customer_id : '',
	car_id :'',
	user_id :'',
}
const empty_complete_task : i_task_complete = {
	_id : '',
    job : empty_job,
    title : '',
    description : '',
    user : empty_user,
    time_estimated : 0,
    start : Date.now(),
    end : Date.now(),
    status : 'Not started',
    type : 'Other',
    initial_path : [],
    path : [],
    timesheet : [{ in : 0, out : 0 }],
    note : '',
    index : 0
}
const empty_task_errors : i_task_errors = {
	_id : '',
    job_id : "",
    title : '',
    description : '',
    user_id : "",
    time_estimated : "",
    start : "",
    end : "",
    status : "",
    type : "",
    initial_path : "",
    path : "",
    note : "",
    index : ""
}
const empty_car_registration : i_registration = {
	position : 1,
	no_cert : "",
	expires : Date.now(),
	delivered : Date.now(),
	registration_number : '',
	path : []
}
const registration_errors : i_registration_errors = {
	position : '',
    no_cert : '',
    expires : '',
    delivered : '',
    registration_number : '',
    path : ''
}
const empty_car_insurance : i_insurance = {
	position:1,
	path: [],
	new_value_code : '',
	delivered: Date.now(),
	expires: Date.now(),
	no_police: '',
	company_name: '',
	courtier : '',
	new_value : false,
	timestamp: Date.now()
}
const insurance_errors : i_insurance_errors = {
	position:'',
    delivered: '',
    expires: '',
    no_police: '',
    company_name: '',
    courtier : '',
	new_value_code : ''
}
const empty_car : i_car = {
	_id : '',
	make : '',
	model : '',
	year : 0,
	path : '',
	option : '',
	timestamp : 0,
	type : 'Van'
}
const default_filter : i_search_filter = {
	page : 1,
	rows_per_page : 10,
	search : '',
	sort_by : 'timestamp',
	sort_order : 'DESC',
}
const default_customer_filter : i_customer_search_filter = { ...default_filter, type : 'Individual' }
const default_job_filter : i_job_search_filter = { ...default_filter, status : 'Any', team_member : 'Any', }


const empty_order : i_order = {
	_id : '',
	car_id : '',
	customer_id : '',
	user_id : '',
	assets : [],
	title : '',
	description : '',
	number : '1',
	timestamp : 0
}
const default_car_selector : i_car_selection_options = {
	years : [],
	makes : [],
	models : [],
	options : [],
	selectedYear : 0,
	selectedMake : "default",
	selectedModel : "default",
	selectedOption : "default"
}
const customers_headers : i_tbl_header[] = [
	{ value : 'Name' , css : { ...header_row, width : '20%' } , portion : 1 },
	{ value : 'Email', css : { ...header_row, width : '20%' } , portion : 1 },
	{ value : 'Phone', css : { ...header_row, width : '15%' } , portion : 1  },
	{ value : 'Customer type', css : { ...header_row, width : '15%' } , portion : 1 },
	{ value : 'Last interaction', css : { ...header_row, width : '10%' }, portion : 1  },
	{ value : 'Type', css :{ ...header_row, width : '5%' } , portion : 1 },
	{ value : 'Action', css : { ...header_row, width : '10%' }, portion : 1 },
]
const cars_headers : i_tbl_header[] = [ 
	{ value : "Year" , css : { ...header_row, width : '15%' } , portion : 1 },
	{ value : "Make" , css : { ...header_row, width : '20%' } , portion : 1 },
	{ value : "Model" , css : { ...header_row, width : '20%' } , portion : 1 },
	{ value : "Color" , css : { ...header_row, width : '15%' } , portion : 1 },
	{ value : "Insured" , css : { ...header_row, width : '10%' } , portion : 1 },
	{ value : 'Action', css : header_row, portion : 1  }
]
const user_headers : i_tbl_header[] = [
	{ value : 'Added date', css : header_row , portion : 1}, 
	{ value : 'Name', css : header_row , portion : 1},
	{ value : 'Email', css : header_row , portion : 1},
	{ value : 'Phone', css : header_row , portion : 1},
	{ value : 'Type', css : header_row , portion : 1},
	{ value : 'Action', css : header_row , portion : 1}
]
const users_type : i_user_type[] = [
	{ value : "Admin", allowed : true },
	{ value : "Liaison Officer" , allowed : true },
	{ value : "Technician", allowed : true },
	{ value : "Accountant", allowed : true },
]
const allowed_extensions = ['jpg', 'jpeg', 'png', 'gif' , 'svg', 'pdf'];
const lexique = {
	postal_code_regex: /^[ABCEGHJKLMNPRSTVXY]\d[A-Z] \d[A-Z]\d$/,
	zip_regex: /^[A-Za-z]\d[A-Za-z] ?\d[A-Za-z]\d$/i,
	Name_regex: /^(?=[a-zA-Z0-9._]{3,20}$)(?!.*[_.]{2})[^_.].*[^_.]$/,
	str_regex: /^[^\\/&[\]{}<>]*$/,
	str_regex_min: /^[^\\/&@.,(){}<>]{5,}$/,
	password_two_regex: /^[a-zA-Z0-9,.:;]{5,}$/,
	password_regex:/^(?=.*\d)(?=.*[a-z])(?=.*[A-Z])(?=.*[^a-zA-Z0-9])(?!.*\s).{8,20}$/,
	email_regex:/^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
	phone_regex: /^\s*(?:\+?(\d{1,3}))?[-. (]*(\d{3})[-. )]*(\d{3})[-. ]*(\d{4})(?: *x(\d+))?\s*$/,
	mobile_phone_regex:/^(?:\s*(?:\+?(\d{1,3}))?[-. (]*(\d{3})[-. )]*(\d{3})[-. ]*(\d{4})(?: *x(\d+))?\s*)?$/,
	alpha_regex: /^[A-Za-z0-9]+$/i,
	name_regex: /^[A-Za-z\s]+$/i,
	address_regex: /^\s*\S+(?:\s+\S+){1,10}/,
	normalText_regex: /^[A-Za-z0-9\s]+$/,
	websiteurl_regex:/^(https?:\/\/)?(www\.)?[a-zA-Z0-9-]+(\.[a-zA-Z]{2,})(\/[^\s]*)?$/,
	cap_number_regex : /^(?=.*[A-Z])(?=.*\d).+/,
	one_special_regex : /^(?=.*[A-Z])(?=.*\d)(?=.*[^A-Za-z\d]).*$/,
	urlRegex : /^(https?|ftp):\/\/[^\s/$.?#].[^\s]*$/i,
	hexColorRegex : /^#([0-9a-fA-F]{3}){1,2}$/,
	nas_regex: /^(\d{3}-\d{3}-\d{3})|(\d{9})$/
};
const lexique_message = {
	empty_error: "Please fill in the field.",
	select_error : "Please select an option.",
	str_error: "Please do not use special characters like []|{}<>",
	zip_error: "Please enter a valid canadian postal code.",
	alpha_error: "Please use only alphanumeric characters.",
	phone_error: "Please provide a valid phone number, e.g., 1-514-111-1111.",
	special_error: "Special characters are not allowed.",
	email_error: "The email must match the email format, e.g., example@example.com.",
	numeric_error: "only accepts numerical values, e.g., 0, 12.",
	password_error: "must be between 8 and 20 characters long, including at least one lowercase letter, one uppercase letter, one digit, and one special character!",
	file_error : "Please provide valid files.",
	match_error : "Passwords do not match.",
	assets_error : "Please provide valid assets.",
	date_error : "Please provide a valid date",
	tax_rates_error: "Please provide a valid tax rates",
	tax_type_error: "Please provide a valid tax type",
	currency_error : "Please provide a valid currency",
	payment_terms_error : "Please provide a valid payment terms",
	portal_language_error : "Please provide a valid portal language",
	customer_type_error : "Please provide a valid customer type",
	customer_title_error : "Please provide a valid customer title",
	string_arr_error : "Please provide a valid string array",
	contact_arr_error : "Please provide a valid contact array",
	url_error : "Please provide a valid url, e.g., http://www.example.com.",
	color_error : "Please provide a valid color, e.g., #0000FF",
	nas_error : "Please provide a valid NAS number, e.g., 000-000-000",
	user_type_error : "Please provide a valid user type",
	skill_code_error : "Please provide a valid skill code",
};
const colors = [{ color : 1, label : 'red' }, { color : 2, label : 'blue' }, { color : 3, label : 'purple' },{ color : 4, label : 'yellow' },{ color : 5, label : 'green' }, { color : 6, label : 'orange' }]
const provinces = [{ value : "QC"} , { value : "MB"} , { value : "ON" } , { value : " BC"} , { value : "NB"},{ value : "AB"} , { value : "LB"}]
const country = [{ value : "Canada" }, { value : "United States" }]
const years = [ { value : "2000" } , { value : "2001" } , { value : "2002" } , { value : "2003" } , { value : "2004" }, { value : "2005" } , { value : "2006" } , { value : "2007" }, { value : "2008" } ,
{ value : "2009" }, { value : "2010" } , { value : "2011" }, { value : "2012" } , { value : "2013" }, { value : "2014" } , { value : "2015" }, { value : "2016" } , { value : "2017" }, { value : "2018" } , { value : "2019" } , 
{ value : "2020" } , { value : "2021" }, { value : "2022" } , { value : "2023" }, { value : "2024" }]

const makes = [ "Toyota","Honda","Ford","Chevrolet","Nissan","BMW","Mercedes-Benz","Audi", "Volkswagen","Hyundai","Kia","Mazda","Subaru","Tesla","Lexus","Volvo","Jeep","Ram","Chrysler","Fiat"]   
const task_types : t_task_type[] = ["Body job","Car entry","Maintenance","Order parts","Other","Paint","Repair"]
const models = [{ value : 'need'} , { value : 'the'} , { value : 'logics' } , { value : '...'}]     
const default_user_config = {
	filter_job : true,
	stats_job : true,
	filter_customer: true,
	stats_customer : true,
	global_task : true,
	stats_task : true,
	stats_payroll : true,
}
const impot_2024 = {
	ae : {
		max_amount : 63200,
		tax_rate : 0.0132,
		lord_rate : 0.01848,
		max_cote : 834.24
	},
	rrq : {
		max_amount : 68500,
        tax_rate : 0.064,
        lord_rate : 0.064,
		max_cote: 4160,
		base_amount : 3500
	},
	rqap : {
		max_amount : 94000,
		tax_rate : 0.00494,
        lord_rate : 0.00692
	},
	qc_taxes : {
		base_amount : 18056,
		first_tax : 0.14,
		second_tax : 0.19,
		third_tax : 0.24,
		fourth_tax : 0.2575,
		first_bracket : 51780,
		second_bracket : 103545,
		third_bracket : 126000,
	},
	fed_taxes : {
		base_amount : 15705,
		first_tax : 0.12525,
        second_tax : 0.171175,
        third_tax : 0.2171,
        fourth_tax : 0.24215,
		fifth_tax : 0.27555,
        first_bracket : 55868,
		second_bracket : 111733,
        third_bracket : 173205,
        fourth_bracket : 246752,
		qc_peraquation : 0.165
	},
	fed_taxes_old : {
		base_amount : 15705,
		first_tax : 0.15,
        second_tax : 0.205,
        third_tax : 0.26,
        fourth_tax : 0.29,
		fifth_tax : 0.33,
        first_bracket : 55868,
		second_bracket : 111733,
        third_bracket : 173205,
        fourth_bracket : 246752,
		qc_peraquation : 0.165
	}
}
const EndDate = new Date()
EndDate.setHours(EndDate.getHours()+1)
//Initial form object
const empty_cal_event : i_cal_event = {
    _id: "",
    title: "",
    description: "",
    start:  Date.now(),
    end:  Date.now(),
    color: 1,
    user_id: "",
	order_number : "",
    timestamp : 0
} 
const cal_event_errors : i_cal_event_errors = {
	_id : "",
    title : "",
    description : "",
    start : "",
    end : "",
    color : "",
    user_id : "",
    order_number : ""
}
const cpa_rates = {
	"2024" : [
		{ label : "Apprentice year one", value : 20.71 } , { label : "Apprentice year two", value : 21.66 }, { label : "Apprentice year three", value : 24.13 },
		{ label : "Journeyman first class", value : 30.91 }, { label : "Journeyman second class", value : 28.46 }, { label : "Journeyman third class", value : 26.93 },
        { label : "Parts clerk level A", value : 26.11},{ label : "Parts clerk level B", value : 24.93 },{ label : "Parts clerk level C", value : 24.47 },{ label : "Parts clerk level D", value : 22.41 },
		{ label : "Commissionaire level A", value : 18.34},{ label : "Commissionaire level B", value : 16.86 },
		{ label : "Dismantler first stage", value : 18.45 },{ label : "Dismantler second stage ", value : 19.39 },{ label : "Dismantler third stage", value : 20.48 },
		{ label : "Washer" , value : 18.34 },
		{ label : "Skilled worker first stage", value : 18.98 },{ label : "Skilled worker second stage", value : 21.59 },{ label : "Skilled worker third stage", value : 22.84 },
		{ label : "Service attendant first stage", value : 18.14 },{ label : "Service attendant second stage", value : 19.71 },{ label : "Service attendant third stage", value : 21.82 },
		{ label : "Suspension and mechanic first class", value : 30.91 },{ label : "Suspension and mechanic second class", value : 28.46 },{ label : "Suspension and mechanic third class", value : 26.93 },
	]
}
const cal_view_selection : t_cal_view[]  = ['month', 'week', 'day', 'agenda']
const task_states_types : t_task_state[] = ["Default" , "Not started" , "Done" , "On time" , "Overtime"]
const job_status = [ { value : "Any"  , label : 'Any'} , { value : "Initial" , label : "Initial" }, { value : "Started" , label : "Started"}, { value : "Done" , label : "Done" } ]
const task_status = [ { value : "Any"  , label : 'Any'} , { value : "Not started" , label : "Not started" }, { value : "Done" , label : "Done"}, { value : "On time" , label : "On time" } , { value : "Overtime" , label : "Overtime" } ]
export { lexique , empty_contact , pay_errors , empty_pay , empty_tax, customer_car_errors, registration_errors, insurance_errors, cal_view_selection, empty_customer_errors, cal_event_errors, empty_cal_event , empty_complete_task, org_errors, empty_task_errors, empty_job_errors, task_states_types, empty_user_errors, default_job_filter, task_types , default_customer_filter , job_status , task_status , default_org, lexique_message , empty_customer , empty_user , default_error , users_type, colors, default_filter, years, cars_headers , empty_customer_car, empty_search_job , empty_job , country, default_user_config,
	default_car_selector , cpa_rates , empty_promise , empty_car , allowed_extensions , empty_order , empty_task , customers_headers , user_headers , provinces , makes , models , empty_car_insurance , empty_car_registration ,impot_2024 }