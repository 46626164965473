

/*
 * Date: 2024
 * Description: Resable back component
 * Author: Philippe Leroux @ skitsc
 */

//Modules
import { IconButton , Grid , Typography , Box } from "@mui/material"
import { ArrowBack } from "@mui/icons-material";

//Interfaces && types
import { i_form_backer } from "../../../interfaces/utility.interface";

const FormBacker = ( props : i_form_backer ) => {
    const event = props.type === 'add' ? 'Add a ' : 'Edit a ';
    return (
            <Grid item xs={12}>
            <Box sx={{ display : 'flex' }}>
                    <IconButton sx={{ marginRight : '4px'}} onClick={() => props.callback()}><ArrowBack color={'primary'} sx={{ marginRight : 'auto'}}/></IconButton>
                    <Typography sx={{ fontWeight : 800 , fontSize : 20 ,  alignSelf : 'center'}}>{ event + props.title } </Typography>
            </Box>
            <Typography sx={{ fontWeight : 800 , fontSize : 16 , marginBottom : '2vh' , color : 'grey'}}>{"Enter your " + props.title +  " informations"}</Typography>
        </Grid>
    )
}

export default FormBacker;