/*
 * Date: 2023-08-24
 * Description: Notification table logics
 * Author: Philippe Leroux @ Skitsc
 */

import { ReactElement } from "react"

//Modules
import { Box , TableContainer , Paper , Table , TableHead , TableCell , TableRow , TableBody } from "@mui/material";

//Utilities
import { Scrollbar_style } from "../../styles/main.styles";
import { i_notifications } from "../../interfaces/notifications.interface";
import { f_timestamp_to_date } from "../../utils/utility";

//Interfaces

//Components
const NotifTbl = ( props : any) : ReactElement => {
   
    const startIndex = props.page * props.rows_per_page;
    const rowsForCurrentPage = props.data.slice(startIndex, startIndex + props.rows_per_page);
    const handleNotif = ( row : i_notifications) => {
        return ""
    }
    return (
        <Box sx={[{ maxHeight : '80vh' , overflow : 'auto' , border : '1px solid gray'}, Scrollbar_style]}>
        <TableContainer component={Paper}>
            <Table sx={{ minWidth: 500 }} aria-label="logs table">
                <TableHead>
                    <TableRow>                
                        <TableCell style={{ width : '16%'}} align='center'>Title</TableCell>
                        <TableCell style={{ width : '8%'}} align='center'>Staff asignated</TableCell>
                        <TableCell style={{ width : '8%'}} align='center'>Customer</TableCell>
                        <TableCell style={{ width : '16%'}} align='center'>Assets</TableCell>
                        <TableCell style={{ width : '16%'}} align='center'>Action</TableCell>
                    </TableRow>
                </TableHead>
            <TableBody>
                { props.data.length > 0 ? rowsForCurrentPage.map((row : i_notifications) => (
                <TableRow key={row._id}>                    
                    <TableCell style={{ width: 250 }}  align='center' >
                        {row.type}
                    </TableCell>
                    <TableCell style={{ width: 100 }}  align='center'>
                        {row.content}
                    </TableCell>
                    <TableCell align='center'>
                        {row.resolved}
                    </TableCell>                  

                    <TableCell style={{ width: 160 }}  align='center'>
                        {handleNotif(row)}
                    </TableCell>                    
                    <TableCell component="th" align='center'>
                        {f_timestamp_to_date(row.timestamp , 'long')}
                    </TableCell>       

                </TableRow>
                )) : null }
                    
            </TableBody>
            </Table>
        </TableContainer>
    </Box>
    )
}

export default NotifTbl