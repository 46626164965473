/*
 *
 * Date: 2024
 * Description: Socket Context initialization
 * Author: Philippe Leroux @SKITSC
 *
 */

//Modules..
import { createContext, ReactElement } from 'react';
import { io, Socket } from 'socket.io-client';

interface i_socket_provider {
    children : ReactElement
}
const defaultSocketLocation = 'ws://localhost:5000';

const socketLocation = process.env.REACT_APP_API_URL || defaultSocketLocation;
const path = process.env.REACT_APP_API_URL?.includes('https') ? process.env.REACT_APP_WS : undefined;

const socketOptions = {
    path: path ,
    withCredentials: true,
    extraHeaders: {
        "x-token": "abcd"
    }
};

const socket = io(socketLocation, socketOptions);
const SocketContext = createContext<Socket>(socket);
var socket_id = ''
socket.on('connect', () => {
    socket_id = socket.id
});  
socket.on('error',(err) => console.log(err))
const SocketProvider = ({ children } : i_socket_provider) => {
  return (
    <SocketContext.Provider value={socket}>{children}</SocketContext.Provider>
  );
};
export { SocketContext, SocketProvider , socket_id };