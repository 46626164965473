

/*
 * Date: 2024
 * Description: Remaster of the navigation bar for table filtering or add form event
 * Author: Philippe Leroux @ Skitsc
 */


//Modules
import {  ReactElement } from "react";
import { Box , Button , Grid} from "@mui/material"

//Interfaces && types
import { i_top_table_bar_props } from "../../interfaces/utility.interface";
//Icons
import AddIcon from '@mui/icons-material/Add';


const TopTableBar = ( props : i_top_table_bar_props ) : ReactElement => {
    return (
        <Box sx={{ width : '100%' , marginTop : '2vh' ,  }}>
            <Grid container sx={{}}>
                <Grid item xs={12}>
                    <Box sx={{ display : 'flex' , width : '100%'}}>
                        {props.inputs.map((input : ReactElement, index : number) => (
                            <Box key={index}>
                                {input}
                            </Box>
                        ))}
                        <Button onClick={() => props.callback()} startIcon={<AddIcon/>} variant="contained" sx={{ marginLeft : 'auto' , marginRight : '2%' , padding:'4px'}}>Add</Button>
                    </Box>
                </Grid>
            </Grid>
        </Box>
    )
}

export default TopTableBar