/*
 * Date: 2023
 * Description: Address part of the customer form
 * Author: Philippe Leroux @ Skitsc
 */

//Modules
import { ChangeEvent } from 'react';
import { Box , Grid , TextField ,Typography , MenuItem } from '@mui/material' 

//Interfaces && types
import { i_customer_form_props } from "../../../interfaces/customer.interface";

//Styles
import { input_base , sub_title , bottom_line } from '../../../styles/form.styles';

//Constant
import { provinces } from '../../../utils/constant';

const CustomerAddress = ( props : i_customer_form_props ) => {

    const F = props.form
    const E = props.errors
    const handleChange = (e : ChangeEvent<HTMLInputElement>) => {
        const { name, value } = e.target
        props.setForm({...F, [name] : value})
    }
    return (
        <Box>
            <Grid container>
                <Grid item xs={3} >
                    <Typography sx={[ sub_title , { fontSize : 14}]}>Unit number / Civic number* / Street*</Typography>
                </Grid>
                <Grid item xs={9}>
                    <TextField sx={[input_base ,{ width : '120px' } ]} name={'unit_number'} size="small" value={F.unit_number} onChange={handleChange}
                    helperText={E.unit_number} error={E.unit_number !== ''}autoComplete={props.dev ? "off" : "new-password" }/>
                     <TextField sx={[input_base ,{ width : '120px' } ]} name={'door_number'} size="small" value={F.door_number} onChange={handleChange}
                    helperText={E.door_number} error={E.door_number !== ''}autoComplete={props.dev ? "off" : "new-password" }/>
                     <TextField sx={[input_base ,{ width : '300px' } ]} name={'street'} size="small" value={F.street} onChange={handleChange}
                    helperText={E.street} error={E.street !== ''}autoComplete={props.dev ? "off" : "new-password" }/>
                </Grid>
                <Grid item xs={12} sx={{ marginBottom : '1vh'}}> <hr style={bottom_line}/></Grid>
                <Grid item xs={3}>
                    <Typography sx={[ sub_title , { fontSize : 14}]}> City* / Province* / Postal code*</Typography>
                </Grid>
                <Grid item xs={9}>
                    <TextField sx={[input_base ,{ width : '200px' } ]} name={'city'} size="small" value={F.city} onChange={handleChange}
                    helperText={E.city} error={E.city!== ''}autoComplete={props.dev ? "off" : "new-password" }/> 
                    <TextField sx={[input_base ,{ width : '200px' } ]} name={'state'} value={F.state} size="small" onChange={handleChange}
                    helperText={E.state} error={E.state!== ''}autoComplete={props.dev ? "off" : "new-password" } select> 
                      {provinces.map((provinces , index ) => (
                            <MenuItem key={index} value={provinces.value}>{provinces.value}</MenuItem>
                        ))}
                    </TextField>
                    <TextField sx={[input_base ,{ width : '140px' } ]} name={'zip'} size="small" inputProps={{ maxLength: 7 }} value={F.zip} onChange={handleChange}
                    helperText={E.zip} error={E.zip!== ''}autoComplete={props.dev ? "off" : "new-password" }/>
                </Grid>
                <Grid item xs={12} sx={{ marginBottom : '1vh'}}> <hr style={bottom_line}/></Grid>
            </Grid>
        </Box>
      );
}

export default CustomerAddress