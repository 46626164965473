
/* Filename: planner.inputs.tsx
* Date: 2024
* Description: Function that return inputs for events planner 
* Author: Philippe Leroux @SKITSC
*
*/

//Modules
import { ChangeEvent } from 'react';
import { TextField , Box, Typography , MenuItem , Grid, Button } from '@mui/material';
import { LocalizationProvider , DateTimePicker } from '@mui/x-date-pickers';
import dayjs from 'dayjs';
import { Dayjs } from 'dayjs';
import { LoadingButton } from '@mui/lab';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';

//Styles
import { input_base , bottom_line} from '../../styles/form.styles';

//Utility
import { colors } from '../../utils/constant';
import { f_return_color } from '../../utils/utility';

//Interface
import { i_planner_inputs } from '../../interfaces/planner.interface';

//Translations
import { Pi_1 } from './planner.translation';

const PlannerInputs = (props : i_planner_inputs) => {
    //Main object states
    const L = props.lang;
    const E = props.errors
    const F = props.form
    //Handlers
    const handleDate = ( date: Dayjs| null , type : 'start' | 'end' ) => { if(date!== null)  props.setForm({...F, [type] : Math.floor(date.valueOf()) }) }

    const handleChange = ( event : ChangeEvent<HTMLInputElement> ) => {
          const { name, value } = event.target;
          props.setForm({...F, [name] : name === 'color' ? Number(value) : value });
    }
   return(
       <Box component='div'>
        <Grid container sx={{ display : 'flex' , justifyContent : 'flex-start' }}>
            <Grid item xs={4}>
                <Typography>Select a user</Typography>
            </Grid>
            <Grid item xs={8}>
                <TextField sx={[input_base , { width : '300px' }]} name={'user_id'} error={E.user_id !== '' ? true : false} helperText={E.user_id} size={'small'}
                     select  
                    onChange={handleChange} value={F.user_id} >
                    {props.users.map((option : any, i : number) => (
                        <MenuItem key={i} value={option._id}>{option.first_name} {option.last_name}</MenuItem>
                    ))}
                </TextField>
            </Grid>
            <Grid item xs={12} sx={{ marginBottom : '1vh'}}> <hr style={bottom_line}/></Grid>
            <Grid item xs={4}>
                <Typography>Event title</Typography>
           </Grid>
           <Grid item xs={8}>
                <TextField sx={[input_base , { width : '300px' }]} error={E.title !== "" ? true : false} name={'title'} helperText={E.title}value={F.title} size={'small'} onChange={handleChange}/>
           </Grid>
           <Grid item xs={12} sx={{ marginBottom : '1vh'}}> <hr style={bottom_line}/></Grid>
           <Grid item xs={4}>
                <Typography>Service order number</Typography>
           </Grid>
           <Grid item xs={8}>
                <TextField sx={[input_base , { width : '300px' }]} error={E.order_number !== "" ? true : false} helperText={E.order_number} name={'order_number'}  size={'small'} value={F.order_number} onChange={handleChange} />
           </Grid>
           <Grid item xs={12} sx={{ marginBottom : '1vh'}}> <hr style={bottom_line}/></Grid>
           <Grid item xs={4}>
                <Typography>Event description</Typography>
           </Grid>
           <Grid item xs={8}>
                <TextField error={E.description !== '' ? true : false} helperText={E.description} sx={[input_base ,]} name={'description'} fullWidth value={F.description} multiline rows={3}onChange={handleChange}/>
           </Grid>
           <Grid item xs={12} sx={{ marginBottom : '1vh'}}> <hr style={bottom_line}/></Grid>
           <Grid item xs={4}>
                <Typography>Start date / End date</Typography>
           </Grid>
           <Grid item xs={8}>
                <LocalizationProvider dateAdapter={AdapterDayjs}>
                        <DateTimePicker maxDate={dayjs(F.end)} sx={{ paddingRight : '8px'}} maxDateTime={dayjs(F.end)} value={dayjs(F.start)} onChange={(e) => handleDate(e , 'start')}/>
                        <DateTimePicker  value={dayjs(F.end)} onChange={(e) => handleDate(e , 'end')}/>
                </LocalizationProvider>
           </Grid>
           <Grid item xs={6} sx={{ height : '25px'}}>
                {E.start !== "" ? <Typography sx={{ color : 'crimson' }}>{E.start}</Typography> : null}
                {F.start > F.end && <Typography sx={{ color : 'red' }}>Start date must be before end date..</Typography>}
           </Grid>
           <Grid item xs={6} sx={{ height : '25px'}}>
                {E.end !== "" ? <Typography sx={{ color : 'crimson' }}>{E.end}</Typography> : null}
           </Grid>
           <Grid item xs={12} sx={{ marginBottom : '1vh'}}> <hr style={bottom_line}/></Grid>
           <Grid item xs={4}>
                <Typography>What is the importance of this event</Typography>
           </Grid>
           <Grid item xs={8} sx={{ display : 'flex'}}>
                <TextField name="color" error={E.color !== '' ? true : false} size={'small'} helperText={E.color} select sx={[input_base , { width : '200px' }]}
                    onChange={handleChange} value={F.color} >
                    {colors.map((option : any, i : number) => (
                        <MenuItem key={i} value={option.color}>{option.label}</MenuItem>
                    ))}
                </TextField>  
                <Box component={'div'} sx={{ width : '35px', height : '35px' ,  backgroundColor : f_return_color(F.color)}}/>
                {F._id !== '' &&
                    <Button sx={{ display : 'block' , marginRight : 'auto' , marginLeft : 'auto' , fontWeight : 800 , height : '50px'}} variant="contained" color="primary" onClick={() => props.handleDelete()}>
                        {Pi_1['Delete'][L]}
                    </Button>
                }
           </Grid>
         
           <Grid item xs={12} sx={{ marginBottom : '1vh'}}> <hr style={bottom_line}/></Grid>
           <Grid item xs={12} sx={{ display : 'flex' , justifyContent : 'center'}}>
                <Button sx={{  marginRight : '8px' , border : "1px solid gray" , borderRadius : '5px'}} onClick={() => props.handleClose()}>Cancel</Button>
                <LoadingButton loading={props.loading} disabled={props.loading} sx={{ fontWeight : 800}} onClick={() => props.callback()} variant='contained'>Submit</LoadingButton>
           </Grid>
           </Grid>
       </Box>
   )
   
}
export default PlannerInputs