/*
 * Date: 2023
 * Description: logout page
 * Author: Philippe Leroux @ skitsc
 */

//Modules
import { useNavigate } from "react-router-dom";
import { useEffect , ReactElement , useState , useContext } from "react";
import { MainContext } from "../context/context";
import { Box } from "@mui/material";

//Utility
import { delay } from "../utils/utility";
import { f_logout } from "../api/fetch";

//Components
import CircularUnderLoad from "../components/utility/center.loader";

//Interfaces && Types

import { i_initial_props } from "../interfaces/utility.interface";
import { SocketContext } from "../context/socket.context";

const Logout = ( props : i_initial_props) : ReactElement => {

    const Nav = useNavigate();
    const socket = useContext(SocketContext)
    const { HandleLogout , user } = useContext(MainContext)

    const [ loading , setLoading ] = useState<boolean>(true)
    useEffect(() => { 
        setLoading(true)
        const f_handle_logout = async() => { 
            socket.emit('logout' , { socket_id : socket.id  , user_id : user._id , roomId : user.type })
            await delay(1000)
            await f_logout()
            HandleLogout(Nav)
        }
        f_handle_logout()
    },[HandleLogout , Nav , socket , user ])
    return (
        <Box component="div">
          { loading &&< CircularUnderLoad type={"full"}/> }
        </Box>
    )
}
export default Logout