

const box_sizing = {
    border : '1px solid #D3D3D3',
    padding : '10px',
    minHeight : '14vh',
    marginTop : '0%',
    marginLeft : '5%',
    marginRight : '5%',
    borderRadius : '10px',
    width : '360px'
}
const double_box = {
    padding : '10px',
    minHeight : '14vh',
    margin : '0%',
    width: '350px',
    marginTop : '0%',
    borderTop : '1px solid #D3D3D3',
    borderBottom : '1px solid #D3D3D3',
}
const chart_proportion = {
    width : '150px',
    marginTop : '1.5vh'
}
const star_style = {
    height : '20px',
    alignSelf : 'center'
}
const acc = {
    height : '35px'
}

export  { box_sizing, double_box, chart_proportion, star_style , acc }