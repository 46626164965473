
/*
 * Date: 2024
 * Description: Jobs rows
 * Author: Philippe Leroux @ Skitsc
 */

//Modules
import { Grid , Box , Typography, TextField , MenuItem } from "@mui/material"
import { useEffect, useState } from "react";
import CircularProgress, { CircularProgressProps, } from '@mui/material/CircularProgress';


//Interfaces
import { i_complete_job } from "../../../interfaces/jobs.interface";
//Utils
import { f_timestamp_to_date } from "../../../utils/utility";
import { f_fetch } from "../../../api/fetch";
import { i_table_v2_row_props } from "../../../interfaces/utility.interface";

//Icons


const JobsRows = ( props : i_table_v2_row_props ) => {
    const row = props.row
    const [ status , setStatus ] = useState<string>(row.status)
    const handleStatus = ( value : any ) => {
        if(value === 'done' || value === 'started' || value === 'initial')  {
            updateStatus(value)
        }
    }
    const updateStatus = async( value : string) => {
        const body = { status : value }
        const res = await f_fetch('/job/status/' + row._id , 'PATCH' , true , body)
        if(res.type === 'Success'){
        }else{
            props.setApiError({open : true, promise : res})
        }
    }
    const order_sub_title = {
        fontSize : 15,
        color : 'gray',
        paddingLeft : '6px'
    }
    const mini_icon = {
        cursor : 'pointer',
        paddingRight : '8px'
    }
    useEffect(() => {
        if(row.status !== status) setStatus(row.status)
    },[row , status])
    
    const CircularProgressWithLabel = ( props: CircularProgressProps & { value: number }) => {
        return (
          <Box sx={{ position: 'relative', display: 'inline-flex' , alignSelf : 'center' , paddingTop:'6px' }}>
                <CircularProgress variant="determinate" {...props} size={50} thickness={6} sx={{ color : '#17B26A'}}/>
                <Box sx={{top: 6,left: 0,bottom: 0,right: 0,position: 'absolute',display: 'flex',alignItems: 'center',justifyContent: 'center',}}>
                    <Typography variant="caption" component="div" color="text.secondary" >{`${Math.round(props.value)}%`}</Typography>
                </Box>
          </Box>
        );
    }
    const renderCircular = () => {
      return(
        <Box>
            {row.task_count > 0 ? <CircularProgressWithLabel value={row.task_done / row.task_count * 100} /> : <Typography>N/A</Typography> }
        </Box>
      )
    }
    const formatItemRow = ( row : i_complete_job ) => {
        return(
            <>
                <Typography sx={order_sub_title}>{row.customer.first_name + ' ' + row.customer.middle_name + ' ' + row.customer.last_name};</Typography>
                <Typography sx={order_sub_title}>{row.car.make};</Typography>
                <Typography sx={order_sub_title}>{row.car.model};</Typography>
                <Typography sx={order_sub_title}>{row.car.year};</Typography>
                <Typography sx={order_sub_title}>{row.car.registration[0] !== undefined ? row.car.registration[0].registration_number : 'No plate'};</Typography>
                <Typography sx={order_sub_title}>{row.car.color};</Typography>
                <Typography sx={order_sub_title}>{row.car.vin}</Typography>
            </>
        )
    }

    return (
            <Box sx={{ borderLeft : '1px solid #D3D3D3' , borderRight : '1px solid #D3D3D3' , borderBottom : '1px solid #D3D3D3'}}>
                <Grid container sx={{ padding : '6px'}}>
                    <Grid item xs={props.headers[0].portion}>
                        <Box sx={{display : 'flex'}}>
                            <Typography sx={{ paddingLeft : '6px' , paddingRight : '10px' , fontWeight : 600}}>No order : {row.order_number}</Typography>
                            <Box sx={mini_icon} component={'img'} alt="Sort order img" src={'./images/check-done-01.svg'}/>
                            <Box sx={mini_icon} component={'img'} alt="Sort order img" src={'./images/file-plus-02.svg'}/>
                            <Box sx={mini_icon} component={'img'} alt="Sort order img" src={'./images/gallery.svg'}/>
                            <Box sx={mini_icon} component={'img'} alt="Sort order img" src={'./images/clipboard-check.svg'}/>
                            <Box sx={mini_icon} component={'img'} alt="Sort order img" src={'./images/file-check-02.svg'}/>
                            <Box sx={mini_icon} component={'img'} alt="Sort order img" src={'./images/credit-card-check.svg'}/>
                        </Box>
                        <Box sx={{ display : 'flex' , marginTop : '0.4vh'}}>
                            {formatItemRow(row)}
                        </Box>
                        
                    </Grid>
                    <Grid item xs={props.headers[1].portion} sx={{ alignSelf : 'center'}}>
                        <Typography sx={{ alignSelf : 'center' , fontSize : 14}}>{f_timestamp_to_date(row.timestamp , 'long')}</Typography>
                    </Grid>
                    <Grid item xs={props.headers[2].portion} sx={{ alignSelf : 'center' , display : 'flex'}}>
                        <TextField size="small" sx={{ padding :'2px'}} value={status} onChange={(e) => handleStatus(e.target.value)} select>
                            <MenuItem value="initial">
                                Initial
                            </MenuItem>
                            <MenuItem value="started">
                                Started
                            </MenuItem>
                            <MenuItem value="done">
                                Done
                            </MenuItem>
                        </TextField>
                        {/* <Box sx={{ padding : '2px' , backgroundColor : '#17B26A' , borderRadius : '8px' , fontSize : 14 , color : 'white'}}>initial</Box> */}
                    </Grid>
                    <Grid item xs={props.headers[3].portion} sx={{ alignSelf : 'center'}}>
                        {renderCircular()}
                    </Grid>
                    <Grid item xs={props.headers[4].portion} sx={{ alignSelf : 'center'}}>
                        <Typography sx={{ fontSize : 14 }}>{row.user.first_name + " " + row.user.last_name}</Typography>
                    </Grid>
                    <Grid item xs={props.headers[5].portion} sx={{ display : 'flex' , alignSelf : 'center'}}>
                        <Box sx={{ height : '25px', marginRight : '8%', cursor : 'pointer'}} onClick={() => props.callback( row ,'task')} component={'img'} alt="Sort order img" src={'./images/eye.svg'}/>
                        <Box sx={{ height : '25px' , marginRight : '8%', cursor : 'pointer'}} onClick={() => props.callback( row ,'download')} component={'img'} alt="Sort order img" src={'./images/download-icon.svg'}/>
                    </Grid>
                </Grid>
            </Box>
    )
}

export default JobsRows