
/*
 * Date: 2023-08-28
 * Description: Styles re-used in customer table
 * Author: Philippe Leroux @ skitsc
 */

const margin = { margin : '20px'}
const full_width = { width : '100%'}
const margin_bottom = { marginBottom : '20px'}
const address_title = { fontWeight : 'bold' , fontSize : 22 , marginBottom : '1vh' }
const stack = { flexDirection : 'row' , alignItems : 'center'}
const border = { border : '1px solid gray'}
const cat_title = { alignSelf : 'center' , fontSize : '16px' , width : '50%'}
const item_box = { width : '45%' , display: 'flex' , justifyContent : 'center' , height : '60px'}
const main_box = {display : 'flex' , justifyContent : 'space-evenly' , width : '100%' }
const paper_box = { marginBottom : '2vh' , padding : '2em'}
const helper_row = { marginLeft : '20%' , width : '28%' }

export { margin , helper_row , full_width , margin_bottom , address_title , stack  , border , cat_title , item_box , main_box , paper_box }