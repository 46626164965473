/*
 * Date: 2024
 * Description: Registration inputs
 * Author: Philippe Leroux @ skitsc
 */

//Modules
import { Box , Grid , Button , Typography , TextField } from "@mui/material"
import { LoadingButton } from "@mui/lab"
import { ChangeEvent } from "react"
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import dayjs, { Dayjs } from "dayjs"
//Components
import UploadsInputs from "../../utility/upload.box";
//Styles
import { bottom_line, sub_title , input_base } from "../../../styles/form.styles"
import { i_customer_car_registration_props } from "../../../interfaces/customer.interface";

  
const RegInputs = ( props : i_customer_car_registration_props ) => {
    const F = props.form
    const E = props.errors
    const handleDate = (date : Dayjs | null , type : 'expires' | 'delivered') => {
        if(date !== null)  props.setForm({...F , [type] : date.valueOf()})
    }
    const handleChange = (e : ChangeEvent<HTMLInputElement>) => {
        const { name, value } = e.target
        props.setForm({...F, [name] : value})
    }
    return (
        <Box component={'form'}>
        <Grid container>
            <Grid item xs={12} sx={{ marginBottom : '1vh'}}> <hr style={bottom_line}/></Grid>
            <Grid item xs={4}>
                <Typography sx={sub_title}> Registration Number*</Typography>
            </Grid>
            <Grid item xs={8}>
                <TextField sx={[input_base , { width : '200px'} ]} name={"registration_number"} size="small" placeholder={"reg number.."} value={F.registration_number} error={E.registration_number !== ''} helperText={E.registration_number}
                onChange={handleChange} autoComplete={props.dev ? "off" : "new-password" }/>
            </Grid>
            <Grid item xs={12} sx={{ marginBottom : '1vh'}}> <hr style={bottom_line}/></Grid>
            <Grid item xs={4}>
                <Typography sx={sub_title}>No certificate</Typography>
            </Grid>
            <Grid item xs={8}>              
               
                <TextField sx={[input_base , { width : '200px'} ]} name={"no_cert"} size="small" placeholder={"Cert number.."} value={F.no_cert} error={E.no_cert !== ""} helperText={E.no_cert}
                onChange={handleChange} autoComplete={props.dev ? "off" : "new-password" }/>      
            </Grid>
            <Grid item xs={12} sx={{ marginBottom : '1vh'}}> <hr style={bottom_line}/></Grid>
            <Grid item xs={4}>
                <Typography sx={sub_title}>Period from to *</Typography>
            </Grid>
            <Grid item xs={8}>
                <LocalizationProvider dateAdapter={AdapterDayjs}>
                    <DatePicker sx={[input_base , { width : '200px'}]} label="Delivery date"  value={dayjs(F.delivered)} onChange={(newValue : Dayjs | null) => handleDate(newValue , 'delivered')}/>
                    <DatePicker sx={[input_base , { width : '200px'}]} label="Expiration date" value={dayjs(F.expires)} onChange={(newValue : Dayjs | null) => handleDate(newValue , 'expires')}/>
                </LocalizationProvider>
            </Grid>
            <UploadsInputs {...props.uploads} />
            <Grid item xs={3}></Grid>
            <Grid item xs={6} sx={{ marginTop : '1vh' ,display : 'flex' , justifyContent : 'center'}}>
                <Button onClick={() => props.return()} sx={{ marginRight : '1vh' , backgroundColor : 'white' , color : 'black' , border : '1px solid gray'}}>Cancel</Button>
                <LoadingButton onClick={() => props.callback()} loading={props.loading} variant="contained" color="primary" sx={{fontWeight : 600}}>Submit</LoadingButton>
            </Grid>
                <Grid item xs={3}></Grid>
            </Grid>
    </Box>
    )
}

export default RegInputs