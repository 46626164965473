/*
 * Date: 2024
 * Description: Insurance view
 * Author: Philippe Leroux @ skitsc
 */

//Modules
import { Box , Grid , Typography } from "@mui/material"

//Styles
import { bottom_line, sub_title } from "../../../styles/form.styles"
import { f_timestamp_to_date } from "../../../utils/utility";
import { i_insurance } from "../../../interfaces/customer.interface";

interface i_insurance_view_props {
    insurance : i_insurance
}
const InsurancesView = ( { insurance } : i_insurance_view_props) => {
    const path = process.env.REACT_APP_API_URL + '/webapi/uploads/insurance/'
    return (
        <Box>
            <Grid container>
                <Grid item xs={4}>
                    <Typography sx={sub_title}>Company name*</Typography>
                </Grid>
                <Grid item xs={8}>
                    <Box sx={{}}>{insurance.company_name}</Box>
                </Grid>
                <Grid item xs={12} sx={{ marginBottom : '1vh'}}> <hr style={bottom_line}/></Grid>
                <Grid item xs={4}>
                    <Typography sx={sub_title}>No Police*</Typography>
                </Grid>
                <Grid item xs={8}>              
                <Box sx={{}}>{insurance.no_police}</Box>
                </Grid>
                <Grid item xs={12} sx={{ marginBottom : '1vh'}}> <hr style={bottom_line}/></Grid>
                <Grid item xs={4}>
                    <Typography sx={sub_title}>Period from to *</Typography>
                </Grid>
                <Grid item xs={8}>
                <Box sx={{}}>{f_timestamp_to_date(insurance.delivered , 'long')}</Box>
                <Box sx={{}}>{f_timestamp_to_date(insurance.expires , 'long')}</Box>
                </Grid>
                <Grid item xs={12} sx={{ marginBottom : '1vh'}}> <hr style={bottom_line}/></Grid>
                <Grid item xs={4}>
                    <Typography sx={sub_title}>Courrier name</Typography>
                </Grid>
                <Grid item xs={8}>              
                    <Box sx={{}}>{insurance.courtier !== '' ? insurance.courtier : 'No courtier mention'}</Box>
                </Grid>
                <Grid item xs={12} sx={{ marginBottom : '1vh'}}> <hr style={bottom_line}/></Grid>
                <Grid item xs={4}>
                    <Typography sx={sub_title}>New value</Typography>
                </Grid>
                <Grid item xs={8} sx={{ minHeight : '60px'}}>
                    <Box sx={{}}>{insurance.new_value ? 'Yes' : 'No'}</Box>
                    {insurance.new_value && <Box sx={{}}>{insurance.new_value_code}</Box>}
                </Grid>
                <Grid item xs={12} sx={{ marginBottom : '1vh'}}> <hr style={bottom_line}/></Grid>
                <Grid item xs={4}>
                    <Typography sx={sub_title}>Insurance proofs</Typography>
                </Grid>
                <Grid item xs={8}> 
                    {insurance.path !== undefined && insurance.path.length > 0 ? 
                        <Box>
                            {insurance.path.map((item : string, index : number) => (
                                <Box key={index}>
                                    <Box component={'img'} alt={'insurance img '}  sx={{ maxWidth: 200, maxHeight: 100, display: 'block', width: 'auto', height: 'auto', }} src={ path + item }/> 
                                </Box>
                            ))}
                        </Box>     
                        :
                        <Box>No assets found..</Box>
                    }
                </Grid>
            </Grid>
        </Box>
    )
}

export default InsurancesView