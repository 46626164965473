/*
 * Date: 2023
 * Description: Fragmented customer form , profile part
 * Author: Philippe Leroux @ Skitsc
 */


//Modules
import { ChangeEvent } from "react";
import { Box , TextField , Grid , Typography } from "@mui/material";

//Interfaces && types
import { i_customer_form_props } from "../../../interfaces/customer.interface";

//Styles
import { input_base } from "../../../styles/main.styles";





const CustomerProfile = (props : i_customer_form_props) => {
    const E = props.errors;
    const F = props.form;
    const handleChange = (e : ChangeEvent<HTMLInputElement>) => {
        const { name, value } = e.target
        props.setForm({...F, [name] : value})
    }
    return (
        <Box>
            <Grid container>
                <Grid item xs={3}>
                    <Typography>Name*</Typography>
                </Grid>
                <Grid item xs={9}>
                    <TextField sx={[input_base , { width : '300px'}]} name={'first_name'} value={F.first_name} error={E.first_name !== ""} helperText={E.first_name}  autoComplete={props.dev ? "off" : "new-password" } onChange={handleChange}/>
                    <TextField sx={[input_base , { width : '300px'}]} name={'last_name'} value={F.last_name} error={E.last_name !== ''} helperText={E.last_name} onChange={handleChange} autoComplete={props.dev ? "off" : "new-password" }/>
                </Grid>
                <Grid item xs={3}>
                    <Typography>Email*</Typography>
                </Grid>
                <Grid item xs={9}>
                    <TextField type={'email'} sx={[input_base , { width : '300px'}]} name={'email'} value={F.email} error={E.email!== ''} helperText={E.email} onChange={handleChange} autoComplete={props.dev ? "off" : "new-password" }/>
                </Grid>
                <Grid item xs={3}>
                    <Typography>Phone number*</Typography>
                </Grid>
                <Grid item xs={9}>
                    <TextField sx={[input_base , { width : '300px'}]} value={F.phone_one} name={'phone_one'} onChange={handleChange} error={E.phone_one !== ''} helperText={E.phone_one} autoComplete={props.dev ? "off" : "new-password" }/>
                </Grid>
                <Grid item xs={3}>
                    <Typography>Additionnal phone*</Typography>
                </Grid>
                <Grid item xs={9}>
                    <TextField sx={[input_base , { width : '300px'}]} value={F.phone_two} name={'phone_two'} onChange={handleChange} error={E.phone_two !== ''} helperText={E.phone_two}autoComplete={props.dev ? "off" : "new-password" }/>   
                </Grid>       
            </Grid>
        </Box>
    )

}

export default CustomerProfile