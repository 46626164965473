
/*
 * Date: 2023
 * Description: Jobs super filter
 * Author: Philippe Leroux @ Skitsc
 */

//Modules
import { useContext, useState } from "react"
import { Box , Grid , Typography , TextField , MenuItem , Button , Accordion , AccordionDetails , AccordionSummary } from "@mui/material"
import dayjs , { Dayjs } from "dayjs"
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
//Constants
import { makes, models, years } from "../../../utils/constant"
//Context
import { MainContext } from "../../../context/context";
//Icons
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { i_jobs_mid_filter_props } from "../../../interfaces/jobs.interface";

const JobsMidFilter = ( props : i_jobs_mid_filter_props ) => {
    const { config } = useContext(MainContext)
    const button_search = { backgroundColor : config.primary_color !== '' ? config.primary_color : '#B71F26' , padding : '8px', paddingRight : '12px' , paddingLeft : '12px', borderRadius : '4px' , cursor : 'pointer'}
    const input_label = { color : 'gray'}
    // Form states
    const [ vin , setVin ] = useState('')
    const [ license_plate, setLicensePlate ] = useState('')
    const [ year, setYear ] = useState('2023')
    const [ make, setMake ] = useState('Tesla')
    const [ model, setModel ] = useState('need')
    const [ job_id, setJobId ] = useState('')
    const [ payment_order , setPaymentOrder ] = useState('')
    const [ date_from , setDateFrom ] = useState<Dayjs>(dayjs(new Date()))
    const [ date_to, setDateTo ] = useState<Dayjs>(dayjs(new Date()))


    const handleDate = (date : Dayjs | null , type : 'to' | 'from' ) => {
        if(date !== null){
            if(type === 'to')  setDateFrom(date)
            if(type === 'from')  setDateTo(date)
        }
    }
    return (
        <Accordion expanded={props.acc_open} onChange={() => props.handleAcc('filter')} >
            <AccordionSummary sx={{ borderColor : '#D3D3D3'}} expandIcon={<ExpandMoreIcon />} aria-controls="panel-job-search" id="panel-job-search">
                <Typography sx={{ fontWeight : 600}}>Search by</Typography>
            </AccordionSummary>
            <AccordionDetails>
                <Grid container sx={{ border : '1px solid #D3D3D3' , padding : '10px' , borderRadius : '8px'}}>
                    <Grid item xs={1}>

                    </Grid>
                    <Grid item xs={4}>
                        <Typography sx={input_label}>VIN</Typography>
                        <Box sx={{ display : 'flex' , width : '90%'}}>
                            <TextField fullWidth value={vin} onChange={(e) => setVin(e.target.value)} placeholder="vin" size={'small'} sx={{ marginRight : '5px' }} />
                            <Box onClick={() => props.callback(vin,'vin')} component={'img'} sx={button_search} alt={'search icon'} src={'./images/search.svg'}/>
                        </Box>
                    </Grid>
                    <Grid item xs={2}>
                        <Typography sx={input_label}>License plate</Typography>
                        <Box sx={{ display : 'flex'  , width : '90%'}}>
                            <TextField value={license_plate} onChange={(e) => setLicensePlate(e.target.value)} fullWidth placeholder="license plate" size={'small'} sx={{ marginRight : '5px'}} />
                            <Box onClick={() => props.callback(license_plate,'license_plate')} component={'img'} sx={button_search} alt={'search icon'} src={'./images/search.svg'}/>
                        </Box>
                    </Grid>
                    <Grid item xs={5}>
                        <Typography sx={input_label}>Car details</Typography>
                        <Box sx={{ display : 'flex'  , width : '90%'}}>
                            <TextField select value={year} onChange={(e) => setYear(e.target.value)}size={'small'} sx={{ marginRight : '5px' , width : '33%'}} >
                                { years.map((option , index ) => (
                                        <MenuItem key={index} value={option.value}>
                                            {option.value}
                                        </MenuItem>
                                ))}
                            </TextField>
                            <TextField select value={make} onChange={(e) => setMake(e.target.value)} size={'small'} sx={{ marginRight : '5px' , width : '33%'}} >
                                { makes.map((option , index ) => (
                                        <MenuItem key={index} value={option}>
                                            {option}
                                        </MenuItem>
                                ))}
                            </TextField>
                            <TextField select value={model} onChange={(e) => setModel(e.target.value)} size={'small'} sx={{ marginRight : '5px' , width : '33%'}} >
                                { models.map((option , index ) => (
                                        <MenuItem key={index} value={option.value}>
                                            {option.value}
                                        </MenuItem>
                                ))}
                            </TextField>
                            <Box component={'img'} sx={button_search} alt={'search icon'} src={'./images/search.svg'}/>
                        </Box>
                    </Grid>
                    <Grid item xs={1} >

                    </Grid>
                    <Grid item xs={1.5} mt={1}>
                        <Typography sx={input_label}>Job ID</Typography>
                        <Box sx={{ display : 'flex'  , width : '90%'}}>
                            <TextField fullWidth placeholder="Job id" value={job_id} onChange={(e) => setJobId(e.target.value)} size={'small'} sx={{ marginRight : '5px'}} />
                            <Box component={'img'} sx={button_search} alt={'search icon'} src={'./images/search.svg'}/>
                        </Box>
                    </Grid>
                    <Grid item xs={2} mt={1}>
                        <Typography sx={input_label}>Payment order</Typography>
                        <Box sx={{ display : 'flex'  , width : '90%'}}>
                            <TextField fullWidth placeholder="payment order" value={payment_order} onChange={(e) => setPaymentOrder(e.target.value)} size={'small'} sx={{ marginRight : '5px'}} />
                            <Box component={'img'} sx={button_search} alt={'search icon'} src={'./images/search.svg'}/>
                        </Box>
                    </Grid>
                    <Grid item xs={1.5} mt={1}>
                        <Typography sx={input_label}>Date to</Typography>
                        <Box sx={{ display : 'flex'  , width : '90%' }}>
                            <LocalizationProvider dateAdapter={AdapterDayjs}>
                                <DatePicker slotProps={{ textField: { size: 'small' } }}  value={date_from} onChange={(newValue : Dayjs | null) => handleDate(newValue , 'to')}/>
                            </LocalizationProvider>
                            <Box component={'img'} sx={button_search} alt={'search icon'} src={'./images/search.svg'}/>
                        </Box>
                    </Grid>
                    <Grid item xs={1.5} mt={1}>
                        <Typography sx={input_label}>Date from</Typography>
                        <Box sx={{ display : 'flex'  , width : '90%' }}>
                            <LocalizationProvider dateAdapter={AdapterDayjs}>
                                <DatePicker slotProps={{ textField: { size: 'small' } }}  value={date_to} onChange={(newValue : Dayjs | null) => handleDate(newValue , 'from')}/>
                            </LocalizationProvider>
                            <Box component={'img'} sx={button_search} alt={'search icon'} src={'./images/search.svg'}/>
                        </Box>
                    </Grid>
                    <Grid item xs={4} mt={1}>
                        <Box sx={{ marginTop : '1.9vh' , display : 'flex' , justifyContent : 'flex-end'}}>
                            <Button variant="contained" >Reset filters</Button>
                        </Box>
                    </Grid>
                    <Grid item xs={0.5} >

                    </Grid>
                </Grid>
            </AccordionDetails>
        </Accordion>
    )
}

export default JobsMidFilter