/*
 * Date: 2024
 * Description: Car inputs only for the main part of car ( no insurance or registration involved )
 * Author: Philippe Leroux @ skitsc
 */

//Modules
import { LoadingButton } from "@mui/lab"
import { ChangeEvent } from "react"
import { Box , Grid , Button , TextField, Typography , MenuItem, Switch } from "@mui/material"

//Styles
import { bottom_line, sub_title , input_base } from "../../styles/form.styles"

//Constant
import { makes, years } from "../../utils/constant"
//Interfaces && types
import { i_customer_car_form_props } from "../../interfaces/customer.interface"

const CarInputs = ( props : i_customer_car_form_props ) => {
    const F = props.form
    const E = props.errors
    const handleChange = (e : ChangeEvent<HTMLInputElement>) => {
        const { name, value } = e.target
        props.setForm({...F, [name] : value})
    }
    const HandleSwitch = () => {
        props.setForm({...F , insured : F.insured ? false : true})
    }
    return (
        <Box component={'form'}>
            <Grid container>
                <Grid item xs={12}>
                    <Box>
                        <Typography sx={{ fontSize : 20 , fontWeight : 800}}>{F._id !== "" ? "Update this car" : "Add a new car"}</Typography>
                        <Typography sx={{ fontSize : 18 , fontWeight : 600 , color : 'gray'}}>some text here</Typography>
                    </Box>
                </Grid>
                <Grid item xs={12} sx={{ marginBottom : '1vh'}}> <hr style={bottom_line}/></Grid>
                <Grid item xs={4}>
                    <Typography sx={sub_title}> Year* / Make* </Typography>
                </Grid>
                <Grid item xs={8}>
                    <Box sx={{ display : 'flex'}}>
                        <TextField sx={[input_base , { width : '200px'} ]} name={'year'} size="small" placeholder={"Year"} value={F.year} error={E.year !== ""} helperText={E.year} select autoComplete={props.dev ? "off" : "new-password" }
                        onChange={handleChange}>
                            { years.map(( year , index ) => (
                                <MenuItem key={index} value={year.value}>{year.value}</MenuItem>
                            ))}
                        </TextField> 

                        <TextField sx={[input_base , { width : '200px'} ]} size="small" name={'make'} placeholder={"Make"} value={F.make} autoComplete={props.dev ? "off" : "new-password" } onChange={handleChange} select>
                            { makes.map(( make, index ) => (
                                <MenuItem key={index} value={make}>{make}</MenuItem>
                            ))}
                        </TextField>
                                   
                    </Box>
                </Grid>
                <Grid item xs={12} sx={{ marginBottom : '1vh'}}> <hr style={bottom_line}/></Grid>
                <Grid item xs={4}>
                    <Typography sx={sub_title}> Model* / Trim *</Typography>
                </Grid>
                <Grid item xs={8}>              
                    <TextField sx={[input_base , { width : '200px'} ]} name={'model'} size="small" placeholder={"Model"} value={F.model} error={E.model !== ''} helperText={E.model}
                    onChange={handleChange}autoComplete={props.dev ? "off" : "new-password" }/>
                    <TextField sx={[input_base , { width : '200px'} ]} name={'trim'} size="small" placeholder={"Trim"} value={F.trim} error={E.trim !== ""} helperText={E.trim}
                    onChange={handleChange} autoComplete={props.dev ? "off" : "new-password" }/>      
                </Grid>
                <Grid item xs={12} sx={{ marginBottom : '1vh'}}> <hr style={bottom_line}/></Grid>
                <Grid item xs={4}>
                    <Typography sx={sub_title}> Color* / Color code* / color code 2*</Typography>
                </Grid>
                <Grid item xs={8}>
                    <TextField sx={[input_base , { width : '133px'} ]} name={'color'} size="small" placeholder={"color"} value={F.color} error={E.color !== ''} helperText={E.color}
                    onChange={handleChange}autoComplete={props.dev ? "off" : "new-password" }/>
                    <TextField sx={[input_base , { width : '133px'} ]} name={'color_code'} size="small" placeholder={"code"} value={F.color_code} error={E.color_code !== ""} helperText={E.color_code}
                    onChange={handleChange} autoComplete={props.dev ? "off" : "new-password" }/>
                    <TextField sx={[input_base , { width : '133px'} ]} name={'color_code_two'} size="small" placeholder={"code two"} value={F.color_code_two} error={E.color_code_two !== ""} helperText={E.color_code_two}
                    onChange={handleChange} autoComplete={props.dev ? "off" : "new-password" }/>         
                </Grid>
                <Grid item xs={12} sx={{ marginBottom : '1vh'}}> <hr style={bottom_line}/></Grid>
                <Grid item xs={4}>
                    <Typography sx={sub_title}> Kilometers* / Insured*</Typography>
                </Grid>
                <Grid item xs={8}>
                    <TextField sx={[input_base , { width : '133px'} ]} name={'kilo'} size="small" placeholder={"Kilometers"} type="number" value={F.kilo} error={E.kilo !== ""} helperText={E.kilo}
                    onChange={handleChange} autoComplete={props.dev ? "off" : "new-password" }/>
                    <Switch checked={F.insured} onChange={HandleSwitch}></Switch>
                </Grid>
                <Grid item xs={12} sx={{ marginBottom : '1vh'}}> <hr style={bottom_line}/></Grid>
                <Grid item xs={4}>
                    <Typography sx={sub_title}> Vin*</Typography>
                </Grid>
                <Grid item xs={8}>
                    <TextField sx={[input_base , { width : '300px'} ]} name={'vin'} size="small" placeholder={"vin"} value={F.vin} error={E.vin !== ""} helperText={E.vin}
                    onChange={handleChange} autoComplete={props.dev ? "off" : "new-password" }/>  
                </Grid>
                <Grid item xs={12} sx={{}}> <hr style={bottom_line}/></Grid>
                <Grid item xs={3}></Grid>
                <Grid item xs={6} sx={{ marginTop : '1vh' ,display : 'flex' , justifyContent : 'center'}}>
                    <Button onClick={() => props.return()} sx={{ marginRight : '1vh' , backgroundColor : 'white' , color : 'black' , border : '1px solid gray'}}>Cancel</Button>
                    <LoadingButton onClick={() => props.callback("car")} loading={props.loading} variant="contained" color="primary" sx={{fontWeight : 600}}>Submit</LoadingButton>
                </Grid>
                    <Grid item xs={3}></Grid>
                </Grid>
        </Box>
    )

}

export default CarInputs