
/*
 * Date: 2023-08-08
 * Description: Escape button for modal dialogs
 * Author: Philippe Leroux @ Skitsc
 */

import { IconButton  } from "@mui/material"
import CloseSharpIcon from '@mui/icons-material/CloseSharp';

interface i_CloseModal {
    handleClose : ( bool : boolean ) => void;
}
const CloseModal = ({ handleClose } : i_CloseModal ) => {
    return (
        <IconButton onClick={() => handleClose(false)} style={{float : "right"}}>
        <   CloseSharpIcon/>
        </IconButton>
    )
}

export default CloseModal