/*
 * Date: 2023-07-17
 * Description: Theme context
 * Author: Philippe Leroux @ skitsc
 */

import React from "react";

//Constants
import { default_org, empty_user } from "../utils/constant";

//Types && interfaces
import { i_main_context , i_theme_context } from "../interfaces/context.interface";
import { NavigateFunction } from "react-router-dom";

export const DefaultContext : i_main_context = {
    authenticated : false,
    user : empty_user,
    config : default_org,
    setCurrentAuth : () => {},
    setCurrentUser: () => {},
    setCurrentConfig: () => {},
    HandleLogin : () => {},
    HandleLogout : (Navigator : NavigateFunction) => {},
}

export const DefaultTheme : i_theme_context = {
    mode : 'Light',
    primary_color : '#B71F26',
    secondary_color : '#F50057',
    hover_color : '#121212',
    selected_color : '#B71F26',
    setCurrentColor : () => {},
    setCurrentTheme : () => {}
}

export const MainContext = React.createContext<i_main_context>(DefaultContext);
export const ThemeContext = React.createContext<i_theme_context>(DefaultTheme);