

/*
 * Date: 2024
 * Description: Reusable select input
 * Author: Philippe Leroux @ Skitsc
 */


//Modules
import { Box , TextField , MenuItem } from "@mui/material"
import { ReactElement, ChangeEvent} from "react"

//Interfaces && types
import { i_select_search_props } from "../../../interfaces/utility.interface"

const SelectFilter = ( props : i_select_search_props) : ReactElement => {
    return (
        <Box sx={{ display : 'flex' }}>
            {props.label !== '' &&
                <Box sx={{  paddingLeft : '10%' , alignSelf : 'center' , fontWeight : 600 , paddingRight : '6px' ,flex: '0 0 auto'}}>{props.label}</Box>
            }
            <TextField size="small" sx={{flex: '0 0 auto'}} value={props.selected} placeholder={props.placeholder} error={props.error_msg !== '' ? true : false} helperText={props.error_msg} onChange={(e :ChangeEvent<HTMLInputElement >) => props.handleSelect(e)} select>  
                {props.data.map((option : any, index : number) => (
                    <MenuItem key={index} value={option.value}>{option.label}</MenuItem>
                ))}
            </TextField>
        </Box>
    )
}

export default SelectFilter