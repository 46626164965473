/*
 * Date: 2024
 * Description: Main dark and light themes
 * Author: Philippe Leroux @ skitsc
 */
import { createTheme } from '@mui/material/styles';
import { red } from '@mui/material/colors';

//Interfaces && types
import { t_mode } from './types/types';

const darkTheme = createTheme({
    palette: {
      mode : 'dark'
    },
      components: {
        MuiOutlinedInput: {
          styleOverrides: {
            root: {
                '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
                  borderColor: '#fff',
                },
              },
            input: {
            '&:-webkit-autofill': {
                boxShadow: '0 0 0 100px #444 inset', 
            },
            caretColor: '#fff',
            borderRadius: 'inherit',
            },
          },
        },
        MuiButton: {
          styleOverrides: {
            root: {
              textTransform: 'none',
              '&:hover': {
                backgroundColor: '#fff',
                color: '#3c52b2',
            },
            },
          },
        },
        MuiTypography : {
          styleOverrides: {
            root: {
              color: '#fff',
            },
          },
        }
    },
});
const Theme = createTheme({
  palette: {
    mode: 'light'
  },
  components: {
    MuiOutlinedInput: {
      styleOverrides: {
        root: {
          '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
            borderColor: '#111',
          },
        },
        input: {
          '&:-webkit-autofill': {
            // Custom autofill styles
          },
          caretColor: red[300],
          borderRadius: 'inherit',
        },
      },
    },
  
    MuiTypography : {
        styleOverrides: {
          root: {
            color: '#344054',
          },
          
        },
      }
  },
});
const generateTheme = (mode : t_mode, primaryColor : string, secondaryColor : string, backgroundColor : string, borderColor : string, textColor : string, selectedActionColor : string, hoverActionColor : string) => {
  const theme = mode === 'Dark' ? 'dark': 'light';
  return createTheme({
      
      palette: {
          mode : theme,
          primary: {
              main: primaryColor,
              contrastText: '#fff'
          },
          secondary: {
              main: secondaryColor,
          },
          background: {
              default: backgroundColor,
          },
          divider: borderColor,
          text: {
              primary: textColor,
          },
          action: {
            selected: selectedActionColor,
            hover: hoverActionColor,
            },
        },
          components: {
            MuiButton: {
                styleOverrides: {
                    root: {
                        textTransform: 'none',
                        '&:hover': {
                            backgroundColor: '#fff',
                            color: '#3c52b2',
                        },
                    },
                },
            },
        },
      
  });
};
export { darkTheme , Theme , generateTheme}