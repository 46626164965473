/*
 * Date: 2023
 * Description: Reusable Footer can be formated on a specific way
 * Author: Philippe Leroux @ skitsc
 */

import { Typography , Box , Grid} from "@mui/material"

interface i_footer_props {
    type : 'left' | 'right' | 'center' | 'off' | undefined
}

const Footer = ({ type } : i_footer_props) => {
    const getCurrentYear = ()  => {
        const currentDate = new Date();
        return currentDate.getFullYear();
    }
    const footer_new = {

        bottom: 0,
        // width: `calc(100% - 250px)`,
        padding : '1vh' , borderTop : '1px solid #D3D3D3' 
    }
    // const old_footer = {
    //     padding : '0.9vh' , borderTop : '1px solid #D3D3D3' , marginLeft : '1%' , marginRight : '1%'
    // }
    return (
        <Box sx={footer_new }>
            <Grid container sx={{ width : '100%'}}>
                <Grid item xs={ type === 'right' ? 5 : 0}></Grid>
                    <Grid item xs={type === 'right' ? 7 : 12} sx={{ display : 'flex'}}>
                        <Grid container >
                            <Grid item xs={6}>
                                <Box sx={{ display : 'flex' , justifyContent : 'flex-start' }}>
                                    <Typography variant="body2" sx={{ textAlign : 'center' , marginRight : '6px'}}>{"© " + getCurrentYear() + " Xykal"}</Typography>
                                    <Typography variant="body2" sx={{ textAlign : 'center', marginRight : '6px'}}>All rights reserved.</Typography>
                                </Box>
                            </Grid>
                            <Grid item xs={6}>
                                <Box sx={{ display : 'flex' , justifyContent : 'flex-end'}}>
                                    <Typography variant="body2" sx={{ textAlign : 'center' , marginRight : '8px'}}>Help</Typography>
                                    <Typography variant="body2" sx={{ textAlign : 'center', marginRight : '8px'}}>Support</Typography>
                                    <Typography variant="body2" sx={{ textAlign : 'center', marginRight : '8px' , cursor : 'pointer' }}>Terms</Typography>
                                    <Typography variant="body2" sx={{ textAlign : 'center', marginRight : '8px' , cursor : 'pointer' }}>Privacy</Typography>
                                    <Typography variant="body2" sx={{ textAlign : 'center', marginRight : '8px' , cursor : 'pointer' }}>Cookies</Typography>
                                </Box>
                            </Grid>
                        </Grid>
                    </Grid>
            </Grid>
        </Box>
    )
}

export default Footer