/*
 * Date: 2024
 * Description: Insurance inputs
 * Author: Philippe Leroux @ skitsc
 */

//Modules
import { Box , Grid , Button , Typography , TextField , Switch } from "@mui/material"
import { LoadingButton } from "@mui/lab"
import { ChangeEvent } from "react"
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import dayjs, { Dayjs } from "dayjs"
//Components
import UploadsInputs from "../../utility/upload.box";
//Styles
import { bottom_line, sub_title , input_base } from "../../../styles/form.styles"
import { i_customer_car_insurance_props } from "../../../interfaces/customer.interface";
  
const InsurancesInputs = ( props : i_customer_car_insurance_props ) => {
    const F = props.form
    const E = props.errors
    const handleDate = (date : Dayjs | null , type : 'expires' | 'delivered') => {
        if(date !== null)  props.setForm({...F , [type] : date.valueOf()})
    }
    const handleChange = (e : ChangeEvent<HTMLInputElement>) => {
        const { name, value } = e.target
        props.setForm({...F, [name] : value})
    }
    const HandleSwitch = () => {
        props.setForm({...F , new_value : F.new_value ? false : true})
    }
    return (
        <Box component={'form'}>
        <Grid container>
            <Grid item xs={12} sx={{ marginBottom : '1vh'}}> <hr style={bottom_line}/></Grid>
            <Grid item xs={4}>
                <Typography sx={sub_title}>Company name*</Typography>
            </Grid>
            <Grid item xs={8}>
                <TextField sx={[input_base , { width : '200px'} ]} name={'company_name'} size="small" placeholder={"company name.."} value={F.company_name} error={E.company_name !== ''} helperText={E.company_name}
                onChange={handleChange} autoComplete={props.dev ? "off" : "new-password" }/>
            </Grid>
            <Grid item xs={12} sx={{ marginBottom : '1vh'}}> <hr style={bottom_line}/></Grid>
            <Grid item xs={4}>
                <Typography sx={sub_title}>No Police*</Typography>
            </Grid>
            <Grid item xs={8}>              
                <TextField sx={[input_base , { width : '200px'} ]} name={'no_police'} size="small" placeholder={"No police.."} value={F.no_police} error={E.no_police !== ""} helperText={E.no_police}
                onChange={handleChange} autoComplete={props.dev ? "off" : "new-password" }/>      
            </Grid>
            <Grid item xs={12} sx={{ marginBottom : '1vh'}}> <hr style={bottom_line}/></Grid>
            <Grid item xs={4}>
                <Typography sx={sub_title}>Period from to *</Typography>
            </Grid>
            <Grid item xs={8}>
                <LocalizationProvider dateAdapter={AdapterDayjs}>
                    <DatePicker slotProps={{ textField: { size: 'small' } }}  sx={[input_base , { width : '184px' , padding : 'initial'  , marginRight : '8px'}]} label="Delivery date"
                      value={dayjs(F.delivered)} onChange={(newValue : Dayjs | null) => handleDate(newValue , 'delivered')}/>
                    <DatePicker slotProps={{ textField: { size: 'small' } }}  sx={[input_base , { width : '184px' , padding : 'initial'}]} label="Expiration date"
                     value={dayjs(F.expires)} onChange={(newValue : Dayjs | null) => handleDate(newValue , 'expires')}/>
                </LocalizationProvider>
            </Grid>
            <Grid item xs={12} sx={{ marginBottom : '1vh'}}> <hr style={bottom_line}/></Grid>
            <Grid item xs={4}>
                <Typography sx={sub_title}>Courrier name</Typography>
            </Grid>
            <Grid item xs={8}>              
                <TextField sx={[input_base , { width : '200px'} ]} name={'courtier'} size="small" placeholder={"Courtier.."} value={F.courtier} error={E.courtier !== ""} helperText={E.courtier}
                onChange={handleChange} autoComplete={props.dev ? "off" : "new-password" }/>      
            </Grid>
            <Grid item xs={12} sx={{ marginBottom : '1vh'}}> <hr style={bottom_line}/></Grid>
            <Grid item xs={4}>
                <Typography sx={sub_title}>New value</Typography>
            </Grid>
            <Grid item xs={8} sx={{ minHeight : '60px'}}>
                <Switch checked={F.new_value} onChange={HandleSwitch}></Switch>
                {F.new_value &&
                    <TextField sx={[input_base , { width : '200px'} ]} name={'new_value_code'} size="small" placeholder={"New value amount.."} value={F.new_value_code} error={E.new_value_code !== ""} helperText={E.new_value_code}
                    onChange={handleChange} autoComplete={props.dev ? "off" : "new-password" }/>      
                }
            </Grid>
            <Grid item xs={12} sx={{ marginBottom : '1vh'}}> <hr style={bottom_line}/></Grid>
            <UploadsInputs {...props.uploads} />
            <Grid item xs={3}></Grid>
            <Grid item xs={6} sx={{ marginTop : '1vh' ,display : 'flex' , justifyContent : 'center'}}>
                <Button onClick={() => props.return()} sx={{ marginRight : '1vh' , backgroundColor : 'white' , color : 'black' , border : '1px solid gray'}}>Cancel</Button>
                <LoadingButton onClick={() => props.callback()} loading={props.loading} variant="contained" color="primary" sx={{fontWeight : 600}}>Submit</LoadingButton>
            </Grid>
                <Grid item xs={3}></Grid>
            </Grid>
    </Box>
    )
}

export default InsurancesInputs