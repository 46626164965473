/*
 * Date: 2024
 * Description: Dedicated body for jobs views..
 * Author: Philippe Leroux @ Skitsc
 */


//Modules
import { Box , Grid} from "@mui/material";
import { LoadingButton } from "@mui/lab";
import { useState } from "react";
//Components

//Styles
import { button_center } from "../../../styles/form.styles";

//Interfaces && types
import { i_fragmented_body } from "../../../interfaces/utility.interface";

const JobFragmentedBody = ( props : i_fragmented_body ) => {
    const [ contacts , setContacts ] = useState(props.form.form.contact_array)
    const contact_props = {
        contacts : contacts,
        setContacts : setContacts,
    }
    // const formated_props : any = {
    //     ...props,
    //     contact_props: contact_props,
    // };
    return (
        <Box>
            <Grid container sx={{ marginTop : '2vh'}}>
                <Grid item xs={12}>
                   
                </Grid>
                <Grid item xs={12} sx={{ marginTop : '2vh'}}>
                    <LoadingButton onClick={() => props.form.callback(contact_props)} sx={button_center} variant="contained" loading={props.form.loading}> Save changes </LoadingButton>
                </Grid>
            </Grid>
        </Box>
    )
}

export default JobFragmentedBody