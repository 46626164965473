/*
 * Date: 2023-09-11
 * Description: Dedicated body for prompt , yes or no
 * Author: Philippe Leroux @ Skitsc
 */

//Modules
import { forwardRef , useState } from "react"

import { Box  , Typography , Grid, Tooltip } from "@mui/material"
import { LoadingButton } from "@mui/lab"
//Components
import CloseModal from "./utils/close.modal"
//Styles
import { getBigModalStyle, modal_style } from "../../styles/main.styles"

//Interfaces && types 
import { i_prompt_modal_props } from "../../interfaces/utility.interface"
import { button_center } from "../../styles/form.styles"

const ModalPromptBody = forwardRef((props : i_prompt_modal_props , ref) => {
    const [ modalStyle ] = useState(getBigModalStyle(props.mode))
    const f_return_title = () => {
        if(props.type === 'delete') return 'Are you sure you want to delete :'
        if(props.type === 'disable') return 'Are you sure you want to disable :'
        if(props.type === 'enable') return 'Are you sure you want to enable :'
        if(props.type === 'update') return 'Are you sure you want to edit :'
        return "Invalid props , please contact the developer"
    }
    console.log(props.type)
    return(
        <Box component={'div'} style={modalStyle} sx={modal_style}>
            <CloseModal {...props}></CloseModal>
            <Grid container>
                { props.type !== 'disable' && 
                <Grid item xs={12}>
                    <Typography sx={{ textAlign : "center" , marginBottom : '1vh' , marginTop : '1vh'}}variant={"h5"}>{f_return_title()}</Typography>
                </Grid>
                }
                <Grid item xs={12}>
                    <Typography sx={{ textAlign : "center" , marginBottom : '3vh' , marginTop : '0vh'}}variant={"h5"}>{props.title}</Typography>
                </Grid>
                <Grid item xs={6}>
                    <Tooltip title={'Yes actually its gonna be deleted for real , not only disabled.. '}>
                        <LoadingButton onClick={() => props.callback()} loading={props.loading} variant="contained" sx={[ button_center , { width : '40%'} ]}>Yes</LoadingButton>
                    </Tooltip>
                </Grid>
                <Grid item xs={6}>
                    <LoadingButton disabled={props.loading} onClick={() => props.handleClose(true)} variant="contained" sx={[ button_center , { width : '40%'}]}>No</LoadingButton>
                </Grid>
            </Grid>
        </Box>
    )
})
export default ModalPromptBody