
/*
 * Date: 2024
 * Description: Reusable headers setup
 * Author: Philippe Leroux @ Skitsc
 */


//Modules
import { Grid , Typography } from "@mui/material";
import { ReactElement } from "react";

//Interfaces && types
import { i_tbl_header } from "../../interfaces/utility.interface";

//Styles
import { header_format } from "../../styles/tbl.styles";

export interface i_header_v2 {
    headers : i_tbl_header[]
}

const HeaderV2 = ( { headers} : i_header_v2 ) : ReactElement => {
    return (
        <Grid container sx={{ border : '1px solid #D3D3D3' , padding : '12px' , borderTopLeftRadius : '8px' , borderTopRightRadius : '8px'}}>
            {headers.map(( row : any , index : number) => (
                <Grid key={index} item xs={row.portion} sx={{ display : 'flex'}}>
                    <Typography sx={header_format}>{row.value}</Typography>
                    {row.icon !== undefined && row.icon}
                </Grid>
            ))}
        </Grid>
    )
}

export default HeaderV2;