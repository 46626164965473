/*
 * Date: 2024
 * Description: Remade entirely , form part for paiments and additonals
 * Author: Philippe Leroux @ skitsc
 */

//Modules
import { ReactElement , ChangeEvent } from "react";
import { Box, MenuItem , TextField, Grid , Typography } from "@mui/material";

//Components
//Interfaces && types
import { i_customer_form_props } from "../../../interfaces/customer.interface";

//Styles
import { input_base } from "../../../styles/form.styles";

const ImportantDetails = (props: i_customer_form_props) : ReactElement =>{
    const F = props.form;
    const E = props.errors
    const handleChange = (e : ChangeEvent<HTMLInputElement>) => {
        const { name, value } = e.target
        props.setForm({...F, [name] : value})
    }
  return (
        <Box>
            <Typography sx={{ marginTop : '2vh' , marginBottom : '2vh', fontSize : 20 , fontWeight : 800}}> Customer billing and settings.</Typography>
            <Grid container>
                <Grid item xs={3}>
                    <TextField fullWidth select value={F.tax_preference} label={"Taxation *"} size={'small'} sx={input_base} onChange={handleChange} name="tax_preference"  error={E.customer_type !== '' ? true : false} helperText={E.customer_type}>
                    
                        <MenuItem value={'Taxable'}>Taxable</MenuItem>
                        <MenuItem value={'Exonere'}>Exonere</MenuItem>
                    </TextField>
                </Grid>
                <Grid item xs={3}>
                    <TextField fullWidth label={"Tax rates *"} select value={F.tax_rate} size={'small'} sx={input_base} onChange={handleChange} name="tax_rate" error={E.tax_rate!== '' ? true : false} helperText={E.tax_rate}>
                        <MenuItem value={'14.975%'}>[14.975%]</MenuItem>
                        <MenuItem value={'9.975%'}>[9.975%]</MenuItem>
                        <MenuItem value={'5%'}>[5%]</MenuItem>
                        <MenuItem value={'0%'}>[0%]</MenuItem>
                    </TextField>
                </Grid>
                <Grid item xs={3}>
                    <TextField label={'Currency *'} fullWidth select value={F.currency} size={'small'} sx={input_base} onChange={handleChange} name="currency" error={E.currency!== ''? true : false} helperText={E.currency}>
                        <MenuItem value={"CAD"}>CAD - Canadian Dollar</MenuItem>
                        <MenuItem value={"USD"}>USD - US Dollar</MenuItem>
                    </TextField>
                </Grid>
                <Grid item xs={3}>
                </Grid>
                <Grid item xs={3}>
                    <TextField fullWidth label={'Payment terms *'} select value={F.payment_terms} size={'small'} sx={input_base} onChange={handleChange} name={'payment_terms'} error={E.payment_terms!== ''? true : false} helperText={E.payment_terms}>
                        <MenuItem value={'Initial payment'}>Initial payment</MenuItem>
                        <MenuItem value={'30 days after receipt'}>30 days after receipt</MenuItem>
                        <MenuItem value={'15 days after receipt'}>15 days after receipt</MenuItem>
                        <MenuItem value={'21 days after receipt'}>21 days after receipt</MenuItem>
                        <MenuItem value={'Paid anticipated'}>Paid anticipated</MenuItem>
                        <MenuItem value={'Paid on receiption'}>Paid on receiption</MenuItem>
                    </TextField>
                </Grid>
                <Grid item xs={3}>
                    <TextField fullWidth label={"Portal language *"} value={F.portal_language} size={'small'} sx={input_base} select onChange={handleChange} name={'portal_language'} error={E.portal_language!== ''? true : false} helperText={E.portal_language}>
                        <MenuItem value={'EN'}>English</MenuItem>
                        <MenuItem value={'FR'}>French</MenuItem>
                    </TextField>
                </Grid>
                <Grid item xs={3}>
                    <TextField fullWidth label={"Welcome email *"} sx={input_base} size={'small'} select value={F.welcome_email} name={'welcome_email'} onChange={handleChange}>
                        <MenuItem value={'false'}>OFF</MenuItem>
                        <MenuItem value={'true'}>ON</MenuItem>
                    </TextField>
                </Grid>
        </Grid>
      </Box>
  );
};

export default ImportantDetails;
