/*
 * Date: 2024
 * Description: Profile page , where current user can update and visualize its profile.
 * Author: Philippe Leroux @ skitsc
 */

//Modules
import { useState, useContext, useEffect, ReactElement , useMemo , useCallback } from "react";
import { Box , Grid , Typography } from "@mui/material";

//Interfaces && types
import { i_user, i_user_errors } from "../interfaces/user.interface";
import { i_alert_props, i_snack_alert , i_promise, i_initial_props, i_file_uploads_props } from "../interfaces/utility.interface";
import { i_profile_password_item } from "../interfaces/profile.interface";

//Context
import { MainContext } from "../context/context";

//Middlewares
import { m_validate_profil_information, m_validate_profil_passwords, m_validate_uploads } from "../validation/main.middleware";
import { m_force_str, m_validate_email, m_validate_opt_phone, m_validate_password, m_validate_phone } from "../validation/utility.middleware";

//Api
import { f_fetch, f_fetch_multiform } from "../api/fetch";

//Components
import ProfilePasswordItem from "../components/profile/profile.password";
import AlertDialog from "../components/utility/alert";
import UploadsInputs from "../components/utility/upload.box";
import Footer from "../components/utility/footer";
import ProfileInformation from "../components/profile/profile.information";
//Utilitys
import { empty_promise, empty_user_errors } from "../utils/constant";

//Styles
import { bottom_line } from "../styles/form.styles";

const Profile = ( props : i_initial_props ) : ReactElement => {
    
    const { user , setCurrentUser } = useContext(MainContext)
    //Main states
    const [ loading , setLoading ] = useState<boolean>(false);
    const [ selected , setSelected ] = useState<"password" | 'information'>('information');
    //Inputs
    const [ form , setForm ] = useState<i_user>({...user , confirm_password : '' , password : '' , old_password : ''});

    //Uploads state
    const [ uploads, setUploads ] = useState<any[]>([]);
    const [ assets, setAssets ] = useState<string[]>(user.path !== undefined && user.path?.length > 0 ? user.path : []);
    //Errors management
    const [ errors , setErrors ] = useState<i_user_errors>(empty_user_errors);
    const [ Euploads , setEuploads ] = useState<string>('');
    const [ api_error , setApiError ] = useState<i_snack_alert>({open : false , promise : empty_promise});
    const handleCallback = useCallback(async (type: "password" | 'information') => {
        setSelected(type);
        setLoading(true);
        const [valid, msg, err_field] = type === "information" ? m_validate_profil_information(form) : type === 'password' ? m_validate_profil_passwords(form) : m_validate_uploads(uploads, assets);
        if (type === 'information') {
            if (valid) {
                const data = { email: form.email, first_name: form.first_name, last_name: form.last_name,
                     phone_one: form.phone_one, phone_two: form.phone_two , unit_number: form.unit_number,
                      door_number: form.door_number , street: form.street, city: form.city, state: form.state, zip: form.zip };
                const res: i_promise = await f_fetch('/user/profile/information', 'PATCH', true, data);
                if(res.type === 'Success') {
                    setCurrentUser( {...form })
                } 
                setApiError({ open: true, promise: res });
            } else {
                setErrors({ ...errors, [err_field]: msg });
            }
        }
        if (type === 'password') {
            if (valid) {
                const data = { password: form.password, old_password: form.old_password, confirm_password: form.confirm_password };
                const res: i_promise = await f_fetch('/user/profile/password', 'PATCH', true, data);
                if (res.type === 'Success') {
                    setForm({ ...user, password: "", confirm_password: "", old_password: "" });
                }
                setApiError({ open: true, promise: res });
            } else {
                setErrors({ ...errors, [err_field]: msg });
            }
        }
        setLoading(false);
    }, [form, uploads, assets, errors, user , setCurrentUser]);


    const password_props : i_profile_password_item = {
        form : form,
        setForm : setForm,
        errors : errors,
        loading : loading,
        selected : selected,
        callback : handleCallback
    }
    const alert_props : i_alert_props = {
        event : api_error,
        handleClose : setApiError,
        type : 'simple'
    }
    useEffect(() => {
        const E = errors
        const F = form
        if(E.email !== '' && m_validate_email(F.email)) setErrors({...errors, email : ''})
        if(E.first_name !== '' && m_force_str(F.first_name) === '') setErrors({...errors, first_name : ''})
        if(E.last_name !== '' && m_force_str(F.last_name) === '') setErrors({...errors, last_name : ''})
        if(E.phone_one !== '' && m_validate_phone(F.phone_one)) setErrors({...errors, phone_one : ''})
        if(E.phone_two !== '' &&  m_validate_opt_phone(F.phone_two)) setErrors({...errors, phone_two : ''})
        if(E.password !== '' && m_validate_password(F.password)) setErrors({...errors, password : ''})
        if(E.confirm_password !== '' && F.password === F.confirm_password) setErrors({...errors, confirm_password : ''})
        if(E.old_password!== '' && F.old_password !== undefined && m_validate_password(F.old_password)) setErrors({...errors, old_password : ''})
    },[form , errors])
    // Finaly founded why it was flickering -> solution here....
    const handleFiles = useCallback(async () => {
        const FD = new FormData();
        FD.append('files', uploads[0]);
        const res = await f_fetch_multiform('/user/profile/picture', 'PATCH', FD);
        if(res.type === 'Success') setCurrentUser({...user, path : res.data});
        setApiError({ open: true, promise: res });
    }, [setApiError , uploads , setCurrentUser , user]);
    const uploads_props : i_file_uploads_props = {
        uploads : uploads,
        assets : assets,
        max : 1,
        setUploads : setUploads,
        setAssets : setAssets,
        path : '/webapi/uploads/profile/',
        title : 'Profile picture',
        setApiError : setApiError,
        Euploads : Euploads,
        setEuploads : setEuploads,
        type : 'form',
        box_width : '350px',
        callback: handleFiles //Here it shall not be pass like () => handleFiles()
    }
    const profile_information_props = useMemo(() => ({
        form: form,
        setForm: setForm,
        errors: errors,
        loading: loading,
        callback: handleCallback
    }), [form, errors, loading, handleCallback]);
  return (
    <Box>
        <Box p={4} component={"form"} sx={{ minHeight : '91.5vh'}}>
            <Grid container>
                <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                    <Typography sx={{ fontWeight : 800 , fontSize : 20 ,  alignSelf : 'center'}}>Your Profile</Typography>
                    <Typography sx={{fontWeight : 800 , fontSize : 16 , marginBottom : '2vh' , color : 'grey'}}> Manage your personnal information </Typography>
                </Grid>
                <ProfileInformation {...profile_information_props}/>
                <Grid item xs={12} sx={{ marginBottom : '1vh'}} mt={3}> <hr style={bottom_line}/></Grid>
                <Grid item xs={12}>
                    <UploadsInputs {...uploads_props}></UploadsInputs>
                </Grid>
                <Grid item xs={12} sx={{ marginBottom : '1vh'}}> <hr style={bottom_line}/></Grid>
                <Grid item xs={12}>
                    <ProfilePasswordItem {...password_props}/>
                </Grid>
            </Grid>
            <AlertDialog {...alert_props}/>
        </Box>
        <Footer type={'center'}></Footer>
    </Box>
  );
}

export default Profile;
