
/*
 * Date: 2023
 * Description: Jobs top views ( data )
 * Author: Philippe Leroux @ Skitsc
 */

//Modules
import { Box , Grid , Typography , Accordion , AccordionDetails , AccordionSummary } from "@mui/material"

//Icons
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
const TaskTop = ( props : any ) => {
    const box_sizing = {
        border : '1px solid #D3D3D3',
        padding : '10px',
        minHeight : '14vh',
        marginLeft : '5%',
        marginRight : '5%',
        borderRadius : '10px',
        width : '500px'
    }
    const chart_proportion = {
        width : '150px',
        marginTop : '1.5vh'
    }
    return (
        <Accordion expanded={props.acc_open} onChange={() => props.handleAcc('stats')} sx={{ }}>
            <AccordionSummary sx={{ borderColor : '#D3D3D3'}} expandIcon={<ExpandMoreIcon />} aria-controls="panel-job-search" id="panel-job-search">
                <Typography sx={{ fontWeight : 600}}>Stats</Typography>
            </AccordionSummary>
            <AccordionDetails>
            <Grid container sx={{ display : 'flex'  , justifyContent : 'center'}}>
                <Grid item sx={{ display : 'flex' }}>
                    <Box sx={[box_sizing ]}>
                        <Grid container>
                            <Grid item xs={12} sx={{ height : '100%' , display : 'flex'}}>
                                <Typography sx={{ fontWeight : 800 , fontSize : 15 , paddingRight : '8px'}}> Average turn over </Typography>
                                <Typography sx={{ fontWeight : 800, fontSize : 10 , color : 'gray' , alignSelf : 'center'}}> per month </Typography>
                            </Grid>
                            <Grid item xs={6}>
                                <Box sx={{ display : 'flex' ,marginTop : '1.5vh' }}>
                                    <Typography sx={{ fontWeight : 800 , fontSize : 30 , marginRight : '2px' ,}}> {props.turn_over } </Typography>
                                    <Typography sx={{ color : '#009900' , alignSelf : 'center'}}> + { props.turn_over_plus}</Typography>
                                </Box>
                                <Box sx={{ display : 'flex' , marginTop : '1vh'}}>
                                    <Box component={'img'} alt={'Up or down icon'} src={'./images/up_green.png'}></Box>
                                    <Typography sx={{ paddingRight : '6px' , color : 'green'}}>{props.turn_over_last_month}</Typography>
                                    <Typography>vs last month</Typography>
                                </Box>
                            </Grid>
                            <Grid item xs={6}>
                                <Box component={'img'} alt={'chart img'} src={'./images/chat_mini.png'} sx={chart_proportion}>
                                    
                                </Box>
                            </Grid>
                        </Grid>
                    </Box>
                </Grid>
                <Grid item sx={{ display : 'flex' }}>
                    <Box sx={[box_sizing]}>
                        <Grid container>
                            <Grid item xs={12} sx={{ height : '100%' }}>
                                <Typography sx={{ fontWeight : 800 , fontSize : 15 , paddingRight : '8px'}}>Today's task completion </Typography>
                                <Typography sx={{ fontWeight : 800, fontSize : 15 ,  alignSelf : 'center'}}> expectation </Typography>
                            </Grid>
                            <Grid item xs={6}>
                                <Box sx={{ display : 'flex' ,marginTop : '1.5vh' }}>
                                    <Typography sx={{ fontWeight : 800 , fontSize : 30 , marginRight : '2px' ,}}> {props.today_task_completion } </Typography>
                                </Box>
                                <Box sx={{ display : 'flex' , marginTop : '1vh'}}>
                                    <Box component={'img'} alt={'Up or down icon'} src={'./images/up_green.png'}></Box>
                                    <Typography sx={{ paddingRight : '6px' , color : 'green'}}>{props.today_task_vs_yesterday}</Typography>
                                    <Typography>vs yesterday</Typography>
                                </Box>
                            </Grid>
                            <Grid item xs={6}>
                                <Box component={'img'} alt={'chart img'} src={'./images/chat_mini.png'} sx={chart_proportion}>
                                    
                                </Box>
                            </Grid>
                        </Grid>
                        
                    </Box>
                </Grid>
                <Grid item sx={{ display : 'flex' }}>
                    <Box sx={[box_sizing ]}>
                        <Grid container>
                            <Grid item xs={12} sx={{ height : '100%' }}>
                                <Typography sx={{ fontWeight : 800 , fontSize : 15 , paddingRight : '8px'}}>This week's task </Typography>
                                <Typography sx={{ fontWeight : 800, fontSize : 15 ,  alignSelf : 'center'}}>completion expectation </Typography>
                            </Grid>
                            <Grid item xs={6}>
                                <Box sx={{ display : 'flex' ,marginTop : '1.5vh' }}>
                                    <Typography sx={{ fontWeight : 800 , fontSize : 30 , marginRight : '2px' ,}}> {props.week_task_completion } </Typography>
                                </Box>
                                <Box sx={{ display : 'flex' , marginTop : '1vh'}}>
                                    <Box component={'img'} alt={'Up or down icon'} src={'./images/up_green.png'}></Box>
                                    <Typography sx={{ paddingRight : '6px' , color : 'green'}}>{props.today_task_vs_yesterday}</Typography>
                                    <Typography>vs yesterday</Typography>
                                </Box>
                            </Grid>
                            <Grid item xs={6}>
                                <Box component={'img'} alt={'chart img'} src={'./images/chat_mini.png'} sx={chart_proportion}>
                                    
                                </Box>
                            </Grid>
                        </Grid>
                        
                    </Box>
                </Grid>
              
   
            </Grid>
            </AccordionDetails>
        </Accordion>
    )


}

export default TaskTop