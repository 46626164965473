


//Modules
import { memo } from "react"
import { Box , Grid , Typography } from "@mui/material"
import { LoadingButton } from "@mui/lab"

//Interfaces && types

//Components
import ProfileItem from "./profile.row"
//Styles
import { sub_title } from "../../styles/form.styles"

const ProfileInformation = memo(( props : any ) => {
    const F = props.form
    const E = props.errors
    const handleChange = (e : React.ChangeEvent<HTMLInputElement>) => {
        const { name, value } = e.target
        props.setForm({...F, [name] : value})
    }

    return (
        <Box>
            <Grid container>
            <Grid item xs={3}>
                <Typography sx={sub_title}> Your email</Typography>
                </Grid>
                <Grid item xs={9}>
                    <ProfileItem value={F.email} title="email" setValue={handleChange}error={E.email}  />
                </Grid>
                <Grid item xs={3}>
                    <Typography sx={sub_title}> First name* / Last name* </Typography>
                </Grid>
                <Grid item xs={9}>
                    <ProfileItem value={F.first_name} title="first_name" setValue={handleChange}  error={E.first_name} />
                    <ProfileItem value={F.last_name} title="last_name" setValue={handleChange}  error={E.last_name} />
                </Grid>
                <Grid item xs={3}>
                    <Typography sx={sub_title}> Phone / Secondary phone :</Typography>
                </Grid>
                <Grid item xs={9}> 
                    <ProfileItem value={F.phone_one} title="phone_one" setValue={handleChange}  error={E.phone_one}/>
                    <ProfileItem value={F.phone_two} title="phone_two" setValue={handleChange} error={E.phone_two}/>
                </Grid>
                <Grid item xs={12}>
                    <Typography sx={[sub_title , { fontSize : 18}]} mb={3} mt={1}>Address </Typography>
                </Grid>
                <Grid item xs={3}>
                    <Typography sx={sub_title}>Unit number / Civic number* / Street*</Typography>
                </Grid>
                <Grid item xs={9}> 
                    <ProfileItem width="175px" value={F.unit_number} title="unit_number" setValue={handleChange} error={E.unit_number}/>
                    <ProfileItem width="175px" value={F.door_number} title="door_number" setValue={handleChange} error={E.door_number}/>
                    <ProfileItem width="350px"  value={F.street} title="street" setValue={handleChange}  error={E.street}/>
                </Grid>
                <Grid item xs={3}>
                    <Typography sx={sub_title}> City* / Province* / Postal code*</Typography>
                </Grid>
                <Grid item xs={9}> 
                    <ProfileItem width="350px" value={F.city} title="city" setValue={handleChange} error={E.city} />
                    <ProfileItem width="175px" select={true} value={F.state} title="state" setValue={handleChange}  error={E.state} />
                    <ProfileItem width="175px" value={F.zip} title="zip" setValue={handleChange}  error={E.zip} />
                </Grid>
                <Grid item xs={12}> 
                    <LoadingButton loading={props.loading} onClick={() => props.callback('information')} variant="contained" color="primary" sx={{ display : 'block' , marginLeft : 'auto' , marginRight : 'auto' , width:'300px' }}>Update</LoadingButton>
                </Grid>
            </Grid>
        </Box>
    )

})

export default ProfileInformation