

//Modules
import React, { ReactElement } from "react";
import { Box , Container , Typography , Paper , Grid } from "@mui/material";

//Interfaces && types
import { i_initial_props } from "../interfaces/utility.interface";

const Dashboard = ( props : i_initial_props ) : ReactElement => {
  return (
    <Box>
      <Container sx={{ marginTop : '2vh'}}>
         <Typography sx={{ textAlign : 'center'}}> Dashboard </Typography>
         <Grid container>
          <Grid item xs={3}>

          </Grid>          
          <Grid item xs={6}>
                <Paper sx={{ padding : '3vh'}}>
                    <Box  sx={{ marginTop : '1vh'}}>
                        Normally here would be important information like the next event , the latest inquiries and more..
                    </Box>
                    <Box  sx={{ marginTop : '1vh' , fontWeight : 800}}>
                        Customer now includes most of the features , insert update , Assign cars , registrations and insurances..
                    </Box>
                    <Box sx={{ marginTop : '1vh' , textDecoration : 'underline'}}>
                        Now a job can be inserted and filtered with pages
                    </Box>
                    <Box sx={{ marginTop : '1vh' , textDecoration : 'underline'}}>
                        Added setting page , this includes a page for users and general config of the application
                    </Box>
                    <Box sx={{ marginTop : '1vh' , textDecoration : 'underline'}}>
                        Working on the task part
                    </Box>
                    <Box  sx={{ marginTop : '1vh' , textDecoration : 'underline'}}>
                        Much more to come
                    </Box>
                </Paper>
          </Grid>
          <Grid item xs={3}>

          </Grid>
         </Grid>
      </Container>
    </Box>
  );
}

export default Dashboard;