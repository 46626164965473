/*
 * Date: 2023
 * Description: Utility functions to validate interface typings
 * Author: Philippe Leroux @ skitsc
 */


//Interfaces && types
import { i_customer_car , i_registration , i_insurance } from "../interfaces/customer.interface";
const f_valid_car = (value: any): value is i_customer_car => {
    return (
      value.make !== undefined &&
      value.model !== undefined &&
      value.year !== undefined &&
      value.color!== undefined &&
      value.trim!== undefined &&
      value.color_code!== undefined &&
      value.color_code_two!== undefined &&
      value.kilo!== undefined &&
      value.vin!== undefined &&
      value.registration !== undefined &&
      value.insurance !== undefined &&
      true
    );
};
const f_valid_car_registration = (value: any): value is i_registration => {
    return(
        value &&
        value.path !== undefined &&
        value.delivered !== undefined &&
        value.expires!== undefined &&
        value.no_cert !== undefined &&
        value.registration_number !== undefined &&
        true
    )
}
const f_valid_car_insurance = (value: any): value is i_insurance => {
    return(
        value &&
        value.path !== undefined&&
        value.delivered !== undefined&&
        value.expires !== undefined&&
        value.no_police !== undefined&&
        value.company_name !== undefined&&
        value.courtier !== undefined&&
        value.new_value !== undefined&&
        true
    )
}

export { f_valid_car , f_valid_car_insurance , f_valid_car_registration}