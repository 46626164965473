
/*
 * Date: 2024
 * Description: Customer rows reworked for table
 * Author: Philippe Leroux @ Skitsc
 */

//Modules
import { Box , Grid , Typography , IconButton} from "@mui/material"
import { ReactElement } from "react"


//Interfaces && types
import { i_table_v2_row_props } from "../../../interfaces/utility.interface";

//Icons
import RemoveRedEyeIcon from '@mui/icons-material/RemoveRedEye';
import EditIcon from '@mui/icons-material/Edit';
import CarCrashIcon from '@mui/icons-material/CarCrash';
import EventAvailableIcon from '@mui/icons-material/EventAvailable';
import HandymanIcon from '@mui/icons-material/Handyman';
import BusinessIcon from '@mui/icons-material/Business';

//Components
import { BlueToolTip } from "../../utility/tooltip.custom";

//Utils
import { f_format_phone_cute , f_timestamp_to_date} from "../../../utils/utility";


const CustomerRow = ( props : i_table_v2_row_props) : ReactElement => {

    const formatTimes = ( value : any ) => {
        const date = f_timestamp_to_date(value , "long")
        return 'Since : ' + date
    }

    return (
        <Box sx={{ borderLeft : '1px solid #D3D3D3' , borderRight : '1px solid #D3D3D3' , borderBottom : '1px solid #D3D3D3'}}>
        <Grid container sx={{ padding : '6px'}}>
            <Grid item xs={props.headers[0].portion} sx={{ alignSelf : 'center'}}>
                <Typography sx={{ alignSelf : 'center' ,fontSize : 14 }}> {props.row.first_name + ' ' + props.row.last_name}</Typography>
            </Grid>
            <Grid item xs={props.headers[1].portion} sx={{ alignSelf : 'center'}}>
                <Typography sx={{ alignSelf : 'center' , fontSize : 14}}>{props.row.email}</Typography>
            </Grid>
            <Grid item xs={props.headers[2].portion} sx={{ alignSelf : 'center' , display : 'flex'}}>
                <Typography sx={{ alignSelf : 'center' , fontSize : 14}}>{f_format_phone_cute(props.row.phone_one)}</Typography>
            </Grid>
            <Grid item xs={props.headers[3].portion} sx={{ alignSelf : 'center'}}>
                <EventAvailableIcon/>
                <CarCrashIcon/>
                <HandymanIcon/>
            </Grid>
            <Grid item xs={props.headers[4].portion} sx={{ alignSelf : 'center'}}>
                <Typography sx={{ fontSize : 14 }}>11/12/2023</Typography>
            </Grid>
            <Grid item xs={props.headers[5].portion} sx={{ alignSelf : 'center'}}>
                <BlueToolTip title={formatTimes(props.row.timestamp)} placement="top" arrow>
                    <BusinessIcon/>
                </BlueToolTip>
            </Grid>
            <Grid item xs={props.headers[5].portion} sx={{ display : 'flex' , alignSelf : 'center'}}>
                <IconButton onClick={() => props.callback(props.row , 'view')}><RemoveRedEyeIcon/></IconButton>
                <IconButton onClick={() => props.callback(props.row , 'form')}><EditIcon color={"primary"}/></IconButton>
            </Grid>
        </Grid>
    </Box>
    )
}

export default CustomerRow