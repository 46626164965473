/*
 * Date: 2024
 * Description: Customer page entirely remade , remade most part of the fields includes the driver licence
 * Author: Philippe Leroux @ skitsc
 */

//Modules
import { useState, useEffect, ReactElement ,useContext , ChangeEvent } from "react";
import { useNavigate } from "react-router-dom";
//Mui
import { Box , IconButton, Modal } from "@mui/material";

//Components
import AlertDialog from "../components/utility/alert";
import CircularUnderLoad from "../components/utility/center.loader";
import TblV2 from "../components/table/table.v2";
import ModalPromptBody from "../components/modal/modal.prompt";
import CustomerView from "../components/customers/customer.view";
import ShellModal from "../components/modal/modal";
import CustomerFragmentedBody from "../components/modal/body/modal.fragment.customer";
import CustomerInputsV2 from "../components/customers/form/customer.inputs";
import Footer from "../components/utility/footer";
import CustomerTop from "../components/customers/card/customer.top";
import SearchFilter from "../components/utility/inputs/search.filter";
import TopTableBar from "../components/utility/top.table.bar";

//interfaces
import {  i_customer_errors, i_customer, i_customer_form_props, i_customer_view_props, i_customer_car, i_customer_search_filter} from "../interfaces/customer.interface";
    
   
import { t_display, t_method, t_transition_event } from "../types/types";
import { i_snack_alert , i_alert_props, i_file_uploads_props , i_socket_response , i_prompt_modal_props , i_shell_modal,
     i_fragmented_body, i_initial_props , i_basic_search_props , i_top_table_bar_props , i_tbl_header , i_table_v2_props, 
     i_pagination_new} from "../interfaces/utility.interface";

//utility
import { empty_promise, empty_customer, default_customer_filter, empty_customer_errors } from "../utils/constant";
import { delay , CheckEnv, f_make_customer_fd, f_encode_query_data} from "../utils/utility";

//fetch
import { f_fetch, f_fetch_multiform } from "../api/fetch";

//Contexts
import { MainContext, ThemeContext } from "../context/context";
import { SocketContext } from "../context/socket.context";

//Middlewares
import { m_validate_str , m_validate_email , m_validate_phone, m_force_str, m_validate_tax_type, m_validate_tax_rates, m_validate_currency,
   m_validate_payment_condition, m_validate_language, m_validate_zip_code , m_validate_date, m_validate_opt_zip_code } from "../validation/utility.middleware";
import { m_validate_customer } from "../validation/main.middleware";

//Styles
import { header_format, tbl_boxing } from "../styles/tbl.styles";

const Customers = ( props : i_initial_props ) : ReactElement => {

    const nav = useNavigate();
    const { HandleLogout } = useContext(MainContext);
    const { mode } = useContext(ThemeContext)
    const socket = useContext(SocketContext);

    //Main States
    const [ loading , setLoading ] = useState<boolean>(true)
    const [ disable_loading , setDisableLoading ] = useState<boolean>(false)
    const [ loading_cars , setLoadingCars ] = useState<boolean>(false)
    const [ customer_cars , setCustomerCars ] = useState<i_customer_car[]>([])
    const [ display , setDisplay ] = useState<t_display>("table");
    const [ data , setData ] = useState<i_customer[]>([]);
    const [ count , setCount ] = useState<number>(0);
    const [ search , setSearch ] = useState<string>('');
    const [ api_error, setApiError ] = useState<i_snack_alert>({ open : false , promise : empty_promise});
    const [ dev ] = useState<boolean>(CheckEnv);
    const [ open , setOpen ] = useState<boolean>(false);
    const [ modal_type , setModalType ] = useState<t_transition_event>('disable');
    const [ filter , setFilter ] = useState<i_customer_search_filter>(default_customer_filter);
    //Form States
    //Customer form
    const [ customer , setCustomer ] = useState<i_customer>(empty_customer);
    const [ prev_customer , setPrevCustomer ] = useState<i_customer>(empty_customer);
    const [ assets, setAssets ] = useState<string[]>([]);
    const [ uploads, setUploads ] = useState<any[]>([]);
    // Customer fields Errors
    const [ customer_errors , setCustomerErrors ] = useState<i_customer_errors>(empty_customer_errors)
    const [ Euploads, setEuploads ] = useState<string>("");

    useEffect(() => {
    socket.removeAllListeners("customer");
    socket.on('customer', ( output : i_socket_response ) => {
        if(output.type === 'Add'){
            const addRow = ( ItemAdded : any ) => {
                const data_to_update = [ ...data ]
                const objectExists = data_to_update.some(( obj : any ) => obj._id === ItemAdded._id);
                if(!objectExists){
                    data_to_update.push(ItemAdded)
                    setData(data_to_update)    
                }
            }    
            addRow(output.item)
        }
        if(output.type === 'Update'){
            const updateItem = ( ItemUpdated : any ) => {
                const data_to_update = [ ...data ] 
                const updatedItems = data_to_update.map( ( item : any , i : number) => {
                    if (item._id === ItemUpdated._id) {
                        return ItemUpdated
                    } else {
                    return item
                    }
                })
                
                if(customer._id === ItemUpdated._id) { 
                    const clean_target = { ...ItemUpdated }
                    setCustomer(clean_target)
                }
                setData(updatedItems)
            } 
            updateItem(output.item)
        }
        if(output.type === 'Delete'){
            const DeleteItem = ( ItemDeleted : any ) => {
                const data_to_delete = [ ...data ] 
                const index = data_to_delete.findIndex(( row : any ) => row._id === ItemDeleted._id);
                if(index > -1){
                    data_to_delete.splice(index,1)
                    setData(data_to_delete)
                }
            } 
            DeleteItem(output.item)
        }
    })
    socket.removeAllListeners("customer_car");
    socket.on('customer_car', ( output : i_socket_response ) => {
        console.log(output)
        if(customer._id === output.item.customer_id){
            if(output.type === 'Add'){
                const addRow = ( ItemAdded : any ) => {
                    const data_to_update = [ ...customer_cars ]
                    const objectExists = data_to_update.some(( obj : any ) => obj._id === ItemAdded._id);
                    if(!objectExists){
                        data_to_update.push(ItemAdded)
                        setCustomerCars(data_to_update)    
                    }
                }    
                addRow(output.item)
            }
            if(output.type === 'Update'){
                const updateItem = ( ItemUpdated : any ) => {
                    const data_to_update = [ ...customer_cars ] 
                    const updatedItems = data_to_update.map( ( item : any , i : number) => {
                        if (item._id === ItemUpdated._id) {
                            return ItemUpdated
                        } else {
                        return item
                        }
                    })
                    console.log(updatedItems)
                    setCustomerCars(updatedItems)
                } 
                updateItem(output.item)
            }
            if(output.type === 'Delete'){
                const DeleteItem = ( ItemDeleted : any ) => {
                    const data_to_delete = [ ...customer_cars ] 
                    const index = data_to_delete.findIndex(( row : any ) => row._id === ItemDeleted._id);
                    if(index > -1){
                        data_to_delete.splice(index,1)
                        setCustomerCars(data_to_delete)
                    }
                } 
                DeleteItem(output.item)
            }
        }
    })
    
    },[socket , data , customer , setCustomer, customer_cars])

    useEffect(() => {
        const f_fetch_data = async () => {
        setLoading(true);
        await delay(0)
        const params = f_encode_query_data(filter)
        const res = await f_fetch('/customers/filtered?'+params , 'GET' , true , null)
        if(res.type === 'Success'){
            setData(res.data.customers)
            setCount(res.data.count)
        }
        if(res.type === 'Unauthorized') HandleLogout(nav);
        setLoading(false);
        }
        f_fetch_data();
    }, [ nav , HandleLogout , filter]);
    const handleSearch = (e :ChangeEvent<HTMLInputElement>) => {
        const value = e.target.value
        setSearch(value)
    }
    const handleRowsPerPage = async( value : number) => {
        const new_filter : i_customer_search_filter = { ...filter}
        new_filter.rows_per_page = value
        new_filter.page = 1
        setFilter(new_filter)
    }
    const FetchCars = async( row : i_customer ) => {
        setLoadingCars(true)
        const res = await f_fetch('/customer/cars/'+row._id, 'GET', true, null)
        if(res.type === 'Success') setCustomerCars(res.data)
        setLoadingCars(false)
    }
    const handleSubmitCallback = async(type : 'contact' | undefined) : Promise<void | null> => {
        var method : t_method = 'POST'
        if(customer._id !== '') method = 'PUT'
        const formated_customer = { ...customer }
        if(type === 'contact'){
            formated_customer.contact_array = prev_customer.contact_array
        }
        const [ valid , error , field ] = m_validate_customer(formated_customer , method)
        if(valid){
        if(method === 'POST'){
            if(uploads.length !== 2 && customer.complete){
                setEuploads('Please upload front and rear images of licences')
                return null
            }
        }
        if(method === 'PUT'){
            if(assets.length + uploads.length !== 2 && customer.complete) {
            setEuploads('Please upload front and rear images of licences')
            return null
            }
        }
        const form_data = f_make_customer_fd(formated_customer , method , uploads, assets)
        const res = await f_fetch_multiform('/customer', method, form_data)
        if(res.type === 'Success'){
            if(type === 'contact'){
                setCustomer({...prev_customer})
            }
            if(open){
                setOpen(false)
            }else{
                handleTarget(empty_customer)
                setDisplay('table')
            }
        }
        setApiError({ open : true, promise : res})
        }else{
            setCustomerErrors({...customer_errors , [field] : error}) 
        }
    }
    const handleAction = async() => {
        setDisableLoading(true)
        await delay(500)
        var method : t_method = 'PATCH'
        var endpoint = '/disable/'
        if(modal_type === 'delete') {
        endpoint = '/'
        method = 'DELETE'
        }
        const res = await f_fetch('/customer' + endpoint + customer._id, method , true, null)
        setApiError({ open : true, promise : res})
        if(res.type === 'Success') {
        setOpen(false)
        if(modal_type === 'delete') {
            setDisplay('table')
        }
        }
        setDisableLoading(false)
    }
    const handleTarget = ( row : i_customer) : void => {
        setCustomer({...row , licence : { ...row.licence, expiration_date : row.licence.expiration_date * 1000 , delivery_date : row.licence.delivery_date * 1000 , birth_date : row.licence.birth_date * 1000  }})
        setAssets(row.licence?.path ? row.licence.path : [])
        setUploads([])
    }
    const handleShowCallback = async( row : i_customer ) => {
        handleTarget(row)
        setCustomer(row)
        setDisplay('view')
        await FetchCars(row)
    }
    const handleDisableCallback = async( row : i_customer ) => {
        handleTarget(row)
        row.disabled === true ? setModalType('enable') : setModalType('disable')
        setOpen(true)
    }
    const handleEditCallback = ( row : i_customer ) => {
        handleTarget(row)
        setDisplay('form')
    }
    const handleAdd = ( value : t_display ) => {
        handleTarget(empty_customer)
        handleDisplay(value)
    }
    const handleDisplay = ( value : t_display) => setDisplay(value)
    const modal_events : t_transition_event[] = ['enable', 'disable', 'delete' , 'portal_invite' , 'edit_profile' , 'add_contact' , 'edit_address' , 'edit_other_details']
    const handleTransition = ( value : t_transition_event) => {
        if(modal_events.includes(value)){
            setPrevCustomer(customer)
            setModalType(value)
            setOpen(true)
        }
    }
    const handleModalAction = () => {
        if(modal_type === 'disable' || modal_type === 'enable' || modal_type === 'delete' ){
            handleAction()
        }
    }
    useEffect(() => {
        const F = customer
        const E = customer_errors;
        if(E.customer_type !== '' && Number(F.customer_type)) setCustomerErrors({ ...E , customer_type : ''})
        if(E.title !== '' && Number(F.title)) setCustomerErrors({ ...E , title : ''})
        if(E.first_name !== '' && m_force_str(F.first_name) === '') setCustomerErrors({ ...E , first_name : ''})
        if(E.middle_name !== '' && m_validate_str(F.middle_name)) setCustomerErrors({ ...E , middle_name : ''})
        if(E.last_name !== '' && m_force_str(F.last_name) === '') setCustomerErrors({ ...E , last_name : ''})
        if(E.company_name !== '' && m_validate_str(F.company_name)) setCustomerErrors({ ...E , company_name : ''})
        if(E.email !== '' && m_validate_email(F.email)) setCustomerErrors({ ...E , email : ''})
        if(E.phone_one !== '' && m_validate_phone(F.phone_one)) setCustomerErrors({ ...E , phone_one : ''})
        if(E.phone_two !== '' && m_validate_phone(F.phone_two)) setCustomerErrors({ ...E , phone_two : ''})
        if(E.tax_preference !== '' && m_validate_tax_type(F.tax_preference)) setCustomerErrors({ ...E , tax_preference : ''})
        if(E.tax_rate !== '' && m_validate_tax_rates(F.tax_rate)) setCustomerErrors({ ...E , tax_rate : ''})
        if(E.currency !== '' && m_validate_currency(F.currency)) setCustomerErrors({ ...E , currency : ''})
        if(E.payment_terms !== '' && m_validate_payment_condition(F.payment_terms)) setCustomerErrors({ ...E , payment_terms : ''})
        if(E.portal_language !== '' && m_validate_language(F.portal_language)) setCustomerErrors({ ...E , portal_language : ''})
        if(E.attendance !== '' && m_force_str(F.attendance) === '') setCustomerErrors({ ...E , attendance : ''})
        if(E.unit_number !== '' && m_validate_str(F.unit_number)) setCustomerErrors({ ...E , unit_number : ''})
        if(E.door_number !== '' && m_force_str(F.door_number) === '') setCustomerErrors({ ...E , door_number : ''})
        if(E.street !== '' && m_force_str(F.street) === '') setCustomerErrors({ ...E , street : ''})
        if(E.city !== '' && m_force_str(F.city) === '') setCustomerErrors({ ...E , city : ''})
        if(E.state !== '' && m_force_str(F.state) === '') setCustomerErrors({ ...E , state : ''})
        if(E.zip !== '' && ( F.complete ? m_validate_zip_code(F.zip) : m_validate_opt_zip_code(F.zip) )) setCustomerErrors({ ...E , zip : ''})
        if(E.contact_array && E.contact_array.length > 0) setCustomerErrors({ ...E , contact_array : ''})
        if(E.licence.licence_number !== '' && m_force_str(F.licence.licence_number) === '') setCustomerErrors({ ...E , licence  : {  ...E.licence, licence_number :'' }})
        if(E.licence.reference_number !== '' && m_force_str(F.licence.reference_number) === '') setCustomerErrors({ ...E , licence  : {  ...E.licence, reference_number :'' }})
        if(E.licence.expiration_date !== '' && m_validate_date(F.licence.expiration_date)) setCustomerErrors({ ...E , licence  : {  ...E.licence, expiration_date :'' }})
        if(E.licence.delivery_date !== '' && m_validate_date(F.licence.delivery_date)) setCustomerErrors({ ...E , licence  : {  ...E.licence, delivery_date :'' }})
        if(E.licence.birth_date !== '' && m_validate_date(F.licence.birth_date)) setCustomerErrors({ ...E , licence  : {  ...E.licence, birth_date :'' }})
        if(E.notes !== '' && m_validate_str(F.notes)) setCustomerErrors({ ...E , notes : ''})
    },[customer , customer_errors])
    const f_render_title = () : string => {
        var title : string = ''
        modal_type === 'add_contact' ? title = 'Add new person to contact' : modal_type === 'edit_profile' ? title = 'Edit ' + customer.first_name + ' ' + customer.last_name + ' profile' :
        modal_type === 'edit_address' ? title = 'Edit address' : modal_type === 'edit_other_details' ? title = 'Edit settings' : title = 'to be defined..'
        return title
    }
    const handleRow = (row : i_customer , type : t_display) => {
        if(type === "disable") handleDisableCallback(row)
        if(type === "view") handleShowCallback(row)
        if(type === "form") handleEditCallback(row)
    }
    const licence_uploads_props : i_file_uploads_props = {
        uploads : uploads,
        setUploads : setUploads,
        assets : assets,
        setAssets : setAssets,
        Euploads : Euploads,
        setEuploads : setEuploads,
        type : 'form',
        path : '/webapi/uploads/licence/',
        title : 'License images ( back and front ) *',
        setApiError : setApiError,
        max : 2
    }
    const customer_form_props : i_customer_form_props = {
        form : customer,
        prev_form : prev_customer,
        setForm : setCustomer,
        setPrevForm : setPrevCustomer,
        loading : loading,
        errors : customer_errors,
        files : licence_uploads_props,
        callback : handleSubmitCallback,
        dev : dev,
        mode : mode,
        setDisplay : setDisplay
    }
    const pagination_props : i_pagination_new = {
        filter : filter,
        onPageChange: (event: React.ChangeEvent<unknown> , page : number) => {
        const new_filter = { ...filter }
        new_filter.page = page
        setFilter(new_filter)
        },
        count : count,
        handleRowsPerPage : handleRowsPerPage,
        title : 'Customers'
    }
    const search_input_props : i_basic_search_props = {
        search : search,
        handleSearch : handleSearch,
        placeholder : 'Search by name or email',
        loading : loading,
        callback : () => setFilter({...filter, search : search}),
        error_msg : ''
    }
    const table_tool_bar_props : i_top_table_bar_props = {
        title : 'Customer',
        callback : () => handleAdd('form'),
        inputs : [<SearchFilter {...search_input_props} />]
    }

    const alert_props : i_alert_props = {
        event : api_error,
        handleClose : () => setApiError({ open : false, promise : empty_promise }),
        type : 'simple'
    }


    const modal_prompt_props : i_prompt_modal_props = {
        open : open,
        handleClose : () => setOpen(false),
        title : customer.first_name + ' ' + customer.last_name,
        mode : mode,
        callback : handleModalAction,
        loading : disable_loading,
        type : modal_type
    }
    const job_headers : i_tbl_header[] = [
        { value : "Name" , css : header_format , portion : 2,  },
        { value : "Email" , css : header_format , portion : 2 , mt : 0.8 },
        { value : "Phone" , css : header_format , portion : 2, mt : 0.8  },
        { value : "Customer type" , css : header_format , portion : 1.5, mt : 0.8 },
        { value : "Last interaction" , css : header_format , portion : 1.5 , mt : 0.8 },
        { value : "Type" , css : header_format , portion : 1.5 , mt : 0.8 },
        { value : "Actions" , css : header_format , portion : 1.5, mt : 0.8 ,icon: (
            <IconButton><Box component={'img'} src={'./images/filter_lines.svg'}/></IconButton>
        ) },
    ]
    const table_v2_props : i_table_v2_props = {
        data : data,
        title : 'Please start by adding a new customer',
        loading : loading,
        headers : job_headers,
        callback : handleRow,
        setApiError : setApiError,
        row_model : "customer",
        pagination : pagination_props
    }
    const fragmented_body_props : i_fragmented_body = {
        form : customer_form_props,
        modal_type : modal_type,
    }
    const shell_modal_props : i_shell_modal = {
        open : open,
        children : <CustomerFragmentedBody {...fragmented_body_props}/> ,
        handleClose : () => setOpen(false),
        title : f_render_title(),
        mode : mode
    }
    const top_table_props = {
        total : count,
        total_month : data.length,
        global_rating : 4.5,
        percent_month : 22,
        total_ratings : 522,
        percent_total : 15,
        high_rating : 201,
        mid_rating : 120,
        low_rating : 221,
        title : 'customers'
    }
    const customer_view_props : i_customer_view_props = {
        form : customer,
        prev_form : prev_customer,
        loading : loading,
        errors : customer_errors,
        target : customer,
        mode : mode,
        handleDisplay : handleDisplay,
        handleTransition : handleTransition,
        customer_cars : customer_cars,
        loading_cars : loading_cars,
        dev : dev,
        setError : (value : i_snack_alert) => setApiError(value),
        socket : socket,
    }
  return (
    <Box sx={{ }}>
      <Box sx={{ minHeight : props.mobile ? '85vh' : '91.5vh' ,  }}>
      <>
        {(display === 'form' || display === 'table') &&
                <Box >
                <Box sx={{ margin : '0vh'}}>
                    {display === "table"  && 
                    <>
                        <Box sx={{ }}>
                        <CustomerTop {...top_table_props}/>
                        <Box sx={{ }}>
                            <TopTableBar {...table_tool_bar_props}/>
                            { loading ? 
                            <Box>
                                <CircularUnderLoad type={"table"}/>
                            </Box>
                            :
                            <Box sx={tbl_boxing}>
                                <TblV2 {...table_v2_props}/>
                            </Box>
                            }
                        </Box>
                        </Box>
                    </>
                    }
                    {display === 'form' && 
                    <Box>
                        <CustomerInputsV2 {...customer_form_props}/>
                    </Box>
                    }
                </Box>
                </Box>
        } 
       <>
        {display === 'view' && 
          <CustomerView {...customer_view_props} />
        }
        </>
        
        </>

        <AlertDialog {...alert_props} />
        <Modal open={open} onClose={() => setOpen(false)} children={
            (modal_type === 'delete' || modal_type === 'enable' || modal_type === 'disable') ? <ModalPromptBody {...modal_prompt_props}/>
        : <ShellModal {...shell_modal_props}/>}></Modal>
        </Box>
        {display !== 'view' &&
            <Footer type={'center'}/>
        }
    </Box>
  );
}

export default Customers;
