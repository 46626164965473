
//Main planner
const P_1 = {
    "Events Planner" : { EN : "Garage events management" , FR : "Planificateur d'évenement du garage" },
    "Next" : { EN : "Next", FR : "Suivant" },
    "Back" : { EN : "Back", FR : "Précédent" },
    "Today" : { EN : "Today", FR : "Aujourd'hui" },
    "Month" : { EN : "Month", FR : "Mois" },
    "Week" : { EN : "Week", FR : "Semaine" },
    "Day" : { EN : "Day", FR : "Jour" },
    "Hour" : { EN : "Hour", FR : "Heure" },
    "Minute" : { EN : "Minute", FR : "Minute" },
    "Time" : { EN : "Time", FR : "Heure" },
    "Event" : { EN : "Event", FR : "Événement"}
   }

//Planner inputs
   const Pi_1 = {
    "Title" : { EN : "Title" , FR : "Titre" },
    "Description" : { EN : "Description", FR : "Description" },
    "Start date" : { EN : "Start date", FR : "Date de début"},
    "End date" : { EN : "End date", FR : "Date de fin" },
    "Type" : { EN : "Type", FR : "Type" },
    "Color" : { EN : "Color", FR : "Couleur" },
    "User" : { EN : "Select a user", FR : "Selectioné un utilisateur" },
    "Delete" : { EN : "Delete", FR : "Supprimer" },
}
export { P_1 , Pi_1 }