


/*
 * Date: 2024
 * Description: Custom input for number takes
 * Author: Philippe Leroux @ Skitsc
 */


//Modules
import { ChangeEvent } from "react"
import { TextField } from "@mui/material"

interface i_numeric_input_props {
    value : any,
    handleChange : ( event : number) => void,
    name : string,
    placeholder : string,
    error : boolean,
    helperText : string,
    sx : any,
    size : 'small' |'medium' 
    autoComplete : 'new-password' | 'off'
    InputProps ?: any
    decimal ?: boolean
}

const NumericTextField = ( props : i_numeric_input_props ) => {
    const handleField = (event: ChangeEvent<HTMLInputElement>) => {
        let newValue = event.target.value;
        // If decimal prop is not set, restrict input to integers only
        if (!props.decimal) {
            newValue = newValue.replace(/[^\d]/g, '');
        }
        // Ensure the input starts with a digit or a decimal point
        newValue = newValue.replace(/^[^\d.]+/, '');
        // Ensure only one decimal point is allowed
        newValue = newValue.replace(/(\..*)\./g, '$1');
        // Update state with the new value
        props.handleChange(newValue === '' ? 0 : parseFloat(newValue));
    };
    const {decimal, handleChange, ...restProps } = props;
    const new_props = { ...restProps };
    return (
        <TextField type={'text'} {...new_props} onChange={(e : ChangeEvent<HTMLInputElement>) => handleField(e)} />
    )
} 

export default NumericTextField