


/*
 * Date: 2023-08-15
 * Description: scroll to top button , return to max scroll click
 * Author: Philippe Leroux @ Skitsc
 */

//Modules
import { useState , useEffect }from 'react';
import { IconButton , Tooltip , Box } from '@mui/material';

//Icons
import ArrowUpwardIcon from '@mui/icons-material/ArrowUpward';

const ScrollToTop = () => {
    const [isVisible, setIsVisible] = useState(false);
  useEffect(() => {
    const handleScroll = () => {
      const scrollContainer = document.getElementById('back-to-top-anchor');
      if (scrollContainer && scrollContainer.scrollTop > 300) {
        setIsVisible(true);
      } else {
        setIsVisible(false);
      }
    };
    const scrollContainer = document.getElementById('back-to-top-anchor');
    scrollContainer?.addEventListener('scroll', handleScroll);
    return () => {
      scrollContainer?.removeEventListener('scroll', handleScroll);
    };
  }, []);
    const handleClick = () => {
        const scrollContainer = document.getElementById('back-to-top-anchor');
        if (scrollContainer) {
          scrollContainer.scrollTo({ top: 0, behavior: 'smooth' });
        }
    }
  return (
    <>
        {isVisible &&
        <Box sx={{ position : 'fixed' , bottom : '16px' , right : '16px' , zIndex : 1000}}>
            <Tooltip title="Back to top" aria-label="scroll back to top">
                <IconButton color={'primary'} sx={{ border : '1px solid gray'}}onClick={() => handleClick()}>
                    <ArrowUpwardIcon sx={{ fontSize : 40}}/>
                </IconButton>
            </Tooltip>
        </Box>
        }
    </>
  );
}

export default ScrollToTop;
