
/*
 * Date: 2024
 * Description: top bar for task
 * Author: Philippe Leroux @ Skitsc
 */
//Modules
import { Box , Tooltip , IconButton } from "@mui/material"

//Interfaces && types
import { i_top_bar_job_view_props } from "../../../interfaces/jobs.interface"
//Icons
import CloseSharpIcon from '@mui/icons-material/CloseSharp';


const TopTaskBar = ( props : i_top_bar_job_view_props) => {
    return (
        <>
            <Box sx={{ alignSelf: 'center' , marginRight : '8px' , fontWeight : 800}}> No job :</Box>
            <Box sx={{ alignSelf : 'center' }}> {props.job.order_number} </Box>
            <Box sx={{ marginLeft : '12px', alignSelf: 'center' , marginRight : '8px' , fontWeight : 800}}>Customer :</Box>
            <Box sx={{alignSelf : 'center' }}> {props.job.customer.first_name + ' ' + props.job.customer.last_name} </Box>
            <Box sx={{ marginLeft : '12px' , alignSelf: 'center' , marginRight : '8px' , fontWeight : 800}}>Car :</Box>                      
            <Box  sx={{alignSelf : 'center' }}> {props.job.car.make + " " + props.job.car.model + " " + props.job.car.trim + " " + props.job.car.year} </Box>
            <Box sx={{ marginLeft : 'auto'}}>
                <Tooltip title={'Close'}>
                    <IconButton onClick={() => props.onClose('table')}><CloseSharpIcon/></IconButton>
                </Tooltip>
            </Box>
        </>
    )
}

export default TopTaskBar