


const header_row = { textAlign : 'center'}

const header_format = {
    fontSize : 14,
    fontWeight : 600 , 
    alignSelf : 'center', 
}
const tbl_boxing = { padding:'5px' , marginTop : '1vh' , height : 'auto' }
export { header_row , header_format , tbl_boxing }