/*
 * Date: 2024
 * Description: Insurance view
 * Author: Philippe Leroux @ skitsc
 */

//Modules
import { Box , Grid , Typography } from "@mui/material"

//Styles
import { bottom_line, sub_title } from "../../../styles/form.styles"
import { f_timestamp_to_date } from "../../../utils/utility";
import { i_registration } from "../../../interfaces/customer.interface";

interface i_registration_view_props {
    registration : i_registration
}
const RegistrationsView = ( { registration } : i_registration_view_props) => {
    const path = process.env.REACT_APP_API_URL + '/webapi/uploads/registration/'
    return (
        <Box>
            <Grid container>
                <Grid item xs={4}>
                    <Typography sx={sub_title}>Registration number</Typography>
                </Grid>
                <Grid item xs={8}>
                    <Box sx={{}}>{registration.registration_number}</Box>
                </Grid>
                <Grid item xs={12} sx={{ marginBottom : '1vh'}}> <hr style={bottom_line}/></Grid>
                <Grid item xs={4}>
                    <Typography sx={sub_title}>No certificate*</Typography>
                </Grid>
                <Grid item xs={8}>              
                <Box sx={{}}>{registration.no_cert}</Box>
                </Grid>
                <Grid item xs={12} sx={{ marginBottom : '1vh'}}> <hr style={bottom_line}/></Grid>
                <Grid item xs={4}>
                    <Typography sx={sub_title}>Period from to *</Typography>
                </Grid>
                <Grid item xs={8}>
                <Box sx={{}}>{f_timestamp_to_date(registration.delivered , 'long')}</Box>
                <Box sx={{}}>{f_timestamp_to_date(registration.expires , 'long')}</Box>
                </Grid>
                <Grid item xs={12} sx={{ marginBottom : '1vh'}}> <hr style={bottom_line}/></Grid>
                <Grid item xs={4}>
                    <Typography sx={sub_title}>Insurance proofs</Typography>
                </Grid>
                <Grid item xs={8}> 
                    {registration.path !== undefined && registration.path.length > 0 ? 

                        <Box>
                            { registration.path.map((item : string, index : number) => (
                                <Box key={index}>
                                    <Box component={'img'} alt={'Registration image '}  sx={{ maxWidth: 200, maxHeight: 100, display: 'block', width: 'auto', height: 'auto', }} src={ path + item }/> 
                                </Box>
                            ))}
                           
                        </Box>     
                        :
                        <Box>No assets found..</Box>
                    }
                </Grid>
            </Grid>
        </Box>
    )
}

export default RegistrationsView