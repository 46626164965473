// Date : 2023
// Author: Philippe Leroux @ Skitsc
// Description : Page where all interaction with user can be manipulated..


//Modules
import { Paper , Grid , Typography , IconButton, Box, Accordion , AccordionDetails , AccordionSummary , Menu , MenuItem } from "@mui/material"
import { useState , useEffect } from "react";

//Components
import CustomerViewSubMenu from "./subcontent/sub.menu";
import CustomerCars from "./customer.cars";
//Interfaces && types
import { i_customer_view_props, t_customer_sub_views , i_customer } from "../../interfaces/customer.interface";
import { t_transition_event } from "../../types/types";
//Icons
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import AddIcon from '@mui/icons-material/Add';
import EditIcon from '@mui/icons-material/Edit';
import MoreVertIcon from '@mui/icons-material/MoreVert';
//Utils
import { f_format_phone_cute } from "../../utils/utility";



const CustomerView = ( props : i_customer_view_props ) => {
    const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
    const open = Boolean(anchorEl);
    const handleClick = (event: React.MouseEvent<HTMLElement>) => {
      setAnchorEl(event.currentTarget);
    };
    const handleClose = () => {
      setAnchorEl(null);
    };
    const handleAction = (e : t_transition_event) => {
        setAnchorEl(null);
        props.handleTransition(e)
    }
    const F = props.form
    const [ sub_menu , setSubMenu ] = useState<t_customer_sub_views>('cars')
    const [ showScrollContacts, setShowScrollContacts ] = useState(false);
    const [ display_contacts , setDisplayContacts ] = useState<boolean>(true)
    const [ display_address , setDisplayAddress ] = useState<boolean>(true)
    const [ display_details , setDisplayDetails ] = useState<boolean>(false)

    const sub_menu_props = {
        sub_menu : sub_menu,
        setSubMenu : setSubMenu,
        callback : () => props.handleDisplay('table')
    }
    const details_rows = {
        marginBottom : '0.6vh'
    }   
    const flex_end = { display : 'flex' , justifyContent : 'flex-end'}
    const T : i_customer = props.target
    useEffect(() => {
        const boxElement = document.getElementById('_contacts'); 
        if (boxElement)  setShowScrollContacts(boxElement.scrollHeight > boxElement.clientHeight);
    }, [T.contact_array]); 

    return (
        <Paper elevation={6} sx={{}}>
        <CustomerViewSubMenu {...sub_menu_props} />
        <Grid container sx={{ height : '90.2vh' , backgroundColor : props.mode === 'Dark' ? '#222' : '#fff' }}>
            <Grid item xs={5.5} sm={5.5} md={5.5} lg={3.5} xl={2.5} sx={{ borderRight : '1px solid #EAECF0' , borderBottom : '1px solid #EAECF0' , borderTop : '1px solid #EAECF0'}}> 
                <Box>
                    <Grid container sx={{ padding : '1.5%'}}>
                        <Grid item xs={1.5} sx={{ display : 'flex', justifyContent : 'center'}}>
                            <Box component={'img'} src={'./images/vador-icon.png'} sx={{ maxWidth: '35px', width: '100%', height: '35px' , marginTop : '0.5vh' , marginRight : '0.5vh'}}>

                            </Box>
                        </Grid>
                        <Grid item xs={8} sx={{ }}>
                            <Box sx={{ paddingLeft: '0.5vh'}}>
                                <Typography variant="h6" sx={{ fontWeight : 700, fontSize : 16 , display : 'block' , marginBottom : '0.5vh' }}>
                                    {T.first_name + ' ' + T.middle_name + ' '+ T.last_name}
                                </Typography>
                                <Typography variant="h6" sx={{ fontWeight : 600, fontSize : 12 , display : 'block' , marginBottom : '0.5vh'}}>
                                    {T.email}
                                </Typography>
                            </Box>
                        </Grid>
                        <Grid item xs={1.5} sx={{ marginLeft : 'auto' }}>
                                <IconButton  aria-label="more"id="long-button"aria-controls={open ? 'long-menu' : undefined}aria-expanded={open ? 'true' : undefined}aria-haspopup="true" onClick={handleClick}>
                                    <MoreVertIcon/>
                                </IconButton>
                                <Menu id="long-menu" MenuListProps={{'aria-labelledby': 'long-button',}} anchorEl={anchorEl} open={open} onClose={handleClose}>
                                    <MenuItem key={'1'} onClick={() => handleAction('edit_profile')}>
                                        Edit
                                    </MenuItem>
                                    <MenuItem key={'2'} onClick={() => handleAction('email')}>
                                        Send email
                                    </MenuItem>
                                    <MenuItem key={'3'} onClick={() => handleAction('portal_invite')}>
                                        Invite to portal
                                    </MenuItem>
                                    <MenuItem key={'4'} onClick={() => handleAction('delete')}>
                                        Delete
                                    </MenuItem>
                                </Menu>
                        </Grid>
                        
                    </Grid>
                </Box>
                <Grid container>
                    <Grid item xs={12}>
                        <Accordion expanded={display_address} onChange={() => setDisplayAddress(display_address ? false : true)}>
                            <AccordionSummary expandIcon={<ExpandMoreIcon />} aria-controls="panel-address" id="panel-address" >
                                <Typography sx={{ fontWeight : 700, fontSize : 16, display : 'block' , alignSelf : 'center'}}>Address</Typography>
                                <IconButton   onClick={(event) => { props.handleTransition('edit_address'); event.stopPropagation();}}sx={{ marginLeft : 'auto' , fontSize : 12}}>
                                    <EditIcon color="primary"/>
                                </IconButton>
                            </AccordionSummary>
                            <AccordionDetails>
                                <Box sx={{ display: 'flex', flexDirection: 'row', flexWrap: 'wrap' }}>
                                        <Typography variant="h6" sx={{ fontWeight : 500, fontSize : 16 , color : 'gray'}}>
                                            {T.unit_number !== '' ? T.unit_number + '-' + T.door_number + ' ' +T.street : T.door_number + ' ' +T.street +',' }
                                        </Typography>
                                        <Typography variant="h6" sx={{ fontWeight : 500, fontSize : 16 , color : 'gray' , paddingLeft : '6px'}}>
                                            {T.attendance} ,
                                        </Typography>
                                        <Typography variant="h6" sx={{ fontWeight : 500, fontSize : 16 , color : 'gray' , paddingLeft : '6px'}}>
                                            {T.city} ,
                                        </Typography>
                                        <Typography variant="h6" sx={{ fontWeight : 500, fontSize : 16 , color : 'gray' , paddingLeft : '6px'}}>
                                            {T.state } ,
                                        </Typography>
                                        <Typography variant="h6" sx={{ fontWeight : 500, fontSize : 16 , color : 'gray' , paddingLeft : '6px'}}>
                                            {T.zip}
                                        </Typography>
                                </Box>
                            </AccordionDetails>
                        </Accordion>
                    </Grid>
                    <Grid item xs={12}>
                    <Accordion expanded={display_details} onChange={() => setDisplayDetails(display_details ? false : true)}>
                            <AccordionSummary expandIcon={<ExpandMoreIcon />} aria-controls="panel-details" id="panel-details" >
                                <Typography sx={{ fontWeight : 700, fontSize : 16, display : 'block' , alignSelf : 'center'}}>Other details</Typography>
                                <IconButton   onClick={(event) => { props.handleTransition('edit_other_details'); event.stopPropagation();}}sx={{ marginLeft : 'auto' , fontSize : 12}}>
                                    <EditIcon color="primary"/>
                                </IconButton>
                            </AccordionSummary>
                            <AccordionDetails>
                                <Grid container>
                                    <Grid item xs={6} sx={details_rows}>
                                        <Typography>Customer type</Typography>
                                    </Grid>
                                    <Grid item xs={6} sx={[ flex_end]}>
                                        <Typography>{F.customer_type}</Typography>
                                    </Grid>
                                    <Grid item xs={6} sx={details_rows}>
                                        <Typography>Currency</Typography>
                                    </Grid>
                                    <Grid item xs={6} sx={[ flex_end]}>
                                        <Typography>{F.currency}</Typography>
                                    </Grid>
                                    <Grid item xs={6} sx={details_rows}>
                                        <Typography>Payment method</Typography>
                                    </Grid>
                                    <Grid item xs={6} sx={[flex_end]}>
                                        <Typography>{F.payment_terms}</Typography>
                                    </Grid>
                                    <Grid item xs={6} sx={details_rows}>
                                        <Typography>Taxation</Typography>
                                    </Grid>
                                    <Grid item xs={6} sx={[flex_end]}>
                                        <Typography>{F.tax_preference}</Typography>
                                    </Grid>
                                    <Grid item xs={6} sx={details_rows}>
                                        <Typography>Welcome email sent</Typography>
                                    </Grid>
                                    <Grid item xs={6} sx={[flex_end]}>
                                        {F.welcome_email ? <Typography sx={{ color : 'lime'}}>Active</Typography> : <Typography sx={{ color : 'red'}}>Off</Typography>}
                                    </Grid>
                                    <Grid item xs={6} sx={details_rows}>
                                        <Typography>Portal language</Typography>
                                    </Grid>
                                    <Grid item xs={6} sx={[flex_end]}>
                                        <Typography>{F.portal_language}</Typography>
                                    </Grid>
                                </Grid>
                            </AccordionDetails>
                        </Accordion>
                    </Grid>
                    <Grid item xs={12}>
                        <Accordion expanded={display_contacts} onChange={() => setDisplayContacts(display_contacts ? false : true)}>
                            <AccordionSummary expandIcon={<ExpandMoreIcon />} aria-controls="panel-contacts" id="panel-contacts">
                                <Typography sx={{ fontWeight : 700, fontSize : 16, display : 'block' , alignSelf : 'center'}}> Person to contact { T.contact_array.length > 0 && '(' + T.contact_array.length + ')'}</Typography>
                                <IconButton   onClick={(event) => { props.handleTransition('add_contact'); event.stopPropagation();}}sx={{ marginLeft : 'auto' , fontSize : 12}}>
                                    <AddIcon color={'primary'}/>
                                </IconButton>
                            </AccordionSummary>
                            <AccordionDetails>
                                <>
                                    {T.contact_array.length > 0 ? 
                                        <Box sx={{ maxHeight : '23vh' , overflowY :  showScrollContacts ? 'auto' : 'hidden'}} id={'_contacts'}>
                                            {T.contact_array.map((contact : any, index : number) => (
                                                
                                                    <Grid container key={index} pr={2}>
                                                        <Grid item xs={6} sx={details_rows}>
                                                            <Typography sx={{ display : 'flex' , justifyContent : 'flex-start'}}>Name</Typography>
                                                        </Grid>
                                                        <Grid item xs={6} sx={details_rows}>
                                                            <Typography sx={{ display : 'flex' , justifyContent : 'flex-end' , fontSize : '14px'}}>{contact.first_name + ' ' + contact.last_name}</Typography>
                                                        </Grid>
                                                        <Grid item xs={6} sx={details_rows}>
                                                            <Typography sx={{ display : 'flex' , justifyContent : 'flex-start'}}>Relationship</Typography>
                                                        </Grid>
                                                        <Grid item xs={6} sx={details_rows}>
                                                            <Typography sx={{ display : 'flex' , justifyContent : 'flex-end'  , fontSize : '14px'}}>{contact.designation}</Typography>
                                                        </Grid>
                                                        <Grid item xs={6} sx={details_rows}>
                                                            <Typography sx={{ display : 'flex' , justifyContent : 'flex-start'}}>Email</Typography>
                                                        </Grid>
                                                        <Grid item xs={6} sx={details_rows}>
                                                            <Typography sx={{ display : 'flex' , justifyContent : 'flex-end'  , fontSize : '14px'}}>{contact.email}</Typography>
                                                        </Grid>
                                                        <Grid item xs={6} sx={details_rows}>
                                                            <Typography sx={{ display : 'flex' , justifyContent : 'flex-start'}}>Phone number :</Typography>
                                                        </Grid>
                                                        <Grid item xs={6} sx={details_rows}>
                                                            <Typography sx={{ display : 'flex' , justifyContent : 'flex-end'  , fontSize : '14px'}}>{f_format_phone_cute(contact.phone)}</Typography>
                                                        </Grid>
                                                        {contact.mobile_phone !== '' &&
                                                            <>
                                                                <Grid item xs={6} sx={details_rows}>
                                                                    <Typography sx={{ display : 'flex' , justifyContent : 'flex-start'}}>Secondary number :</Typography>
                                                                </Grid>
                                                                <Grid item xs={6} sx={details_rows}>
                                                                    <Typography sx={{ display : 'flex' , justifyContent : 'flex-end'  , fontSize : '14px'}}>{f_format_phone_cute(contact.mobile_phone)}</Typography>
                                                                </Grid>
                                                            </>
                                                        }
                                                        <Grid item xs={12}> <hr style={{ color : 'yellow'}}></hr></Grid>
                                                    </Grid>
                                           
                                            )) 
                                            }
                                        </Box>
                                        :
                                            <Box sx={{ display : 'flex' , justifyContent : 'center'}}>
                                                No person to contact.
                                            </Box>
                                    }
                                </>
                            </AccordionDetails>
                        </Accordion>
                    </Grid>
                </Grid>
            </Grid>
            <Grid item xs={6.5} sm={6.5} md={6.5} lg={8.5} xl={9.5} sx={{ paddingLeft : '3px'}}>
                <Box sx={{ border : '1px solid #EAECF0'}}>
                    { sub_menu === 'global_view' && 
                        <Box sx={{ maxHeight : '40vh' , overflowY : 'scroll'}}>
                            <CustomerCars {...props} /> 
                        </Box>
                    }
                    { sub_menu === 'cars' && 
                        <CustomerCars {...props} /> 
                    }
                </Box>
            </Grid>
        </Grid>
      </Paper>
    )
}

export default CustomerView;