/*
 * Date: 2024
 * Description: Jobs page
 * Author: Philippe Leroux @ skitsc
 */

//Modules
import { ReactElement , useState , useEffect , useContext , ChangeEvent } from "react";
import { Box , Modal , IconButton } from "@mui/material"
import { useNavigate } from "react-router-dom";

//Interfaces && types
import { i_initial_props , i_file_uploads_props , i_pagination_new,  i_select_search_props } from "../interfaces/utility.interface";
import { i_customer, i_customer_car } from "../interfaces/customer.interface";
import { i_complete_job, i_job, i_job_errors, i_job_search_filter, i_jobs_inputs_props, i_jobs_mid_filter_props, i_jobs_search , t_jobs_search} from "../interfaces/jobs.interface";
import { t_display , t_transition_event } from "../types/types";
import { i_user } from "../interfaces/user.interface";
import { i_socket_response , i_table_v2_props , i_alert_props , i_shell_modal , i_prompt_modal_props , i_fragmented_body , i_snack_alert , i_top_table_bar_props , i_tbl_header } from "../interfaces/utility.interface";
import { i_task_view_props } from "../interfaces/task.interface";

//Components
import JobsTop from "../components/jobs/view/jobs.top.view";
import JobsMidFilter from "../components/jobs/filter/jobs.mid.filter";
import Footer from "../components/utility/footer";
import AlertDialog from "../components/utility/alert";
import ModalPromptBody from "../components/modal/modal.prompt";
import ShellModal from "../components/modal/modal";
import JobFragmentedBody from "../components/modal/body/modal.fragement.job";
import JobsInputs from "../components/jobs/form/jobs.input";
import TaskView from "../components/jobs/task.view";
import SelectFilter from "../components/utility/inputs/select.filter";
import TopTableBar from "../components/utility/top.table.bar";

//Utilitys
import { f_empty_promise, f_encode_query_data , CheckEnv, f_get_local_key, f_set_local_key } from "../utils/utility";
import { empty_job, empty_search_job , job_status, default_job_filter, empty_job_errors } from "../utils/constant";

//Api
import { f_fetch, f_fetch_multiform } from "../api/fetch";


//Context
import { MainContext, ThemeContext } from "../context/context";
import { SocketContext } from "../context/socket.context";

//Middlewares
import { m_force_str, m_validate_str } from "../validation/utility.middleware";
import { m_validate_job } from "../validation/main.middleware";


//Styles 
import { header_format, tbl_boxing } from "../styles/tbl.styles";
import TblV2 from "../components/table/table.v2";


const Jobs = ( props : i_initial_props ) : ReactElement => {
    //Context
    const { mode } = useContext(ThemeContext);
    const { HandleLogout } = useContext(MainContext)
    const socket = useContext(SocketContext);
    const config = f_get_local_key('config') 
    const nav = useNavigate();
    //Main states
    const [ display , setDisplay ] = useState<t_display>('table')
    const [ loading , setLoading ] = useState<boolean>(false)
    const [ disable_loading ] = useState<boolean>(false)
    const [ data, setData ] = useState<i_complete_job[]>([])
    const [ count , setCount ] = useState<number>(0)
    const [ customers , setCustomers ] = useState<i_customer[]>([])
    const [ cars , setCars ] = useState<i_customer_car[]>([])
    const [ open , setOpen ] = useState<boolean>(false)
    const [ modal_type ] = useState<t_transition_event>('delete')
    const [ users , setUsers ] = useState<i_user[]>([])
    const [ api_errors , setApiError ] = useState({ open : false, promise : f_empty_promise() })
    const [ acc_filter_open , setAccFilterOpen ] = useState<boolean>(config.filter_job)
    const [ acc_stats_open , setAccStatsOpen ] = useState<boolean>(config.stats_job)
    const [ target , setTarget ] = useState<i_complete_job>(empty_job)
    const [ search , setSearch ] = useState<i_jobs_search>(empty_search_job)
    const [ dev ] = useState<boolean>(CheckEnv);
    const [ filter , setFilter ] = useState<i_job_search_filter>(default_job_filter);

    //Search states
    const [ search_status , setSearchStatus ] = useState<string>('Any')
    const [ search_member , setSearchMember ] = useState<string>('Any')

    //Form states
    const [ job , setJob ] = useState<i_complete_job>(empty_job)
    const [ assets , setAssets ] = useState<any>([])
    const [ uploads , setUploads ] = useState<any>([])

    //Error states
    const [ job_errors , setJobErrors ] = useState<i_job_errors>(empty_job_errors)
    const [ Euploads, setEuploads ] = useState<string>('')
    useEffect(() => {
        socket.removeAllListeners("job");
        socket.on('job', ( output : i_socket_response ) => {
            if(output.type === 'Update'){
                const updateItem = ( ItemUpdated : i_complete_job ) => {
                    const data_to_update = [ ...data ] 
                    const updatedItems = data_to_update.map( ( item : i_complete_job , i : number) => {
                        if (item._id === ItemUpdated._id) {
                            console.log(ItemUpdated)
                            return ItemUpdated
                        } else {
                            return item
                        }
                    })
                    setData(updatedItems)
                } 
                updateItem(output.item)
            }
            if(output.type === 'Add'){
                const addRow = ( ItemAdded : i_complete_job ) => {
                    const data_to_update = [ ...data ]
                    const objectExists = data_to_update.some(( obj : i_complete_job ) => obj._id === ItemAdded._id);
                    if(!objectExists){
                        if(filter.sort_order === 'DESC'){
                            if(search_status === 'Any' && search_member === 'Any'){
                                data_to_update.unshift(ItemAdded)
                            }else{
                                if(search_status === ItemAdded.status && search_member === ItemAdded.user._id){
                                     data_to_update.unshift(ItemAdded)
                                }
                            }
                        }else{
                            if(search_status === 'Any' && search_member === 'Any'){
                                data_to_update.push(ItemAdded)
                            }else{
                                if(search_status === ItemAdded.status && search_member === ItemAdded.user._id){
                                     data_to_update.push(ItemAdded)
                                }
                            }
                        }
                        setData(data_to_update)    
                        setCount(count +1 )
                    }
                } 
                addRow(output.item)
            }
        })
    },[socket , data , count , search_member , search_status , filter.sort_order])
    useEffect(() => {
        setLoading(true)
        const f_get_data = async () => {
            const params = f_encode_query_data(filter)
            const res = await f_fetch('/jobs/filtered?' + params , 'GET', true, null)
            if(res.type === 'Success'){
                setData(res.data.jobs)
                setCount(res.data.count)
            }
            else if(res.type === 'Unauthorized'){
                HandleLogout(nav);
            }else{
                setApiError({ open : true, promise : res})
            }    
        }
        f_get_data()
        setLoading(false)
    },[ filter , nav , HandleLogout ])
    useEffect(() => {
        if (display === "form" && job._id === '') {
          handleTarget(empty_job)
        }
    }, [display , job._id]);
    useEffect(() => {
        setLoading(true)
        const f_get_customers = async () => {
            const res = await f_fetch('/customers' , 'GET', true, null)
            if(res.type === 'Success'){
                setCustomers(res.data)
            }else{
                setApiError({ open : true, promise : res})
            }
        }
        f_get_customers()
        setLoading(false)
    },[ ])
    useEffect(() => {
        const f_get_cars = async () => {
            const res = await f_fetch('/customer/cars/'+job.customer._id, 'GET', true , null)
            if(res.type === 'Success'){
                setCars(res.data)
            }else{
                setApiError({ open : true, promise : res})
            }
        }
        if(job.customer._id!== '') f_get_cars()
    },[job.customer])
    useEffect(() => {
        const f_get_users = async () => {
            const res = await f_fetch('/users', 'GET', true, null)
            if(res.type === 'Success')  setUsers(res.data)
        }
        f_get_users()
    },[])
    useEffect(() => {
        const F = job
        const E = job_errors
        if(E.title !== '' && m_force_str(F.title) === '') setJobErrors({...job_errors, title : ''})
        if(E.user_id !== '' && F.user._id !== '') setJobErrors({...job_errors, user_id : ''})
        if(E.order_number!== '' && m_force_str(F.order_number) === '') setJobErrors({...job_errors, order_number : ''})
        if(E.description!== '' && m_validate_str(F.description)) setJobErrors({...job_errors, description : ''})
        if(E.customer_id !== '' && F.customer._id !== '') setJobErrors({...job_errors, customer_id : ''})
        if(E.car_id !== '' && F.car._id !== '') setJobErrors({...job_errors, car_id : ''})
    },[job , job_errors])
    const handleTarget = (target : i_complete_job) => {
        setJobErrors(empty_job_errors)
        setJob({...empty_job , ...target})
        setAssets(target.path)
    }
    const handleSubmit = async( ) => {
        setLoading(true)
        const form_data : i_job = {
            title : job.title,
            order_number : job.order_number,
            claim_number : job.claim_number,
            description : job.description,
            customer_id : job.customer._id,
            car_id : job.car._id,
            user_id : job.user._id,
            status : 'New',
            sub_status : '',
            path : assets,
        }
        const [ valid , message , errors ] = m_validate_job(form_data)
        if(valid){
            const FD = new FormData()
            FD.append('order_number', form_data.order_number)
            FD.append('title', form_data.title)
            FD.append('description', form_data.description)
            FD.append('status', 'initial')
            FD.append('sub_status', '')
            FD.append('claim_number', form_data.claim_number)
            FD.append('customer_id', form_data.customer_id)
            FD.append('car_id', form_data.car_id)
            FD.append('user_id', form_data.user_id)
            for(let i = 0; i < uploads.length; i++) FD.append('files', uploads[i])
            const res = await f_fetch_multiform('/job' , 'POST' , FD)
            if(res.type === 'Success'){
                handleTarget(empty_job)
                handleDisplay('table')
                setUploads([])
            }
            setApiError({ open : true, promise : res})
        }else{
            setJobErrors({...job_errors, [errors] : message})
        }
        setLoading(false)
    }

    const handleRow = ( row : i_complete_job, view : t_display ) => {
        setTarget(row)
        if(view === 'task') setDisplay(view)
        
    }
    const handleSearch = ( value : any , search_value : t_jobs_search ) => {
        if(search_value === 'reset'){
            setSearch(empty_search_job)
        }else{      
            const new_search = {...search , [search_value] : value }  
            setSearch(new_search)
        }
    }
    const handleDisplay = ( value : t_display) => setDisplay(value)
    const handleRowsPerPage = async( value : number) => {
        const new_filter : i_job_search_filter = { ...filter , rows_per_page : value  , page : 1 }
        setFilter(new_filter)
    }
    const handleModalAction = () => {

    }
    const f_render_title = () : string => {
        var title : string = ''
        title = modal_type === 'edit_profile' ? title = 'Edit ' + target.title + ' profile' : "to be defined"
        return title
    }
    const handleAccordion = ( type : string ) => { 
        const current_cookie = f_get_local_key('config')
        const new_cookie = { ...current_cookie}
        if(type === 'filter') {
            new_cookie.filter_job = acc_filter_open === true ? false : true
            f_set_local_key('config', new_cookie ,  1000 * 60 * 60 * 24)
            setAccFilterOpen(acc_filter_open === true? false : true)
        } 
        if(type === 'stats') {
            new_cookie.stats_job = acc_stats_open === true ? false : true
            f_set_local_key('config', new_cookie ,  1000 * 60 * 60 * 24)
            setAccStatsOpen(acc_stats_open === true? false : true)
        }
    }
    const handleSelect = ( value : ChangeEvent<HTMLInputElement>, type : string ) => {
        if(type === 'job'){
            const new_filter = {...filter }
            new_filter.status = value.target.value
            setSearchStatus(value.target.value)
            setFilter(new_filter)
        }
        if(type === 'team_member'){
            const new_filter = {...filter }
            new_filter.team_member = value.target.value
            setSearchMember(value.target.value)
            setFilter(new_filter)
        }
    }
    const formMembers = () : i_user[] => {
        const members : any[] = []
        var default_value = { value : 'Any', label : 'Any'}
        members.push(default_value)
        for(let i = 0; i < users.length; i++){
            var label = users[i].first_name + ' ' + users[i].last_name
            var value = users[i]._id
            members.push({ label : label, value : value})
        }
        return members
    }
    const pagination_props : i_pagination_new = {
        filter : filter,
        onPageChange: (event: React.ChangeEvent<unknown> , page : number) => {
          const new_filter = { ...filter }
          new_filter.page = page
          setFilter(new_filter)
        },
        count : count,
        handleRowsPerPage : handleRowsPerPage,
        title : 'Jobs'
    }
    const job_headers : i_tbl_header[] = [
        { value : "Job" , css : header_format , portion : 6 ,  icon: (
            <IconButton 
                onClick={() => setFilter({...filter, sort_order: filter.sort_order === 'ASC' ? 'DESC' : 'ASC'})}
            >
                <img 
                    alt="Sort order img" 
                    src={filter.sort_order === 'ASC' ? './images/arrow-up.svg' : './images/arrow-down.svg'}
                />
            </IconButton>
        )},
        { value : "Date" , css : header_format , portion : 1.5 , mt : 0.8 },
        { value : "Status" , css : header_format , portion : 1, mt : 0.8  },
        { value : "Progress" , css : header_format , portion : 1, mt : 0.8 },
        { value : "Assignated to" , css : header_format , portion : 1.5 , mt : 0.8 },
        { value : "Actions" , css : header_format , portion : 1, mt : 0.8  },
    ]
    const table_v2_props : i_table_v2_props = {
        data : data,
        title : 'Please start by adding a new job',
        loading : loading,
        headers : job_headers,
        callback : handleRow,
        setApiError : setApiError,
        row_model : "job",
        pagination : pagination_props
    }
    const top_view_props = {
        acc_open : acc_stats_open,
        handleAcc : handleAccordion,
        turn_over : 2420,
        turn_over_plus : 420,
        turn_over_last_month : '40%',
        today_task_completion : '60%',
        today_task_vs_yesterday : '15%',
        week_task_completion : '45%',
        sastifaction_score : 416,
        high_rating : 500,
        mid_rating : 400,
        low_rating : 200,
        total_ratings : 1100,
        global_rating : 4.5
    }
    const file_uploads : i_file_uploads_props = {
        uploads : uploads,
        setUploads : setUploads,
        assets : assets,
        setAssets: setAssets,
        Euploads : Euploads,
        setEuploads: setEuploads,
        type : 'form',
        path : '/webapi/uploads/jobs/',
        title : 'File uploads',
        setApiError: setApiError,
        max : 20
    }
    const jobs_inputs_props : i_jobs_inputs_props = {
        form : job,
        setForm : setJob,
        errors : job_errors,
        customers : customers,
        cars : cars,
        mode : mode,
        uploads : file_uploads,
        callback : handleSubmit,
        setDisplay : setDisplay,
        loading : loading,
        dev : dev,
        users : users,
    }
    const jobs_filters_props : i_jobs_mid_filter_props = {
        acc_open : acc_filter_open,
        handleAcc : handleAccordion,
        search : search,
        callback : handleSearch
    }

    const alert_props : i_alert_props = {
        event : api_errors,
        handleClose : () => setApiError({ open : false, promise : f_empty_promise() }),
        type : 'simple'
    }
    const modal_prompt_props : i_prompt_modal_props = {
        open : open,
        handleClose : () => setOpen(false),
        title : target.title,
        mode : mode,
        callback : handleModalAction,
        loading : disable_loading,
        type : modal_type
    }
    const fragmented_body_props : i_fragmented_body = {
        form : job,
        modal_type : modal_type,
    }
    const shell_modal_props : i_shell_modal = {
        open : open,
        children : <JobFragmentedBody {...fragmented_body_props} />,
        handleClose : () => setOpen(false),
        title : f_render_title(),
        mode : mode
    }

    const task_props : i_task_view_props = {
        socket : socket,
        target : target,
        setError : (value : i_snack_alert) => setApiError(value),
        mode : mode,
        dev : dev,
        users : users,
        nav : nav,
        config : config,
        onClose : () => setDisplay('table')
    }
    const select_status_props : i_select_search_props = {
        label : 'Filter by status',
        selected : search_status,
        handleSelect : (value :  ChangeEvent<HTMLInputElement>) => handleSelect(value ,'job'),
        data : job_status,
        placeholder : 'Search',
        error_msg : ''
    }
    const select_member_props : i_select_search_props = {
        label : 'Filter by team member',
        selected : search_member,
        handleSelect : (value :  ChangeEvent<HTMLInputElement>) => handleSelect(value ,'team_member'),
        data : formMembers(),
        placeholder : 'Search',
        error_msg : ''
    }
    const table_tool_bar_props : i_top_table_bar_props = {
        title : 'Payrolls',
        callback : () => setDisplay('form'),
        inputs : [<SelectFilter {...select_status_props} /> , <SelectFilter {...select_member_props} />]
    }
    return (
        <Box>
            <Box>
            {display === 'task' &&
            <Box>
            
            </Box>
            }
            {display === 'table' &&
                <Box sx={{ minHeight : '90vh'}}>
                    <JobsTop {...top_view_props} />
                    <JobsMidFilter {...jobs_filters_props}/>
                    <TopTableBar {...table_tool_bar_props} />
                    <Box sx={tbl_boxing}>
                        <TblV2 {...table_v2_props} />
                    </Box>
                </Box>
            }
            {display === 'form' && 
                <Box>
                    <JobsInputs {...jobs_inputs_props} />
                </Box>
            }
            {display === 'task' && 
                <Box>
                    <TaskView {...task_props}></TaskView>
                </Box>
            }
            </Box>
            <AlertDialog {...alert_props} />
                <Modal open={open} onClose={() => setOpen(false)} children={
                    (modal_type === 'delete' || modal_type === 'enable' || modal_type === 'disable') ? <ModalPromptBody {...modal_prompt_props}/>
                : <ShellModal {...shell_modal_props}/>}></Modal>
            <Footer type={'off'}/>
        </Box>
    )
}

export default Jobs