/*
 * Date: 2024
 * Description: Tasks inputs..
 * Author: Philippe Leroux @ Skitsc
 */

//Modules
import { ReactElement, ChangeEvent } from "react";
import { Box, Typography, MenuItem, TextField, Grid , Button , IconButton } from "@mui/material";
import { LoadingButton } from "@mui/lab";
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider , DateTimePicker } from '@mui/x-date-pickers';
import dayjs from "dayjs";

//Components
import UploadsInputs from "../../utility/upload.box";
import NumericTextField from "../../utility/inputs/numeric.inputs";

//Interfaces && types
import { i_user } from "../../../interfaces/user.interface";
import { i_task_inputs_props } from "../../../interfaces/task.interface";
import { Dayjs } from "dayjs";

//Styles
import { bottom_line, input_base, sub_title } from "../../../styles/form.styles";

//Constants
import { empty_user, task_status, task_types } from "../../../utils/constant";

//Icons
import { ArrowBack } from "@mui/icons-material";

const TasksInputs = ( props : i_task_inputs_props) : ReactElement => {
    const F = props.form;
    const E = props.errors;
    const setUser = ( val : string ) => {
        const row = props.users.find( (x : any) => x._id === val)
        props.setForm({ ...F , user : row ? row : empty_user})
    }
    const handleDate = (date : Dayjs | null , value : any) => {
        if(date !== null){
            props.setForm({ ...F , start :  Math.floor(date.valueOf()) , end :  Math.floor(date.add(F.time_estimated,'minute').valueOf())});
        }
    }
    const handleEstimatedTime = ( val : number ) => {
        if(Number(val)){
            var date = dayjs(F.start)
            props.setForm({ ...F , end : date.add(Number(val), 'minute').valueOf() , time_estimated : Number(val) });
        }else{
            props.setForm({ ...F , end : F.start.valueOf() , time_estimated : Number(0) });
        }
    }
    const handleIndex = ( val : number ) => {
        props.setForm({ ...F , index: val });
    }
    const handleChange = ( event : ChangeEvent<HTMLInputElement>) => {
        const { name, value } = event.target;
        props.setForm({...F, [name] : value})
    }
  return (
    <Box p={2} sx={{ marginBottom : '1vh' }} id={'task_inputs'} >
        <Grid container>
            <Grid item xs={12}>
                <Box sx={{ display : 'flex' }}>
                    <IconButton sx={{ marginRight : '4px'}} onClick={() => props.onClose('table')}><ArrowBack color={'primary'} sx={{ marginRight : 'auto'}}/></IconButton>
                    <Typography sx={{ fontWeight : 800 , fontSize : 20 ,  alignSelf : 'center'}}>{F._id !== '' ? "Edit task "+ F.title : "Add a task"}</Typography>
                </Box>
                <Typography sx={{ fontWeight : 800 , fontSize : 16 , marginBottom : '2vh' , color : 'grey'}}>Enter your task informations</Typography>
            </Grid>
            <Grid item xs={3}>
                <Typography sx={sub_title}>Task title* / Time estimated ( Minutes )</Typography>
            </Grid>
            <Grid item xs={9}>
                <TextField sx={[input_base , { width : '300px'} ]} name={'title'} size="small" placeholder={"Task name"} value={F.title} error={E.title !== ""} helperText={E.title}
                onChange={handleChange} autoComplete={props.dev ? "off" : "new-password" }/>  
                <NumericTextField name={"estimated_time"} sx={[input_base , { width : '150px'} ]} size="small" placeholder={"Time estimation ( Minutes )"} value={F.time_estimated} error={E.time_estimated !== ""} helperText={E.time_estimated}
                handleChange={handleEstimatedTime} autoComplete={props.dev ? "off" : "new-password" }/>                 
            </Grid>
            <Grid item xs={12} sx={{ marginBottom : '1vh'}}> <hr style={bottom_line}/></Grid>
            <Grid item xs={3}>
                <Typography sx={sub_title}>Select assigned person* / Task type *</Typography>
            </Grid>
            <Grid item xs={9}>
                <TextField select name={"user"} sx={[input_base , { width : '300px'} ]} size="small" value={F.user._id !== ''  ? F.user._id : "default"} error={E.user_id !== ''} helperText={E.user_id}
                    onChange={( event: ChangeEvent<any> ) => setUser(event.target.value)} autoComplete={props.dev ? "off" : "new-password" }>
                        <MenuItem value={"default"} disabled>Select a user</MenuItem>
                        {props.users.map((user : i_user) => (
                            <MenuItem key={user._id} value={user._id}>{user.first_name + " " + user.last_name + " --> " + user.type }</MenuItem>
                        ))}
                </TextField>
                <TextField select name={"type"} sx={[input_base , { width : '300px'} ]}size="small" value={F.type} error={E.type !== ''} helperText={E.type}
                    onChange={handleChange} autoComplete={props.dev ? "off" : "new-password" }>
                        <MenuItem value={"Default"} disabled>Select a task type</MenuItem>
                        {task_types.map((type : string) => (
                            <MenuItem key={type} value={type}>{type}</MenuItem>
                        ))}
                      
                </TextField>
            </Grid>
            <Grid item xs={12} sx={{ marginBottom : '1vh'}}> <hr style={bottom_line}/></Grid>
            <Grid item xs={3}>
                <Typography sx={sub_title}>Start date* / End date</Typography>
                <Typography sx={{ fontSize : 14 , color : 'grey'}}>Give an approximate start date</Typography>
            </Grid>
            <Grid item xs={9}>
            <LocalizationProvider dateAdapter={AdapterDayjs}>
                        <DateTimePicker  sx={{ paddingRight : '8px'}}  value={dayjs(F.start)} onChange={handleDate}/>
                        <DateTimePicker  value={dayjs(F.end)} disabled/>
                </LocalizationProvider>
            </Grid>
            <Grid item xs={12} sx={{ marginBottom : '1vh'}}> <hr style={bottom_line}/></Grid>
            <Grid item xs={3}>
                <Typography sx={sub_title}>Description </Typography>
                <Typography sx={{ fontSize : 14 , color : 'grey'}}>Give a brief description of the task</Typography>
            </Grid>
            <Grid item xs={9}>
                <TextField name={'description'} sx={[input_base , { width : '600px'} ]} multiline rows={3} size="small" value={F.description} error={E.description !== ''} helperText={E.description}
                onChange={handleChange}autoComplete={props.dev ? "off" : "new-password" }/>
            </Grid>
            <Grid item xs={12} sx={{ marginBottom : '1vh'}}> <hr style={bottom_line}/></Grid>
            <Grid item xs={3}>
                <Typography sx={sub_title}>Task status / Index order</Typography>
                <Typography sx={{ fontSize : 14 , color : 'grey'}}>If set to done the estimated time will be considered at 100%</Typography>
            </Grid>
            <Grid item xs={9}>
                <TextField name={'status'} select sx={[input_base , { width : '300px'} ]}size="small" value={F.status} error={E.status !== ''} helperText={E.status}
                    onChange={handleChange} autoComplete={props.dev ? "off" : "new-password" }>
                        <MenuItem value={"Default"} disabled>Select a task status</MenuItem>
                        {task_status.map((row : any , index : number) => (
                            row.value !== "Any" && <MenuItem key={index} value={row.value}>{row.label}</MenuItem>
                        ))}
                      
                </TextField>
                <NumericTextField sx={[input_base , { width : '150px'} ]} name={"index"} size="small" placeholder={"index"} value={F.index} error={E.index !== ""} helperText={E.index}
                handleChange={handleIndex} autoComplete={props.dev ? "off" : "new-password" }/>               
            </Grid>
        </Grid>
        <Grid container>
            <Grid item xs={12} sx={{ marginTop : '1vh'}}>
                <Typography sx={{ fontWeight : 800 , fontSize : 20}}> Add assets </Typography>
                <Typography sx={{ marginBottom : '1vh' , color : 'gray'}}> Uploads revelant photos</Typography>
            </Grid>
            <Grid item xs={12}>
                <UploadsInputs {...props.uploads}/>
            </Grid>
        </Grid>
        <hr style={bottom_line}/>
        <Grid container sx={{ marginTop : '1vh' , marginBottom : '1vh'}}>
            <Grid item xs={3}></Grid>
            <Grid item xs={6} sx={{ marginTop : '1vh' ,display : 'flex' , justifyContent : 'center'}}>
                <Button onClick={() => props.onClose('table')} sx={{ marginRight : '1vh' , backgroundColor : 'white' , color : 'black' , border : '1px solid gray'}}>Cancel</Button>
                <LoadingButton onClick={() => props.callback()} loading={props.loading} variant="contained" color="primary" sx={{fontWeight : 600}}>Submit</LoadingButton>
            </Grid>
            <Grid item xs={3}></Grid>
        </Grid>
    </Box>
  );
}


export default TasksInputs;