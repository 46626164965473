/*
 * Date: 2023
 * Description: Customer inputs form reworked..
 * Author: Philippe Leroux @ Skitsc
 */

//Modules
import { ReactElement, ChangeEvent } from "react";
import { Box, Typography, MenuItem, TextField, Grid , Button, Switch } from "@mui/material";
import { LoadingButton } from "@mui/lab";
//Components
import ImportantDetails from "./important.details";
import LicenceInputs from "./licence.input";
import CustomerContacts from "./contacts.inputs";

//Interfaces && types
import { i_customer_form_props } from "../../../interfaces/customer.interface";

//Styles
import { bottom_line, input_base, sub_title } from "../../../styles/form.styles";
import CustomerAddress from "./customer.address";
import FormBacker from "../../utility/action/form.backer";




const CustomerInputsV2 = ( props: i_customer_form_props ) : ReactElement => {

    const F = props.form;
    const E = props.errors;
    const HandleSwitch = () => {
        props.setForm({...F , complete : F.complete ? false : true})
    }
    const handleChange = (e : ChangeEvent<HTMLInputElement>) => {
        const { name, value } = e.target
        props.setForm({...F, [name] : value})
    }
  return (
    <Box component={"form"} p={2} m={2} sx={{ marginBottom : '2vh' , backgroundColor : props.mode === 'Dark' ? '#333' : 'white'}} id={'customer_inputs'} >
        <Grid container>
            <FormBacker title={'customer'} type={F._id === '' ? 'add' : 'edit'} callback={() => props.setDisplay('table')}/>
            <Grid item xs={3} >
                <Typography sx={{ marginBottom : '2vh' , fontWeight : 600 , fontSize : 16}}>Completed customer</Typography>
            </Grid>
            <Grid item xs={9}>
                <Switch checked={F.complete}  onChange={HandleSwitch}></Switch>
            </Grid>
            <Grid item xs={12} sx={{ marginBottom : '1vh'}}> <hr style={bottom_line}/></Grid>
            <Grid item xs={3}>
                <Typography sx={sub_title}>Customer type*</Typography>
            </Grid>
            <Grid item xs={9}>
                <TextField select value={F.customer_type} name={'customer_type'} size="small" sx={[input_base , { width : '300px'} ]} onChange={handleChange}
                error={E.customer_type !== ''} helperText={E.customer_type} autoComplete={props.dev ? "off" : "new-password" }>
                    <MenuItem value={'Individual'}>Individual</MenuItem>
                    <MenuItem value={'Business'}>Business</MenuItem>
                </TextField>
            </Grid>
            <Grid item xs={12} sx={{ marginBottom : '1vh'}}> <hr style={bottom_line}/></Grid>
            <Grid item xs={3}>
                <Typography sx={sub_title}>Name *</Typography>
            </Grid>
            <Grid item xs={9}>
                <TextField sx={[input_base , { width : '200px'} ]} name={'first_name'} size="small" placeholder={"First name"} value={F.first_name} error={E.first_name !== ""} helperText={E.first_name}
                onChange={handleChange} autoComplete={props.dev ? "off" : "new-password" }/>  
                <TextField sx={[input_base , { width : '200px'} ]} name={'middle_name'} size="small" placeholder={"Middle name"} value={F.middle_name} error={E.middle_name !== ""} helperText={E.middle_name}
                onChange={handleChange} autoComplete={props.dev ? "off" : "new-password" }/>                 
                <TextField sx={[input_base , { width : '200px'} ]} name={'last_name'} size="small" placeholder={"Last name"} value={F.last_name} error={E.last_name !== ''} helperText={E.last_name}
                onChange={handleChange} autoComplete={props.dev ? "off" : "new-password" }/>
            </Grid>
            <Grid item xs={12} sx={{ marginBottom : '1vh'}}> <hr style={bottom_line}/></Grid>
            <Grid item xs={3}>
                <Typography sx={sub_title}>Email address* </Typography>
            </Grid>
            <Grid item xs={9}>
                <TextField type={'email'} name={'email'} sx={[input_base , { width : '300px'} ]} size="small" value={F.email} error={E.email!== ''} helperText={E.email}
                onChange={handleChange} autoComplete={props.dev ? "off" : "new-password" }/>
            </Grid>
            <Grid item xs={12} sx={{ marginBottom : '1vh'}}> <hr style={bottom_line}/></Grid>
            <Grid item xs={3}>
                <Typography sx={sub_title}>Phone number* </Typography>
            </Grid>  
            <Grid item xs={9}>
                <TextField sx={[input_base , { width : '300px'} ]} name={'phone_one'} size="small" value={F.phone_one} onChange={handleChange}
                    error={E.phone_one !== ''} helperText={E.phone_one}autoComplete={props.dev ? "off" : "new-password" }/>
            </Grid>
            <Grid item xs={12} sx={{ marginBottom : '1vh'}}> <hr style={bottom_line}/></Grid>
            <Grid item xs={3}>
                <Typography sx={sub_title}>Additional phone number</Typography>
            </Grid>
            <Grid item xs={9}>
                <TextField sx={[input_base , { width : '300px'} ]} name={'phone_two'} size="small" value={F.phone_two} onChange={handleChange}
                error={E.phone_two !== ''} helperText={E.phone_two} autoComplete={props.dev ? "off" : "new-password" }/>
            </Grid>
            <Grid item xs={12} sx={{ marginBottom : '1vh'}}> <hr style={bottom_line}/></Grid>

        </Grid>
        <CustomerAddress {...props} />
        <LicenceInputs {...props} />
        <hr style={bottom_line}/>
        <ImportantDetails {...props} />
        <hr style={bottom_line}/>
        <CustomerContacts {...props} />
        <hr style={bottom_line}/>
        <Grid container>
        <Grid item xs={12} sx={{ marginTop : '1vh'}}>
            <Typography sx={{ fontWeight : 800 , fontSize : 20}}> Additional details </Typography>
            <Typography sx={{ marginBottom : '1vh' , color : 'gray'}}> Add additonal details here.</Typography>
        </Grid>
        </Grid>
        <hr style={bottom_line}/>
        <Grid container sx={{ marginTop : '1vh' , marginBottom : '1vh'}}>
        <Grid item xs={3}>
            <Typography sx={sub_title}>Add your notes</Typography>
        </Grid>
        <Grid item xs={9} sx={{marginBottom : '1vh'}}>
            <TextField sx={[input_base, { maxWidth : '700px' } ]} name="notes" fullWidth multiline minRows={3} value={F.notes} onChange={handleChange}
                error={E.notes!== ''} placeholder="Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, " helperText={E.notes}autoComplete={props.dev? "off" : "new-password" }/>
        </Grid>
        <Grid item xs={12} sx={{}}> <hr style={bottom_line}/></Grid>
        <Grid item xs={3}></Grid>
        <Grid item xs={6} sx={{ marginTop : '1vh' ,display : 'flex' , justifyContent : 'center'}}>
            <Button onClick={() => props.setDisplay('table')} sx={{ marginRight : '1vh' , backgroundColor : 'white' , color : 'black' , border : '1px solid gray'}}>Cancel</Button>
            <LoadingButton onClick={() => props.callback()} loading={props.loading} variant="contained" color="primary" sx={{fontWeight : 600}}>Submit</LoadingButton>
        </Grid>
            <Grid item xs={3}></Grid>
        </Grid>
    </Box>
  );
}
export default CustomerInputsV2