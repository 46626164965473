/*
 * Date: 2023
 * Description: Mini menu for customer view
 * Author: Philippe Leroux @ Skitsc
 */

//Modules
import { Box , Grid , Tooltip , IconButton } from "@mui/material"

//Interfaces && types
import { t_customer_sub_views } from "../../../interfaces/customer.interface"

//Icons
import CloseSharpIcon from '@mui/icons-material/CloseSharp';
import { useContext } from "react";
import { MainContext } from "../../../context/context";

export interface i_customer_sub_views {
    sub_menu : t_customer_sub_views,
    setSubMenu : React.Dispatch<React.SetStateAction<t_customer_sub_views>>
    callback : () => void
}

const CustomerViewSubMenu = ( props : i_customer_sub_views) => {
    const { config } = useContext(MainContext)
    const basic_box = {
        padding : '0.7vh',
        cursor : 'pointer'
    }
    const selected_view_box = {
        borderBottom : config.primary_color !== '' ? '1px solid '+ config.primary_color :'1px solid  '
    }
    const V = props.sub_menu
    const S = props.setSubMenu
    return (
        <Grid container>
            <Grid item xs={12} sx={{ display : 'flex' , marginBottom : '1vh'}}>
                <Box onClick={() => { S('global_view')}} sx={[basic_box , V === 'global_view' && selected_view_box]}>
                    Global view
                </Box>
                <Box onClick={() => { S('interaction')}} sx={[basic_box , V === 'interaction' && selected_view_box]}>
                    Interaction
                </Box>
                <Box onClick={() => { S('transactions')}} sx={[basic_box , V === 'transactions' && selected_view_box]}>
                    Transactions
                </Box>
                <Box onClick={() => { S('comunication')}} sx={[basic_box , V === 'comunication' && selected_view_box]}>
                    Comunications
                </Box>
                <Box onClick={() => { S('cars')}} sx={[basic_box , V === 'cars' && selected_view_box]}>
                    Cars
                </Box>
                <Box sx={{ marginLeft : 'auto'}}>
                    <Tooltip title={'Close'}>
                        <IconButton onClick={() => props.callback()}> <CloseSharpIcon sx={{ fontSize : 26}}/> </IconButton>
                    </Tooltip>
                </Box>
            </Grid>
        </Grid>
    )
}

export default CustomerViewSubMenu;