

/*
 * Date: 2024
 * Description: Reusable search input
 * Author: Philippe Leroux @ Skitsc
 */


//Modules
import { Box , TextField } from "@mui/material"
import { ReactElement, ChangeEvent} from "react"
import { LoadingButton } from "@mui/lab"
//interfaces && types
import { i_basic_search_props } from "../../../interfaces/utility.interface"

const search_style = {
    '& > img': {
        width: '20px', 
        height: '20px',
        marginTop : '5px',
        marginRight : '5px',
        marginLeft : '-5px',
        cursor : 'pointer',
      },
}

const SearchFilter = ( props : i_basic_search_props ) : ReactElement => {
    return (
        <Box sx={{ display : 'flex' , paddingLeft : '10%' ,}}>
            <TextField size="small" value={props.search} placeholder={props.placeholder} error={props.error_msg !== '' ? true : false} helperText={props.error_msg} onChange={(e :ChangeEvent<HTMLInputElement >) => props.handleSearch(e)}
            InputProps={{ startAdornment: <Box onClick={() => props.callback()}  component="div" sx={search_style}>  <img alt="search icon" src="/images/search.svg" style={{ filter : 'contrast(0.1)'}}/></Box>}}  />    
            <LoadingButton sx={{ padding : '2px'}} variant="contained" loading={props.loading} disabled={props.loading} onClick={() => props.callback()}>Search</LoadingButton>
        </Box>
    )
}

export default SearchFilter