/*
 * Date: 2023
 * Description: Notifications page needs to be designed
 * Author: Philippe Leroux @ skitsc
 */

//Modules
import { Box, Typography } from "@mui/material"
import { ReactElement , useEffect, useState} from "react"

//Api
import { f_fetch } from "../api/fetch"

//Components
import NotifTbl from "../components/notifications/notif.table"
import CircularUnderLoad from "../components/utility/center.loader"
import Header from "../components/utility/header"
//Interfaces && types
import { i_header_props, i_initial_props } from "../interfaces/utility.interface"
import { t_display } from "../types/types"

//Utitlitys
import { delay } from "../utils/utility"

const notif_tbl_title = 'Notifications viewer'
const Notifications = ( props : i_initial_props ) : ReactElement => {
    const [ loading , setLoading ] = useState<boolean>(true)
    const [ display , setDisplay ] = useState<t_display>('table')
    const [ title_header , setTitleHeader ] = useState<string>( notif_tbl_title )
    const [ data , setData ] = useState<[]>([])
    useEffect(() => {
        const f_fetch_notif = async() => {
            setLoading(true)
            await delay(1000)
            const res = await f_fetch('/notifications' , 'GET' , true , null)
            if(res.type === 'Success'){
                setData(res.data)
            }
            setLoading(false)
        }
        f_fetch_notif()
    },[])
    useEffect(() => {
        if(display === 'table') setTitleHeader(notif_tbl_title)
    },[display])
    const initial_props = {
        loading : loading,
        data : data
    }
    const handleDisplay = ( value : t_display ) => setDisplay(value)
    const header_props : i_header_props = {
        display : display,
        title : title_header,
        callback : handleDisplay
    }
    return (
        <Box>
            { loading ? 
                <CircularUnderLoad type={"full"}/> 
                :
            <Box>
                <Header {...header_props}/>
                { data.length > 0 ?
                    <NotifTbl {...initial_props} />
                    : 
                    <Typography>No notifications yet..</Typography>
                }
            </Box>
            }
        </Box>
    )
}

export default Notifications