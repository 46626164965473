
/*
 * Date: 2024
 * Description: Task -> complete job view
 * Author: Philippe Leroux @ Skitsc
 */
//Modules
import { Box , Accordion , AccordionDetails , AccordionSummary , Typography } from "@mui/material"


//Icons
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';

const TaskCompleteView = ( props : any ) => {

    return (
        <Accordion expanded={props.acc_open} onChange={() => props.handleAcc('global')} sx={{ }}>
            <AccordionSummary sx={{ borderColor : '#D3D3D3'}} expandIcon={<ExpandMoreIcon />} aria-controls="panel-job-search" id="panel-job-search">
                <Typography sx={{ fontWeight : 600}}>Job's information</Typography>
            </AccordionSummary>
            <AccordionDetails>
                <Box>
                    <Box>Customer info</Box>
                    <Box>Car info</Box>
                    <Box>Insurance info</Box>
                    <Box>Estimation company info</Box>
                    <Box>Other info</Box>
                </Box>
            </AccordionDetails>
        </Accordion>
    )
}

export default TaskCompleteView