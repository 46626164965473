/*
 * Date: 2024
 * Description: All core hooks
 * Author: Philippe Leroux @ skitsc
 */

//Modules
import { useState, useCallback , useMemo } from "react";
import { NavigateFunction } from "react-router-dom";

//Constants
import { empty_user , default_org } from "../utils/constant";

//Types && interfaces
import { i_main_context } from "../interfaces/context.interface";
import { i_user } from "../interfaces/user.interface";
import { i_theme_context } from "../interfaces/context.interface";
import { t_mode } from "../types/types";
import { i_app_config } from "../interfaces/setting.interface";

//Utils
import { f_get_local_key } from "../utils/utility";


const MainHook = (): i_main_context => {
    const [ authenticated, setAuthenticated ] = useState<boolean>(false);
    const [ user, setUser ] = useState<i_user>(empty_user);
    const [ config , setConfig ] = useState<i_app_config>(default_org);
    const setCurrentAuth = useCallback((currentAuth: boolean) : void => {
        setAuthenticated(currentAuth);
    }, []);
    const setCurrentUser = useCallback((currentUser: i_user) : void => {
        setUser(currentUser);
    }, []);
    const setCurrentConfig = useCallback((currentConfig : i_app_config) : void => {
        setConfig(currentConfig);
    }, []);
    //Function to trigger on logout
    const HandleLogout = useCallback(( Navigator : NavigateFunction ) => {
        setCurrentAuth(false);
        setCurrentUser(empty_user);
        if(Navigator !== undefined)Navigator('/');
    },[setCurrentAuth , setCurrentUser]);
    //Function to trigger on login
    const HandleLogin = useCallback(( user: i_user ) => {
        setCurrentAuth(true);
        setCurrentUser({...empty_user , ...user});
    },[setCurrentAuth, setCurrentUser]);
    
    return useMemo(() => ({
        authenticated,
        setCurrentAuth,
        user,
        config,
        setCurrentUser,
        setCurrentConfig,
        HandleLogin,
        HandleLogout,
    }),[
        authenticated,
        setCurrentAuth,
        user,
        config,
        setCurrentConfig,
        setCurrentUser,
        HandleLogin,
        HandleLogout,
    ]);
    };
    const ThemeHook = (): i_theme_context => {
        const f_find_key = () : t_mode => {
            const key = f_get_local_key('theme_mode')
            if(key !== null ) return key
            return 'Light'
        }
        // const f_find_color = () : string => {
        //     const key = f_get_local_key('theme_color')
        //     if(key !== null ) return key
        //     return '#B71F26'
        // }
        const [ mode , setMode ] = useState<t_mode>(f_find_key());
        const [ primary_color, setPrimaryColor ] = useState<string>('#B71F26');
        const [ secondary_color, setSecondaryColor ] = useState<string>('#555555');
        const [ hover_color, setHoverColor ] = useState<string>('#555555');
        const [ selected_color, setSelectedColor ] = useState<string>('#B71F26');

        const setCurrentTheme = useCallback((currentTheme: t_mode): void => {
            setMode(currentTheme);
        }, []);
        const setCurrentColor = useCallback((config: i_app_config): void => { 
            setPrimaryColor(config.primary_color);
            setSecondaryColor(config.secondary_color);
            setHoverColor(config.hover_color);
            setSelectedColor(config.selected_color);
        }, []);
        return {
            mode : mode,
            primary_color : primary_color,
            secondary_color : secondary_color,
            selected_color : selected_color,
            hover_color : hover_color,
            setCurrentTheme : setCurrentTheme,
            setCurrentColor : setCurrentColor,
        };
    };


export { MainHook , ThemeHook }