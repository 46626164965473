
/*
 * Date: 2024
 * Description: Jobs top views ( data )
 * Author: Philippe Leroux @ Skitsc
 */

//Modules
import { Box , Grid , Typography , Accordion , AccordionDetails , AccordionSummary } from "@mui/material"

//Icons
import ArrowUpwardIcon from '@mui/icons-material/ArrowUpward';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';

//Styles
import { box_sizing , double_box , chart_proportion , star_style } from "../../../styles/view.styles";
const JobsTop = ( props : any ) => {

    return (
        <Accordion expanded={props.acc_open} onChange={() => props.handleAcc('stats')} sx={{ }}>
            <AccordionSummary sx={{ borderColor : '#D3D3D3'}} expandIcon={<ExpandMoreIcon />} aria-controls="panel-job-search" id="panel-job-search">
                <Typography sx={{ fontWeight : 600}}>Stats</Typography>
            </AccordionSummary>
            <AccordionDetails>
            <Grid container sx={{ paddingTop : '0.5vh' , display : 'flex'  , justifyContent : 'center'}}>
                <Grid item sx={{ display : 'flex' }}>
                    <Box sx={[box_sizing , { width : '400px'}]}>
                        <Grid container>
                            <Grid item xs={12} sx={{ height : '100%' , display : 'flex'}}>
                                <Typography sx={{ fontWeight : 800 , fontSize : 15 , paddingRight : '8px'}}> Average turn over </Typography>
                                <Typography sx={{ fontWeight : 800, fontSize : 10 , color : 'gray' , alignSelf : 'center'}}> per month </Typography>
                            </Grid>
                            <Grid item xs={6}>
                                <Box sx={{ display : 'flex' ,marginTop : '1.5vh' }}>
                                    <Typography sx={{ fontWeight : 800 , fontSize : 30 , marginRight : '2px' ,}}> {props.turn_over } </Typography>
                                    <Typography sx={{ color : '#009900' , alignSelf : 'center'}}> + { props.turn_over_plus}</Typography>
                                </Box>
                                <Box sx={{ display : 'flex' , marginTop : '1vh'}}>
                                    <Box component={'img'} alt={'Up or down icon'} src={'./images/up_green.png'}></Box>
                                    <Typography sx={{ paddingRight : '6px' , color : 'green'}}>{props.turn_over_last_month}</Typography>
                                    <Typography>vs last month</Typography>
                                </Box>
                            </Grid>
                            <Grid item xs={6}>
                                <Box component={'img'} alt={'chart img'} src={'./images/chat_mini.png'} sx={chart_proportion}>
                                    
                                </Box>
                            </Grid>
                        </Grid>
                    </Box>
                </Grid>
                <Grid item sx={{ display : 'flex' }}>
                    <Box sx={[double_box , { borderLeft : '1px solid #D3D3D3' , borderTopLeftRadius : '10px' , borderBottomLeftRadius : '10px'}]}>
                        <Grid container>
                            <Grid item xs={12} sx={{ height : '100%' }}>
                                <Typography sx={{ fontWeight : 800 , fontSize : 15 , paddingRight : '8px'}}>Today's task completion </Typography>
                                <Typography sx={{ fontWeight : 800, fontSize : 15 ,  alignSelf : 'center'}}> expectation </Typography>
                            </Grid>
                            <Grid item xs={6}>
                                <Box sx={{ display : 'flex' ,marginTop : '1.5vh' }}>
                                    <Typography sx={{ fontWeight : 800 , fontSize : 30 , marginRight : '2px' ,}}> {props.today_task_completion } </Typography>
                                </Box>
                                <Box sx={{ display : 'flex' , marginTop : '1vh'}}>
                                    <Box component={'img'} alt={'Up or down icon'} src={'./images/up_green.png'}></Box>
                                    <Typography sx={{ paddingRight : '6px' , color : 'green'}}>{props.today_task_vs_yesterday}</Typography>
                                    <Typography>vs yesterday</Typography>
                                </Box>
                            </Grid>
                            <Grid item xs={6}>
                                <Box component={'img'} alt={'chart img'} src={'./images/chat_mini.png'} sx={chart_proportion}>
                                    
                                </Box>
                            </Grid>
                        </Grid>
                        
                    </Box>
                </Grid>
                <Grid item sx={{ display : 'flex' }}>
                    <Box sx={[double_box , { borderRight : '1px solid #D3D3D3'  , borderTopRightRadius : '10px' , borderBottomRightRadius : '10px'}]}>
                        <Grid container>
                            <Grid item xs={12} sx={{ height : '100%' }}>
                                <Typography sx={{ fontWeight : 800 , fontSize : 15 , paddingRight : '8px'}}>This week's task </Typography>
                                <Typography sx={{ fontWeight : 800, fontSize : 15 ,  alignSelf : 'center'}}>completion expectation </Typography>
                            </Grid>
                            <Grid item xs={6}>
                                <Box sx={{ display : 'flex' ,marginTop : '1.5vh' }}>
                                    <Typography sx={{ fontWeight : 800 , fontSize : 30 , marginRight : '2px' ,}}> {props.week_task_completion } </Typography>
                                </Box>
                                <Box sx={{ display : 'flex' , marginTop : '1vh'}}>
                                    <Box component={'img'} alt={'Up or down icon'} src={'./images/up_green.png'}></Box>
                                    <Typography sx={{ paddingRight : '6px' , color : 'green'}}>{props.today_task_vs_yesterday}</Typography>
                                    <Typography>vs yesterday</Typography>
                                </Box>
                            </Grid>
                            <Grid item xs={6}>
                                <Box component={'img'} alt={'chart img'} src={'./images/chat_mini.png'} sx={chart_proportion}>
                                    
                                </Box>
                            </Grid>
                        </Grid>
                        
                    </Box>
                </Grid>
                <Grid item  sx={{ display : 'flex'}}>
                    <Box sx={box_sizing}>
                    <Grid container>
                            <Grid item xs={12} sx={{ height : '100%'}}>
                                <Typography sx={{ fontWeight : 800 ,fontSize : 15}}>Satisfaction score </Typography>

                            </Grid>
                            <Grid item xs={12}>
                                <Typography sx={{ color : 'gray' , fontSize : 14}}>Total reviews</Typography>
                            </Grid>
                            <Grid item xs={6}>
                                <Box sx={{ display : 'flex' ,marginTop : '0.5vh' }}>
                                    <Typography sx={{ fontWeight : 800 , fontSize : 15}}>{props.total_ratings}</Typography>
                                </Box>
                            </Grid>
                            <Grid item xs={6}>
                                <Box sx={{ display : 'flex' ,marginTop : '0.5vh' }}>
                                    <Typography sx={{ fontWeight : 800 , fontSize : 15 , paddingRight : '4px'}}>{props.global_rating}</Typography>
                                    <Box component={'img'} alt={'star img'} src={'./images/star.png'} sx={star_style}></Box>
                                </Box>
                            </Grid>
                            <Grid item xs={6}>
                                <Box sx={{ display : 'flex',marginTop : '0vh' }}>
                                    <ArrowUpwardIcon sx={{color : '#009900' , fontSize : 16}}/>
                                    <Typography sx={{color : '#009900' , marginRight : '8px' , alignSelf : 'center' , fontSize : 14}}>{props.percent_total}%</Typography>
                                    <Typography sx={{ fontSize : 12 , alignSelf : 'center' }}>vs last month</Typography>
                                </Box>
                            </Grid>
                            <Grid item xs={6}>
                                <Box sx={{ display : 'flex',marginTop : '0vh' }}>
                                    <ArrowUpwardIcon sx={{color : '#009900' , fontSize : 16}}/>
                                    <Typography sx={{color : '#009900' , marginRight : '8px' , alignSelf : 'center' , fontSize : 14}}>{props.percent_month}%</Typography>
                                    <Typography sx={{ fontSize : 12 , alignSelf : 'center' }}>vs last month</Typography>
                                </Box>
                            </Grid>
                            <Grid item xs={4}>
                                <Box sx={{ display : 'flex' , marginTop : '0.2vh' ,  marginBottom : '0.2vh', borderTop : '1px solid #D3D3D3',}}>
                                    <Box component={'img'} alt={'star img'} src={'./images/star.png'} sx={star_style}></Box>
                                    <Box component={'img'} src={'./images/happy.svg'} sx={{ alignSelf : 'center' , marginRight : '4px'}}/>
                                    <Typography sx={{fontSize : 16 , alignSelf : 'center'}}>4-5</Typography>
                                </Box>
                            </Grid>
                            <Grid item xs={4}>
                            <Box sx={{ display : 'flex' , marginTop : '0.2vh' , marginBottom : '0.2vh', borderTop : '1px solid #D3D3D3',}}>
                                    <Box component={'img'} alt={'star img'} src={'./images/star.png'} sx={star_style}></Box>
                                    <Box component={'img'} src={'./images/mid.svg'} sx={{color : 'yellow', fontSize : 20 , alignSelf : 'center' , marginRight : '4px'}}/>
                                    <Typography sx={{fontSize : 16 , alignSelf : 'center'}}>2-3</Typography>
                                </Box>
                            </Grid>
                            <Grid item xs={4}>
                            <Box sx={{ display : 'flex' , marginTop : '0.2vh' ,  marginBottom : '0.2vh', borderTop : '1px solid #D3D3D3',}}>
                                    <Box component={'img'} alt={'star img'} src={'./images/star.png'}sx={star_style} />
                                    <Box component={'img'} src={'./images/sad.svg'} sx={{alignSelf : 'center' , marginRight : '4px'}}/>
                                    <Typography sx={{fontSize : 16 , alignSelf : 'center'}}>1-2</Typography>
                                </Box>
                            </Grid>
                            <Grid item xs={4}>
                                <Typography sx={{fontSize : 15}}>{props.high_rating} user</Typography>
                            </Grid>
                            <Grid item xs={4}>
                                <Typography sx={{fontSize : 15}}>{props.mid_rating} user</Typography>
                            </Grid>
                            <Grid item xs={4}>
                                <Typography sx={{fontSize : 15}}>{props.low_rating} user</Typography>
                            </Grid>
                        </Grid>
                    </Box>
                </Grid>
   
            </Grid>
            </AccordionDetails>
        </Accordion>
    )


}

export default JobsTop