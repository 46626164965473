/*
 * Date: 2024
 * Description: Dedicated body for payroll views..
 * Author: Philippe Leroux @ Skitsc
 */


//Modules
import { Box , Grid} from "@mui/material";
import { LoadingButton } from "@mui/lab";
//Components

//Styles
import { button_center } from "../../../styles/form.styles";

//Interfaces && types
import { i_fragmented_body } from "../../../interfaces/utility.interface";

const PayrollFragmentedBody = ( props : i_fragmented_body ) => {

    return (
        <Box>
            <Grid container sx={{ marginTop : '2vh'}}>
                <Grid item xs={12}>
                   
                </Grid>
                <Grid item xs={12} sx={{ marginTop : '2vh'}}>
                    <LoadingButton onClick={() => {}} sx={button_center} variant="contained" loading={props.form.loading}> Save changes </LoadingButton>
                </Grid>
            </Grid>
        </Box>
    )
}

export default PayrollFragmentedBody