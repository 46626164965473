/*
 * Date: 2023
 * Description: Links
 * Author: Philippe Leroux @ skitsc
 */

import { Box } from "@mui/material"
import { ReactElement } from "react"

//Interfaces && types
import { i_initial_props } from "../interfaces/utility.interface"

const Links = ( props : i_initial_props ) : ReactElement => {
    return (
        <Box>

        </Box>
    )
}


export default Links