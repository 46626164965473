/*
 * Date: 2024
 * Description: Top bar menu for extra interactions
 * Author: Philippe Leroux @ skitsc
 */

//Modules
import { useContext , useEffect , useState } from "react";
import { Box, IconButton, useTheme , Tooltip  } from "@mui/material";
import { Link  } from "react-router-dom";
import { useLocation , useNavigate } from "react-router-dom";
//Context
import { MainContext, ThemeContext } from "../../context/context";
//Icons
import { LightModeOutlined , DarkModeOutlined } from "@mui/icons-material";
import { ArrowBack } from "@mui/icons-material";

//Utils
import { f_set_local_key } from "../../utils/utility";
const TopBar = () => {
  const theme = useTheme();
  const { user } = useContext(MainContext)
  const { mode , setCurrentTheme , primary_color} = useContext(ThemeContext)
  const [ back , setBack ] = useState<boolean>(false)
  const handleMode = () => {
    mode === 'Light'? setCurrentTheme('Dark') : setCurrentTheme('Light')
    mode === 'Light'? f_set_local_key('theme_mode','Dark' , 2592000000) : f_set_local_key('theme_mode','Light' , 2592000000)
  } 
  const location = useLocation();
  
  const navigate = useNavigate();
  const sizing = {
    width : '45px',
    height : '45px'
  }
  useEffect(() => {
    if(location.pathname.includes('settings')){
      setBack(true)
    }else{
      setBack(false)
    }
  },[location])
  const handleBack = () => {
    navigate(-1)
  }
  return (
    <Box sx={{ display : 'flex'  , borderBottom: "1px solid #EAECF0" ,  height : '4.5vh' , paddingRight : '2vh' , paddingTop : '2.5px' , paddingLeft : '2vh'}} >
        {back &&
            <Box sx={{ marginRight : 'auto'}}>
                <IconButton sx={sizing} onClick={() => handleBack()}><ArrowBack color={'primary'}/></IconButton>
            </Box>
        }
        <Box sx={{ marginLeft : 'auto'}}>
          <Tooltip title={theme.palette.mode + " Mode"}>
            <IconButton sx={sizing} color='primary' onClick={ () => handleMode() }>
              {mode === 'Dark' ? (
                <DarkModeOutlined sx={{ color : primary_color}}/>
              ) : (
                <LightModeOutlined color="primary" />
              )}
            </IconButton>
          </Tooltip>
          <Tooltip title="Calendar">
            <IconButton sx={sizing} color='primary' component={Link} to={'/calendar'}>
              <Box component={'img'} src={'/images/calendar.svg'}></Box>
            </IconButton>
          </Tooltip>
          <Tooltip title="Notifications">
            <IconButton sx={sizing} color='primary'>
              <Box component={'img'} src={'/images/bell.svg'}></Box>
            </IconButton>
          </Tooltip>
          <Tooltip title="Profile">
            <IconButton sx={sizing} color='primary' component={Link} to={'/profile'}>
              { user.path !== null && user.path !== undefined ?
                <Box component={'img'} src={'/images/user.svg'}></Box>
              :
                <Box component={'img'} sx={{height : '30px'}} src={'/images/vador-icon.png'}></Box>
              }
            </IconButton>
          </Tooltip>
        </Box>
    </Box>
  );
};

export default TopBar;

